import React from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate, useParams, Navigate } from 'react-router-dom'

import { refreshToken } from 'store/login'
import { toggleFadebar } from 'store/common'

const mstp = state => {
  return {
    userInfo: state.userInfo,
    loginData: state.loginData,
    redirect: state.common.redirect,
  }
}

class WrappedComponent extends React.Component {
  componentDidMount() {
    this.props.dispatch(toggleFadebar(false))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.redirect !== this.props.redirect) {
      this.props.router.navigate(this.props.redirect)
    }
  }

  render() {
    let { component: Component, userInfo, loginData, ...rest } = this.props
    if (loginData.status === 'login') {
      return <Navigate to="/login" push={true} />
    }
    if (userInfo.email) {
      return <Component {...rest} />
    } else {
      this.props.dispatch(refreshToken({ email: 'email', password: 'password' }))
      return null
    }
  }
}

const ConnectedComponent = connect(mstp)(WrappedComponent)

const withJWTSession = Component => props => {
  let location = useLocation()
  let navigate = useNavigate()
  let params = useParams()
  return <ConnectedComponent {...props} component={Component} router={{ location, navigate, params }} />
}

export default withJWTSession
