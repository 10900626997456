import React from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'

import withJWTSession from 'enhancers/jwt_session'
import Topbar from 'components/system_wide/topbar'
import Fadebar from 'components/system_wide/fadebar'
import Affiliates from 'components/assignments/affiliates'

const { t } = i18next

const mstp = state => {
  let { current_assignment } = state.assignments.data
  return { current_assignment }
}

class Assignments extends React.Component {
  render() {
    let { current_assignment } = this.props
    let name = current_assignment?.user?.name || ''
    let surname = current_assignment?.user?.surname || ''
    let { params, navigate } = this.props.router
    return (
      <div>
        <Topbar title={`${t('pages.assignment.title', 'Incarico di ')} ${name} ${surname}`} />
        <div className="d-flex vh-94">
          <div className="d-flex" style={{ backgroundColor: 'white' }}>
            <Fadebar />
          </div>
          <Affiliates assignment_id={params.assignmentId} navigate={navigate} />
        </div>
      </div>
    )
  }
}

export default connect(mstp)(withJWTSession(Assignments))
