import React from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap'

class EconomicSummary extends React.Component {
  render() {
    let { condominia, current_state, dossiers, prices, id: assignment_id } = this.props.assignment
    let { services, loading } = this.props

    let condominia_services = condominia.reduce((acc, c) => {
      return [...new Set([...acc, ...c.services])]
    }, [])
    let selected_services = services.filter(s => condominia_services.includes(parseInt(s.value)))

    if (loading)
      return (
        <div className="d-flex w-100 flex-column zi-1">
          <div className="w-100">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      )

    return (
      <div className="bg-white ps-0 pe-0 border">
        <div className="fw-bold p-3 bg-warning">
          <h4>
            <Link
              to={`/assignments/${assignment_id}/prices`}
              className="d-flex"
              style={{ color: 'black', textDecoration: 'none' }}
            >
              <i className="fas fa-external-link pe-3" />
              <Trans i18nKey="assignments.dashboard.economic_summary">Riepilogo economico</Trans>
            </Link>
          </h4>
        </div>
        <div style={{ height: '30rem', overflow: 'auto' }}>
          <div className="d-flex flex-row w-100" style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
            <div
              className={`text-center align-middle text-muted p-3 fw-bold h6`}
              style={{
                minWidth: '24rem',
                borderBottom: '2px solid orange',
                position: 'sticky',
                left: 0,
                background: 'white',
              }}
            >
              Nome
            </div>
            {selected_services.map((s, idx) => {
              let target = React.createRef()
              return (
                <div
                  key={s.value}
                  className="text-center align-center align-middle text-muted p-3 fw-bold h6"
                  style={{
                    width: '7rem',
                    minWidth: '7rem',
                    borderBottom: '2px solid orange',
                  }}
                >
                  <OverlayTrigger key={s.value} trigger={['hover', 'click']} overlay={<Tooltip>{s.label}</Tooltip>}>
                    <div className="d-flex justify-content-center">
                      <div style={{ width: '1.3rem' }} className="d-flex align-items-center">
                        <i ref={target} className={`${s.icon_classes} m-auto`} />
                      </div>
                    </div>
                  </OverlayTrigger>
                </div>
              )
            })}
            <div
              className="text-center align-center align-middle text-muted p-3 fw-bold h6"
              style={{
                width: '100%',
                minWidth: '4rem',
                borderBottom: '2px solid orange',
              }}
            ></div>
          </div>
          {condominia.map(c => {
            return (
              <div className="d-flex flex-row text-center" key={`${c.id}-condominium`}>
                <div
                  className="p-3 border-bottom"
                  style={{ minWidth: '24rem', backgroundColor: 'var(--bs-body-bg)', position: 'sticky', left: 0 }}
                >
                  {c.name}
                </div>
                {selected_services.map(s => {
                  let d = dossiers.find(d => parseInt(d.service_id) === parseInt(s.value))
                  return (
                    <div
                      key={`value-${c.id}-${s.value}`}
                      className="p-3 border-bottom"
                      style={{
                        width: '7rem',
                        minWidth: '7rem',
                      }}
                    >
                      {c.services.includes(parseInt(s.value))
                        ? `${!isNaN(parseFloat(prices[c.id][d.id])) ? '€' : ''} ${prices[c.id][d.id]}`
                        : ''}
                    </div>
                  )
                })}
                <div
                  className="p-3 border-bottom"
                  style={{
                    width: '100%',
                  }}
                ></div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default EconomicSummary
