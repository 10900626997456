import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'lib/axios'
import axiosLib from 'axios'
import i18next from 'i18next'

import config from 'config'
import jsonapiToData from 'lib/jsonapi_to_data'
import { error, success } from 'components/system_wide/notification'

const { t } = i18next

export const initialState = {
  users: [],
  roles: {},
  affiliate_roles: [],
  total: 0,
  show_form: false,
  current_user: {},
  errors: {},
  is_deactivated: false,
  created_user_id: null,
  filtered_roles: [],
  filtered_affiliate_roles: [],
  current_page: 1,
}

export const getRoles = createAsyncThunk('users/roles', async (_, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/v1/roles`,
      method: 'GET',
    })
    let { data: roles } = response.data
    roles = roles.reduce((acc, r) => {
      let { id, attributes } = r
      let { codename } = attributes
      return { ...acc, [id]: codename }
    }, {})
    return roles
  } catch (err) {
    error(t('store.users.get_roles_error', 'Impossibile recupeare le roles'))
    return thunkApi.rejectWithValue()
  }
})

export const getUsers = createAsyncThunk('users/list', async (params, thunkApi) => {
  try {
    let { offset, limit, sortData, filter } = params
    let { sort_column, sort_direction } = sortData
    let state = thunkApi.getState().usersData
    let current_page = parseInt(offset / limit) + 1 || 1

    let url = `${config.SERVER_API_URL}/v1/users?include=role,tax_datum&page=${current_page}&per=${limit}`
    if (filter) url = url + `&filter[q]=${encodeURIComponent(filter)}`
    if (!state.is_deactivated) url = url + `&filter[is_deactivated]=false`
    if (state.filtered_affiliate_roles.length > 0)
      url = url + `&filter[with_affiliate_roles]=${state.filtered_affiliate_roles.join(',')}`
    if (state.filtered_roles.length > 0) url = url + `&filter[with_role]=${state.filtered_roles.join(',')}`
    if (sort_column !== '' && sort_column !== 'business_name')
      url = url + `&sort=${sort_direction === 'ASC' ? '' : '-'}${sort_column}`
    if (sort_column !== '' && sort_column === 'business_name')
      url = url + `&sort=${sort_direction === 'ASC' ? '' : '-'}scope:ordered_by_business_name`

    let response = await axios({
      url,
      method: 'get',
    })
    let users_data = jsonapiToData(response.data)
    users_data.data.forEach(el => {
      el.business_name = el?.tax_datum?.business_name ?? ''
    })
    return { users: users_data.data, total: users_data.total, reset: offset === 0, current_page }
  } catch (err) {
    console.log(err)
    error(t('store.users.get_users_error', 'Impossibile recuperare gli utenti'))
    return thunkApi.rejectWithValue()
  }
})

export const setCurrentUser = createAsyncThunk('users/set_user', async (id, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/v1/users/${id}?include=tax_datum.city.province.region,affiliate_settings,role`,
      method: 'GET',
    })
    let userData = jsonapiToData(response.data)?.data?.[0] ?? {}

    let affiliate_roles = userData?.affiliate_settings?.map(r => r)
    return {
      id: userData.id,
      ...userData,
      role_id: userData?.role?.id,
      tax_datum_attributes: {
        ...userData?.tax_datum,
        region: userData?.tax_datum?.city?.province?.region?.id ?? null,
        province: userData?.tax_datum?.city?.province?.id ?? null,
        city_id: userData?.tax_datum?.city?.id,
      },
      affiliate_roles,
    }
  } catch (err) {
    console.log(err)
    error(t('store.users.set_user_error', "Impossibile recuperare l'utente"))
    return thunkApi.rejectWithValue()
  }
})

export const updateUser = createAsyncThunk('users/updateUser', async (user, thunkApi) => {
  try {
    let { id, affiliate_roles = [], selected_affiliate_roles = [], ...rest } = user
    let affiliate_settings_attributes = selected_affiliate_roles.map(r => {
      let is_present = affiliate_roles.find(af => af.affiliate_role_id.toString() === r.toString())
      if (is_present) {
        return { affiliate_role_id: r, id: is_present.id }
      } else {
        return { affiliate_role_id: r }
      }
    })

    affiliate_roles.forEach(r => {
      if (!selected_affiliate_roles.includes(r.affiliate_role_id.toString())) {
        affiliate_settings_attributes.push({
          affiliate_role_id: r.affiliate_role_id,
          id: r.id,
          _destroy: 1,
        })
      }
    })

    await axios({
      url: `${config.SERVER_API_URL}/v1/users/${id}`,
      method: 'patch',
      data: { ...rest, affiliate_settings_attributes },
    })
    thunkApi.dispatch(resetCurrentUser())
    thunkApi.dispatch(toggleShowForm())
    success(t('store.users.update_user_success', 'Utente aggiornato con successo'))
    return {}
  } catch (err) {
    console.log(err)
    let {
      data: { errors: from_server },
    } = err.response
    let errors = { from_server }
    thunkApi.dispatch(updateErrors({ errors }))
    error(t('store.users.update_user_error', 'Impossibile salvare le modifiche'))
    return thunkApi.rejectWithValue()
  }
})

export const createUser = createAsyncThunk('users/createUser', async (params, thunkApi) => {
  try {
    let { id, affiliate_roles, selected_affiliate_roles, ...user } = params
    let affiliate_settings_attributes = selected_affiliate_roles.map(r => {
      let is_present = affiliate_roles.find(af => af.affiliate_role_id.toString() === r.toString())
      if (is_present) {
        return { affiliate_role_id: r, id: is_present.id }
      } else {
        return { affiliate_role_id: r }
      }
    })
    affiliate_roles.forEach(r => {
      if (!selected_affiliate_roles.includes(r.affiliate_role_id.toString())) {
        affiliate_settings_attributes.push({
          affiliate_role_id: r.affiliate_role_id,
          id: r.id,
          _destroy: 1,
        })
      }
    })

    let response = await axios({
      url: `${config.SERVER_API_URL}/v1/users`,
      method: 'POST',
      data: { ...user, affiliate_settings_attributes },
    })
    thunkApi.dispatch(resetCurrentUser())
    thunkApi.dispatch(toggleShowForm())
    success(t('store.users.create_user_success', 'Utente aggiunto con successo'))
    return response.data.data.id
  } catch (err) {
    let {
      data: { errors: from_server },
    } = err.response
    let errors = { from_server }
    thunkApi.dispatch(updateErrors({ errors }))
    error(t('store.users.create_user_error', "Impossibile creare l'utente!"))
    return thunkApi.rejectWithValue()
  }
})

export const deleteUser = createAsyncThunk('users/deleteUser', async (id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/v1/users/${id}`,
      method: 'DELETE',
    })
    success(t('store.users.delete_user_success', 'Utente disattivato con successo'))
    return {}
  } catch (err) {
    error(t('store.users.delete_user_error', "Impossibile disattivare l'utente!"))
    return thunkApi.rejectWithValue()
  }
})

export const activeUser = createAsyncThunk('users/activeUser', async (id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/v1/users/${id}/reactivate`,
      method: 'PUT',
    })
    success(t('store.usersa.activate_user_success', 'Utente attivato con successo'))
    return {}
  } catch (err) {
    error(t('store.users.activate_user_error', "Impossibile attivare l'utente!"))
    return thunkApi.rejectWithValue()
  }
})

export const resetPasswordUser = createAsyncThunk('users/resetPasswordUser', async (email, thunkApi) => {
  try {
    await axiosLib({
      url: `${config.SERVER_API_URL}/users/password`,
      method: 'POST',
      data: { user: { email } },
    })
    success(t('store.usersa.email_reset_password_sended_success', 'Email per reset password inviata!'))
    return {}
  } catch (err) {
    error(t('store.users.email_reset_password_sended_error', "Impossibile inviare l'email di reset password!"))
    return thunkApi.rejectWithValue()
  }
})

export const getAffiliateRoles = createAsyncThunk('users/getAffiliateRoles', async (_, thunkApi) => {
  try {
    let url = `${config.SERVER_API_URL}/v1/affiliate_roles`
    let response = await axios({
      url,
      method: 'GET',
    })
    let affiliate_roles = response.data.data.map(r => {
      return {
        id: r.id,
        ...r.attributes,
        value: r.id,
        label: r.attributes.name,
      }
    })
    return { affiliate_roles }
  } catch (err) {
    console.log(err)
    error(t('store.assignments.get_affiliate_roles_error', 'Impossibile recuperare i ruoli affiliato'))
    return thunkApi.rejectWithValue()
  }
})

export const usersSlice = createSlice({
  name: 'usersData',
  initialState,

  reducers: {
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    toggleDeactivatedFilter: state => {
      state.is_deactivated = !state.is_deactivated
    },
    resetCurrentUser: state => {
      state.current_user = initialState.current_user
      state.errors = {}
    },
    updateErrors: (state, action) => {
      let { errors } = action.payload
      state.errors = errors
    },
    filterRoles: (state, action) => {
      state.filtered_roles = action.payload
    },
    filterAffiliateRoles: (state, action) => {
      state.filtered_affiliate_roles = action.payload
    },
  },
  extraReducers: {
    [getUsers.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { total, users, current_page } = action.payload
      state.total = total
      state.users = current_page === 1 ? [...users] : [...state.users, ...users]
    },
    [getUsers.rejected]: state => {
      state.total = initialState.total
      state.users = initialState.users
      state.current_page = initialState.current_page
    },
    [getRoles.fulfilled]: (state, action) => {
      if (!action?.payload) return
      state.roles = action.payload
    },
    [getRoles.rejected]: state => {
      state.roles = initialState.roles
    },
    [getAffiliateRoles.fulfilled]: (state, action) => {
      state.affiliate_roles = action.payload.affiliate_roles
    },
    [getAffiliateRoles.rejected]: state => {
      state.affiliate_roles = initialState.affiliate_roles
    },
    [updateUser.fulfilled]: (state, action) => {},
    [updateUser.rejected]: state => {},
    [createUser.fulfilled]: (state, action) => {
      state.created_user_id = action.payload
    },
    [createUser.rejected]: state => {},
    [setCurrentUser.fulfilled]: (state, action) => {
      state.errors = {}
      state.current_user = action.payload
    },
    [setCurrentUser.rejected]: state => {
      state.current_user = {}
    },
  },
})

export const {
  toggleShowForm,
  resetCurrentUser,
  updateErrors,
  toggleDeactivatedFilter,
  filterRoles,
  filterAffiliateRoles,
} = usersSlice.actions
export default usersSlice.reducer
