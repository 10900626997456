import * as React from 'react'
import axios from 'axios'
import i18next from 'i18next'
import isStrongPassword from 'validator/lib/isStrongPassword'
import { Button, Card, Form, Alert } from 'react-bootstrap'
import { useSearchParams, Link } from 'react-router-dom'

import './index.css'
import logo from 'assets/images/logo.svg'
import config from 'config'
import Footer from './footer'

const { t } = i18next

const withRouter = WrappedComponent => props => {
  const params = useSearchParams()
  return <WrappedComponent {...props} params={params} />
}

class Login extends React.Component {
  state = { password: '', confirm_password: '', message: '', message_type: '', go_to_login: false }

  handleKeyDown = event => {
    if (event.key === 'Enter') {
      this.handleSubmitPassword()
      event.preventDefault()
    }
  }

  handleChange = ({ target }) => {
    let { name, value } = target
    this.setState({ [name]: value, message: '' })
  }

  handleSubmitPassword = async () => {
    let { password, confirm_password } = this.state
    try {
      if (password !== confirm_password) {
        this.setState({ message: t('common.no_match_passwords', 'Password non corrispondono'), message_type: 'danger' })
      } else if (
        !isStrongPassword(password, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1 })
      ) {
        this.setState({
          message: t(
            'common.no_strong_password',
            'La password deve essere di minimo 8 caratteri e contenere almeno 1 lettera maiuscola, 1 lettera minuscola, 1 numero e 1 carattere speciale'
          ),
          message_type: 'danger',
        })
      } else {
        await axios({
          url: `${config.SERVER_API_URL}/users/password`,
          method: 'PATCH',
          data: {
            user: {
              password: this.state.password,
              password_confirmation: this.state.confirm_password,
              reset_password_token: this.state.reset_password_token,
            },
          },
        })
        this.setState({
          message: t('common.change_password_ok', 'Password modificata con successo. Torna al login'),
          message_type: 'success',
          go_to_login: true,
        })
      }
    } catch (err) {
      console.log(err)
      this.setState({
        message: t('common.change_password_error', 'Errore nella modifica password!'),
        message_type: 'danger',
      })
    }
  }
  componentDidMount() {
    let reset_password_token = this.props.params[0].get('reset_password_token')
    this.setState({ reset_password_token })
  }

  render() {
    let { password, confirm_password, message, message_type, go_to_login } = this.state

    return (
      <div className="d-flex justify-content-center align-items-center vh-100 flex-column">
        <Card style={{ width: '28rem', backgroundColor: '#f2f2f2' }} className="p-5 pb-1 mt-auto">
          <Card.Img variant="top" src={logo} />
          <Card.Body className="mt-5">
            {go_to_login === false && (
              <>
                <Alert variant={message_type} show={message !== ''} transition={false}>
                  {message}
                </Alert>
                <Form onKeyDown={this.handleKeyDown} autocomplete="off">
                  <Form.Label className="pb-2 text-muted">Inserisci la nuova password</Form.Label>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      as="input"
                      autocomplete="off"
                      name="password"
                      type="password"
                      placeholder="Nuova Password"
                      onChange={this.handleChange}
                      value={password}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      name="confirm_password"
                      type="password"
                      placeholder="Confirm Password"
                      onChange={this.handleChange}
                      value={confirm_password}
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    className="w-100 rounded-3 mt-2 mb-2"
                    disabled={password === '' || confirm_password === ''}
                    onClick={this.handleSubmitPassword}
                  >
                    CONFERMA
                  </Button>
                </Form>
              </>
            )}
            {go_to_login === true && (
              <Alert variant={message_type} show={message !== ''} transition={false}>
                {message}
              </Alert>
            )}
            <div className="fs-7 text-primary mt-2">
              <Link to="/login" className="text-decoration-none">
                <i className="fas fa-arrow-left" /> Torna al login
              </Link>
            </div>
          </Card.Body>
        </Card>
        <Footer />
      </div>
    )
  }
}

export default withRouter(Login)
