import React from 'react'
import { Card, Alert } from 'react-bootstrap'
import { Trans } from 'react-i18next'

import Footer from 'components/login/footer'
import logo from 'assets/images/logo.svg'

class NotFound extends React.Component {
  render() {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100 flex-column">
        <Card style={{ width: '50rem', backgroundColor: '#f2f2f2' }} className="p-5 pb-1 mt-auto align-items-center">
          <Card.Img variant="top" src={logo} style={{ width: '25rem' }} />
          <Card.Body className="mt-5 text-center">
            <Alert variant="primary" style={{ width: '30rem' }}>
              <Trans i18nKey="common.page_not_found">Pagina non trovata</Trans>
            </Alert>
          </Card.Body>
        </Card>
        <Footer />
      </div>
    )
  }
}

export default NotFound
