import React from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'
import { Trans } from 'react-i18next'
import { Table, Form, Button, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { isStateBefore } from 'lib/utils'
import StyledSelect from 'components/system_wide/styled_select'
import { getUsers } from 'store/users'
import { setCurrentAssignment } from 'store/assignments'
import {
  getAffiliatesRoles,
  getRoles,
  getUsersForRole,
  addAffiliate,
  deleteAffiliate,
  updateErrors,
  confirmAffiliates,
} from 'store/assignments/affiliates'

const { t } = i18next

const mstp = state => {
  let { roles, users, list, errors } = state.assignments.affiliates
  let { users: all_users } = state.usersData
  let { current_assignment } = state.assignments.data
  return { roles, users, list, all_users, current_assignment, errors }
}

class Affiliates extends React.Component {
  state = {
    selected_role: null,
    selected_user: null,
  }

  async componentDidMount() {
    await this.props.dispatch(setCurrentAssignment(this.props.assignment_id))
    await this.props.dispatch(
      getUsers({ offset: 0, limit: 100000, sortData: { sort_column: '', sort_direction: 'ASC' }, filter: '' })
    )
    await this.props.dispatch(getRoles())
    await this.props.dispatch(getAffiliatesRoles(this.props.assignment_id))
  }
  handleSelect = (value, { name }) => {
    if (name === 'selected_role') this.props.dispatch(getUsersForRole(value.id))
    this.setState({ [name]: value })
  }

  handleAddRoleUser = async () => {
    let { assignment_id } = this.props
    let { selected_role, selected_user } = this.state
    await this.props.dispatch(
      addAffiliate({ assignment_id, affiliate_role_id: selected_role.id, user_id: selected_user.value })
    )
    await this.props.dispatch(updateErrors({ errors: [] }))
    this.setState({ selected_role: null, selected_user: null })
  }

  deleteAffiliate = affiliate_id => () => {
    let { assignment_id } = this.props
    this.props.dispatch(deleteAffiliate({ assignment_id, affiliate_id }))
  }

  onConfirm = async () => {
    let { list, assignment_id } = this.props
    let min_roles = ['5', '6', '7']
    let selected_roles = list.map(el => el.affiliate_role_id)
    let intersection = min_roles.filter(x => selected_roles.includes(x))
    if (intersection && selected_roles.length) {
      await this.props.dispatch(confirmAffiliates(assignment_id))
    } else {
      await this.props.dispatch(
        updateErrors({
          errors: [
            t(
              'assignments.affiliates.min_roles',
              'Deve esistere almeno uno di questi ruoli: Key Account, Sales Manager o Team Manager'
            ),
          ],
        })
      )
    }
  }

  render() {
    let { roles, users, list, all_users, current_assignment, errors } = this.props
    let { selected_role, selected_user } = this.state
    let current_state = current_assignment?.current_state ?? false

    return (
      <div className="d-flex w-100 flex-column zi-1">
        <div className="w-100 h-100">
          <div
            className="p-3 text-primary fw-bold fs-2"
            role="button"
            style={{ borderBottom: '2px solid orange', fontSize: '1.2rem' }}
          >
            <Trans i18nKey="assignments.affiliates.title">Affiliati</Trans>
          </div>
          <div className="container-fluid row mt-2 gap-1">
            <div className="col-12 col-lg-6 border shadow-sm bg-white">
              <div className="fw-bold p-3">
                <h4>
                  <Trans i18nKey="assignments.affiliates.sub_title">Associa ruoli</Trans>
                </h4>
              </div>
              <div className="p-3">
                <Trans i18nKey="assignments.affiliates.sub_msg">
                  In questa sezione è possibile associare un utente al ruolo che deve dovrà ricoprire in merito a questo
                  incarico.
                </Trans>
                <div className="mt-3">
                  <Trans i18nKey="">
                    <b>NB:</b> L'incarico deve avere almeno uno dei seguenti ruoli:
                    <i> Key Account, Sales Manager, Team Manager</i>
                  </Trans>
                </div>
              </div>
              <div className="p-3 border m-2">
                <div className="row mb-3">
                  <div className="col-6">
                    <Form.Label>
                      <Trans i18nKey="assignments.affiliates.select_role">Ruolo:</Trans>
                    </Form.Label>
                    <StyledSelect
                      name="selected_role"
                      placeholder={t('assignments.affiliates.select_role', 'Seleziona il ruolo..')}
                      value={selected_role}
                      options={roles}
                      onChange={this.handleSelect}
                    />
                  </div>
                  <div className="col-6">
                    <Form.Label>
                      <Trans i18nKey="assignments.affiliates.select_user">Utente:</Trans>
                    </Form.Label>
                    <StyledSelect
                      name="selected_user"
                      placeholder={t('assignments.affiliates.select_user', 'Seleziona il utente..')}
                      value={selected_user}
                      options={users}
                      onChange={this.handleSelect}
                    />
                  </div>
                  <div className="flex-fill d-flex flex-column align-items-end mt-3">
                    <Button onClick={this.handleAddRoleUser} variant="primary" disabled={selected_user === null}>
                      <Trans i18nKey="assignments.add_modal.confirm_button">Aggiungi</Trans>
                    </Button>
                  </div>
                </div>
              </div>
              {errors?.length > 0 && (
                <Alert variant="danger" className="mx-2 mt-4">
                  {errors.map((err, i) => (
                    <li key={`err-${i}`}>{err}</li>
                  ))}
                </Alert>
              )}
            </div>
            <div className="col-12 col-lg-6 border shadow-sm bg-white px-0" style={{ marginRight: '-0.25rem' }}>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th></th>
                    <th>Ruolo</th>
                    <th>Utente</th>
                  </tr>
                </thead>
                <tbody>
                  {list.length === 0 && (
                    <tr style={{ verticalAlign: 'middle' }}>
                      <td colSpan="3" className="text-center">
                        <Trans i18nKey="assignments.affiliates.no_affiliates_msg">Nessun affiliato inserito</Trans>
                      </td>
                    </tr>
                  )}
                  {list.map(s => {
                    let user = all_users.find(u => parseInt(u.id) === parseInt(s.user_id)) ?? {}
                    let role = roles.find(r => parseInt(r.id) === parseInt(s.affiliate_role_id)) ?? {}
                    return (
                      <tr
                        key={s.id}
                        style={{ height: '3rem', verticalAlign: 'middle' }}
                        onClick={this.deleteAffiliate(s.id)}
                      >
                        <td width="4%" className="text-center">
                          <i className="fas fa-trash" />
                        </td>
                        <td width="36%">{role.name}</td>
                        <td width="60%" className="ps-3">
                          {user.name} {user.surname}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="align-self-baseline mt-auto flex-row d-flex w-100">
          <Table striped bordered className="w-100 mb-0">
            <thead style={{ borderTop: '2px solid var(--bs-primary)' }}>
              <tr>
                <th className="align-middle justify-content-end d-flex">
                  <Link to="/assignments" className="d-grid pe-3">
                    <Button variant="secondary" style={{ height: '3.5rem' }}>
                      <i className="fas fa-arrow-left" /> Indietro
                    </Button>
                  </Link>
                  {current_state && isStateBefore(current_state, 'configuration') && (
                    <Button
                      variant="success"
                      onClick={this.onConfirm}
                      style={{ height: '3.5rem' }}
                      disabled={list.length === 0}
                    >
                      <Trans i18nKey="assignments.affiliates.confirm_button">Conferma</Trans>
                    </Button>
                  )}
                  {current_state && !isStateBefore(current_state, 'configuration') && (
                    <div className="text-success text-center align-self-center">APPROVATO</div>
                  )}
                </th>
              </tr>
            </thead>
          </Table>
        </div>
      </div>
    )
  }
}

export default connect(mstp)(Affiliates)
