import React from 'react'
import ResponsiveTable from 'components/system_wide/responsive_table'

class ExpiredList extends React.Component {
  columns = [...this.props.columns]

  render() {
    return (
      <ResponsiveTable
        ref={this.props.tableRef}
        className={`flex-fill border ${this.props.className}`}
        rowClasses={this.props.rowClasses}
        headerClasses={this.props.headerClasses}
        menuWidth="70rem"
        data={this.props.expiredContracts}
        getData={this.props.getData}
        totalRecords={this.props.totalRecords}
        columns={this.columns}
      />
    )
  }
}

export default ExpiredList
