import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { Table, Button, Spinner, OverlayTrigger, Tooltip, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import 'scss/assignments.css'

import { setCurrentAssignment } from 'store/assignments'
import { confirmPrices, editPrice } from 'store/assignments/condominia'
import { getServices } from 'store/assignments/services'
import { isStateBefore } from 'lib/utils'

const mstp = state => {
  let { current_assignment, loading } = state.assignments.data
  let { services } = state.assignments.services
  return { current_assignment, loading, services }
}
class Prices extends React.Component {
  static defaultProps = {
    current_assignment: { dossiers: [] },
  }
  state = {
    hide_columns: true,
    prices: {},
    total_costs: [],
  }

  componentDidMount = async () => {
    await this.props.dispatch(getServices())
    await this.props.dispatch(setCurrentAssignment(this.props.assignment_id))

    let { condominia, dossiers, prices } = this.props.current_assignment
    let calculated_prices = condominia.reduce((acc, c) => {
      acc[c.id] = {}
      c.services.map(s => {
        let d = dossiers.find(d => d.service_id === s)
        acc[c.id][s] = isNaN(prices[c.id][d.id]) ? prices[c.id][d.id] : parseFloat(prices[c.id][d.id])
      })
      return acc
    }, {})
    let start_prices = condominia.reduce((acc, c) => {
      acc[c.id] = {}
      c.services.map(s => {
        let d = dossiers.find(d => d.service_id === s)
        acc[c.id][s] = isNaN(d.price) ? d.price : parseFloat(d.price)
      })
      return acc
    }, {})
    let total_costs = Object.keys(start_prices).reduce((acc, c) => {
      Object.keys(start_prices[c]).map(s => {
        let value = start_prices[c][s]
        acc[s] = isNaN(value) ? value : acc[s] ? acc[s] + parseFloat(value) : 0 + parseFloat(value)
      })
      return acc
    }, {})
    this.setState({ prices: calculated_prices, start_prices, total_costs })
  }

  onConfirmPrices = async () => {
    await this.props.dispatch(confirmPrices({ assignment_id: this.props.current_assignment.id }))
  }

  onHideColumns = () => this.setState({ hide_columns: !this.state.hide_columns })

  onBlur = (c_id, s_id) => async e => {
    let { start_prices } = this.state
    if (parseFloat(start_prices[c_id][s_id]) !== parseFloat(e.target.value)) {
      let { dossiers } = this.props.current_assignment
      let d = dossiers.find(d => d.service_id === s_id)
      await this.props.dispatch(
        editPrice({
          assignment_id: this.props.assignment_id,
          condominium_id: c_id,
          dossier_id: d.id,
          price: e.target.value,
        })
      )
    }
  }

  handleChange = (c_id, s_id) => e => {
    let prices = this.state.prices
    if (e.target.value === '') prices[c_id][s_id] = 0
    else prices[c_id][s_id] = parseFloat(e.target.value)
    this.setState({ prices })
  }

  renderInputBox = (c, s) => {
    let disabled = this.props.current_assignment.current_state === 'ready'
    if (Object.keys(this.state.prices).length === 0) return null
    let s_id = parseInt(s.value)
    if (c.services.includes(s_id)) {
      let value = this.state.prices[c.id][s_id] ?? null
      return (
        <div key={`${c.id}-${s_id}-input`}>
          <Form.Control
            disabled={isNaN(value) || disabled}
            name={`${c.id}-${s_id}-input`}
            value={value}
            onChange={this.handleChange(c.id, s_id)}
            onBlur={this.onBlur(c.id, s_id)}
            className={`text-center ${isNaN(value) || disabled ? '' : 'bg-white'}`}
          />
        </div>
      )
    } else {
      return '-'
    }
  }

  render() {
    let { condominia, current_state } = this.props.current_assignment
    let { services, loading } = this.props
    let { hide_columns, prices, total_costs } = this.state

    let totals = Object.keys(prices).reduce((acc, c) => {
      Object.keys(prices[c]).map(s => {
        let value = prices[c][s]
        acc[s] = isNaN(value) ? value : acc[s] ? acc[s] + parseFloat(value) : 0 + parseFloat(value)
      })
      return acc
    }, {})

    if (loading)
      return (
        <div className="d-flex w-100 flex-column zi-1">
          <div className="w-100">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      )

    let condominia_services = condominia.reduce((acc, c) => {
      return [...new Set([...acc, ...c.services])]
    }, [])
    let selected_services = services.filter(s => condominia_services.includes(parseInt(s.value)))
    let confirmation_enabled = selected_services.reduce(
      (acc, s) => acc && totals[s.value] === total_costs[s.value],
      true
    )
    return (
      <>
        <div className="d-flex flex-column w-100 overflow-auto z-1" style={{ height: 'calc(100% - 70px)' }}>
          <div
            className="d-flex flex-row w-100"
            style={{
              backgroundColor: 'var(--bs-body-bg)',
              position: 'sticky',
              top: 0,
              zIndex: 1,
            }}
          >
            <div
              className="d-flex text-left align-middle text-primary p-2 fw-bold h5 z-1"
              style={{
                minWidth: '20rem',
                backgroundColor: 'var(--bs-body-bg)',
                position: 'sticky',
                left: 0,
                borderBottom: '2px solid orange',
              }}
            >
              <div className="pt-1">Condomini</div>
              <div className="text-end pt-1 w-100" onClick={this.onHideColumns} role="button">
                {hide_columns ? (
                  <i className="fas fa-caret-square-right" />
                ) : (
                  <i className="fas fa-caret-square-left" />
                )}
              </div>
            </div>
            <div
              className={`text-center align-middle text-muted p-3 fw-bold h6 ${hide_columns ? 'd-none' : ''}`}
              style={{ minWidth: '25rem', borderBottom: '2px solid orange' }}
            >
              <Trans i18nKey="assignments.services.number">Indirizzo</Trans>
            </div>
            <div
              className={`text-center align-middle text-muted p-3 fw-bold h6 ${hide_columns ? 'd-none' : ''} `}
              style={{ minWidth: '20rem', borderBottom: '2px solid orange' }}
            >
              <Trans i18nKey="assignments.services.cost">Codice fiscale</Trans>
            </div>
            <div
              className={`text-center align-middle text-muted p-3 fw-bold h6 ${hide_columns ? 'd-none' : ''}`}
              style={{ minWidth: '25rem', borderBottom: '2px solid orange' }}
            >
              <Trans i18nKey="assignments.services.commission">Iban</Trans>
            </div>
            {selected_services.map((s, idx) => {
              let target = React.createRef()
              return (
                <div
                  key={s.value}
                  className="text-center align-center align-middle text-muted p-3 fw-bold h6"
                  style={{
                    width: '12rem',
                    minWidth: '12rem',
                    borderBottom: '2px solid orange',
                  }}
                >
                  <OverlayTrigger key={s.value} trigger={['hover', 'click']} overlay={<Tooltip>{s.label}</Tooltip>}>
                    <div className="d-flex justify-content-center">
                      <div style={{ width: '1.3rem' }} className="d-flex align-items-center">
                        <i ref={target} className={`${s.icon_classes} m-auto`} />
                      </div>
                      <div className="ps-2 text-truncate">{s.label}</div>
                    </div>
                  </OverlayTrigger>
                </div>
              )
            })}
            <div
              className="text-end p-3 fw-bold text-muted h6 justify-content-center"
              style={{
                width: '100%',
                minWidth: '1rem',
                borderBottom: '2px solid orange',
                backgroundColor: 'var(--bs-body-bg)',
              }}
            ></div>
          </div>
          {condominia.map(c => {
            return (
              <div className="d-flex flex-row text-center" key={`${c.id}-condominium`}>
                <div
                  className="p-3 border-bottom"
                  style={{ minWidth: '20rem', backgroundColor: 'var(--bs-body-bg)', position: 'sticky', left: 0 }}
                >
                  {c.name}
                </div>
                <div className={`${hide_columns ? 'd-none' : ''} p-3 border-bottom`} style={{ minWidth: '25rem' }}>
                  {c.cap} - {c.city.name} - {c.street}
                </div>
                <div className={`${hide_columns ? 'd-none' : ''} p-3 border-bottom`} style={{ minWidth: '20rem' }}>
                  {c.fiscal_code}
                </div>
                <div className={`${hide_columns ? 'd-none' : ''} p-3 border-bottom`} style={{ minWidth: '25rem' }}>
                  {c.iban}
                </div>
                {selected_services.map(s => {
                  return (
                    <div
                      key={`value-${c.id}-${s.value}`}
                      className="p-3 border-bottom"
                      style={{
                        width: '12rem',
                        minWidth: '12rem',
                      }}
                    >
                      {this.renderInputBox(c, s)}
                    </div>
                  )
                })}
              </div>
            )
          })}
          {selected_services.length > 0 && (
            <div
              className="d-flex flex-row w-100 pt-1"
              style={{
                backgroundColor: 'white',
                position: 'sticky',
                zIndex: 1,
              }}
            >
              <div
                className="p-2 fw-bold"
                style={{
                  minWidth: '20rem',
                  position: 'sticky',
                  left: 0,
                  borderTop: '2px solid orange',
                  borderBottom: '2px solid orange',
                }}
              >
                Totali
              </div>
              <div
                style={{
                  ...(hide_columns ? { minWidth: '0rem' } : { minWidth: '70rem' }),
                  borderTop: '2px solid orange',
                  borderBottom: '2px solid orange',
                }}
              ></div>
              {selected_services.map(s => {
                return (
                  <OverlayTrigger
                    key={'totals-' + s.value}
                    trigger={['hover', 'click']}
                    overlay={<Tooltip>{total_costs[s.value]}</Tooltip>}
                  >
                    <div
                      key={`value-${s.id}-${s.value}`}
                      className="p-2 text-center fw-bold"
                      style={{
                        backgroundColor: 'white',
                        width: '12rem',
                        minWidth: '12rem',
                        borderTop: '2px solid orange',
                        borderBottom: '2px solid orange',
                        ...(totals[s.value] !== total_costs[s.value] ? { color: 'red' } : {}),
                      }}
                    >
                      {totals[s.value]}
                    </div>
                  </OverlayTrigger>
                )
              })}
              <div
                style={{
                  backgroundColor: 'white',
                  minWidth: '1rem',
                  width: '100%',
                  borderTop: '2px solid orange',
                  borderBottom: '2px solid orange',
                }}
              ></div>
            </div>
          )}
        </div>
        <div
          style={{
            bottom: 0,
            left: 0,
            paddingLeft: 50,
            width: '100%',
            position: 'absolute',
            backgroundColor: 'white',
          }}
        >
          <Table striped bordered className="w-100 mb-0">
            <thead style={{ borderTop: '2px solid var(--bs-primary)' }}>
              <tr>
                <th width="70%"></th>
                <th width="10%" className="align-middle">
                  <Link to="/assignments" className="d-grid">
                    <Button variant="secondary" style={{ height: '3.5rem' }}>
                      <i className="fas fa-arrow-left" /> Indietro
                    </Button>
                  </Link>
                </th>
                <th width="10%" className="align-middle">
                  <div className="d-grid">
                    {current_state && current_state === 'price_definition' && (
                      <Button
                        variant="success"
                        value="confirm"
                        onClick={this.onConfirmPrices}
                        style={{ height: '3.5rem', minWidth: '7rem' }}
                        disabled={condominia.length === 0 || confirmation_enabled === false}
                      >
                        Conferma
                      </Button>
                    )}
                    {current_state && !isStateBefore(current_state, 'ready') && (
                      <div className="text-success text-center align-self-center">APPROVATO</div>
                    )}
                  </div>
                </th>
              </tr>
            </thead>
          </Table>
        </div>
      </>
    )
  }
}

export default connect(mstp)(Prices)
