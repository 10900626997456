import React from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'
import { Trans } from 'react-i18next'

import { setCurrentAssignment } from 'store/assignments'
import { getServices } from 'store/assignments/services'
import ListCondominia from './list-condominia'
import EconomicSummary from './economic-summary'
import DossiersGroup from './dossiers'

const { t } = i18next

const mstp = state => {
  let { current_assignment, loading } = state.assignments.data
  let { services } = state.assignments.services
  return { current_assignment, loading, services }
}

class Dashboard extends React.Component {
  async componentDidMount() {
    await this.props.dispatch(getServices())
    await this.props.dispatch(setCurrentAssignment(this.props.assignment_id))
  }

  render() {
    let { dossiers } = this.props.current_assignment
    return (
      <div className="d-flex w-100 flex-column zi-1 overflow-auto">
        <div className="w-100 h-100">
          <div
            className="p-3 text-primary fw-bold fs-2"
            role="button"
            style={{ borderBottom: '2px solid orange', fontSize: '1.2rem' }}
          >
            <Trans i18nKey="assignments.dashboard.title">Dashboard</Trans>
          </div>
          <div className="container-fluid row mt-2 p-0 ps-3">
            <div className="col-12 col-lg-6 ">
              <ListCondominia assignment={this.props.current_assignment} services={this.props.services} />
            </div>
            <div className="col-12 col-lg-6 ">
              <EconomicSummary assignment={this.props.current_assignment} services={this.props.services} />
            </div>
          </div>
          <div className="d-flex flex-wrap container-fluid row p-0 ps-3">
            <DossiersGroup dossiers={dossiers} assignment_id={this.props.current_assignment.id} />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mstp)(Dashboard)
