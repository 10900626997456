import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'lib/axios'
import i18next from 'i18next'

import config from 'config'
import { error, success } from 'components/system_wide/notification'
import { redirectLocation } from 'store/common'
import { resetCurrentAssignment, setCurrentAssignment } from 'store/assignments'

const { t } = i18next

export const initialState = {
  errors: [],
  loading: false,
  show_add_condominia: false,
  edit_condominia: {},
}

export const createCondominium = createAsyncThunk(
  'assignments/createCondominium',
  async ({ assignment_id, condominium }, thunkApi) => {
    try {
      await axios({
        url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}/condominia`,
        method: 'POST',
        data: { ...condominium },
      })
      thunkApi.dispatch(setLoading(true))
      thunkApi.dispatch(resetCurrentAssignment())
      thunkApi.dispatch(setCurrentAssignment(assignment_id))
      thunkApi.dispatch(toggleShowAddCondominia())
      success(t('store.assignments.create_condomium_success', 'Condominio inserito con successo'))
    } catch (err) {
      console.log(err)
      let {
        data: { errors: from_server },
      } = err.response
      let errors = { from_server: Array.isArray(from_server) ? from_server : [from_server] }
      thunkApi.dispatch(updateErrors({ errors }))
      error(t('store.users.create_assignment_condominium_error', 'Impossibile aggiungere il condominio'))
      return thunkApi.rejectWithValue()
    }
  }
)
export const editCondominium = createAsyncThunk(
  'assignments/editCondominium',
  async ({ assignment_id, condominium }, thunkApi) => {
    try {
      await axios({
        url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}/condominia/${condominium.id}`,
        method: 'PATCH',
        data: { ...condominium },
      })
      thunkApi.dispatch(setLoading(true))
      thunkApi.dispatch(resetCurrentAssignment())
      thunkApi.dispatch(setCurrentAssignment(assignment_id))
      thunkApi.dispatch(toggleShowAddCondominia())
      success(t('store.assignments.update_condomium_success', 'Condominio aggiornato con successo'))
    } catch (err) {
      console.log(err)
      let {
        data: { errors: from_server },
      } = err.response
      let errors = { from_server: Array.isArray(from_server) ? from_server : [from_server] }
      thunkApi.dispatch(updateErrors({ errors }))
      error(t('store.users.update_assignment_condominium_error', 'Impossibile aggiornare il condominio'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const getCondominium = createAsyncThunk(
  'assignments/getCondominium',
  async ({ assignment_id, condominium_id }, thunkApi) => {
    try {
      let response = await axios({
        url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}/condominia/${condominium_id}`,
        method: 'GET',
      })
      let { attributes, id } = response.data.data
      let { included } = response.data
      let { cap, city, fiscal_code, name, iban, real_estate_units, street, services } = attributes
      let region = included.find(c => city.id.toString() === c.id)?.attributes?.region_id

      return {
        id,
        name,
        fiscal_code,
        iban,
        cap,
        region,
        province: city.province_id,
        city_id: city.id,
        country_id: 1,
        street,
        real_estate_units,
        services: services?.map(s => s.toString()),
      }
    } catch (err) {
      console.log(err)
      error(t('store.assignments.get_condominia_error', 'Impossibile recuperare il condominio'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const deleteCondominium = createAsyncThunk(
  'assignments/deleteCondominium',
  async ({ assignment_id, condominium_id }, thunkApi) => {
    try {
      await axios({
        url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}/condominia/${condominium_id}`,
        method: 'DELETE',
      })
      thunkApi.dispatch(setLoading(true))
      thunkApi.dispatch(resetCurrentAssignment())
      thunkApi.dispatch(setCurrentAssignment(assignment_id))
      success(t('store.assignments.delete_condominium_success', 'Condominio eliminato con successo'))
    } catch (err) {
      console.log(err)
      error(t('store.users.create_assignment_condominium_error', 'Impossibile eliminare il condominio'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const confirmCondominia = createAsyncThunk(
  'assignments/confirmCondominia',
  async ({ assignment_id, action }, thunkApi) => {
    try {
      let confirmed = null
      switch (action) {
        case 'tofix':
          confirmed = false
          break
        case 'confirm':
          confirmed = true
          break
        default:
          break
      }
      await axios({
        url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}`,
        method: 'PUT',
        ...(confirmed !== null ? { data: { confirmed } } : {}),
      })
      thunkApi.dispatch(setCurrentAssignment(assignment_id))
      thunkApi.dispatch(redirectLocation(`/assignments`))
      success(t('store.assignments.confirm_condominia_success', 'Condomini confermati con successo'))
    } catch (err) {
      console.log(err)
      error(t('store.assignments.change_state_assignment_error', 'Impossibile aggiornare lo stato'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const confirmPrices = createAsyncThunk('assignments/confirmPrices', async ({ assignment_id }, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}`,
      method: 'PUT',
      ...{ data: { confirmed: true } },
    })
    thunkApi.dispatch(setCurrentAssignment(assignment_id))
    thunkApi.dispatch(redirectLocation(`/assignments`))
    success(t('store.assignments.confirm_condominia_success', 'Prezzi confermati con successo'))
  } catch (err) {
    console.log(err)
    error(t('store.assignments.change_state_assignment_error', 'Impossibile aggiornare lo stato'))
    return thunkApi.rejectWithValue()
  }
})

export const editPrice = createAsyncThunk(
  'assignments/editPrice',
  async ({ assignment_id, condominium_id, dossier_id, price }, thunkApi) => {
    try {
      await axios({
        url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}/dossiers/${dossier_id}/condominia/${condominium_id}`,
        method: 'PUT',
        data: { price },
      })
    } catch (err) {
      console.log(err)
      let {
        data: { errors: from_server },
      } = err.response
      let errors = { from_server: Array.isArray(from_server) ? from_server : [from_server] }
      thunkApi.dispatch(updateErrors({ errors }))
      error(t('store.users.update_assignment_condominium_error', 'Impossibile aggiornare il prezzo'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const condominiaSlice = createSlice({
  name: 'assignments/condominia',
  initialState,

  reducers: {
    toggleShowAddCondominia: state => {
      state.show_add_condominia = !state.show_add_condominia
      state.errors = []
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    updateErrors: (state, action) => {
      let { errors } = action.payload
      state.errors = errors
    },
    resetCurrentCondominium: state => {
      state.edit_condominia = {}
    },
  },
  extraReducers: {
    [getCondominium.fulfilled]: (state, action) => {
      state.edit_condominia = { ...action.payload }
    },
    [getCondominium.rejected]: state => {
      state.edit_condominia = {}
    },
  },
})

export const { setLoading, toggleShowAddCondominia, updateErrors, resetCurrentCondominium } = condominiaSlice.actions
export default condominiaSlice.reducer
