import React from 'react'
import i18next from 'i18next'
import axios from 'lib/axios'
import config from 'config'
import FileDropzone from 'components/system_wide/file_dropzone'
import { error } from 'components/system_wide/notification'

const { t } = i18next

class ColumnDropzone extends React.Component {
  state = {
    dimmed: false,
    uploaded: false,
    errored: false,
  }

  resetSstatus = () => {
    this.setState({ uploaded: false, dimmed: false, errored: false })
    this.props.onFinishDrop()
  }
  onDrop = id => async file => {
    const formData = new FormData()
    formData.append('_jsonapi[document]', file)
    formData.append('accepted', true)
    try {
      if (file.size === 0) throw Error('File size = 0!')
      await axios.post(`${config.SERVER_API_URL}/v1/contracts/${id}/attachments`, formData)
      this.setState({ uploaded: true, dimmed: false }, () => setTimeout(this.resetSstatus, 2000))
    } catch (err) {
      if (file.size === 0) {
        error('Impossibile caricare il file! La sua grandezza deve essere maggiore di 0 byte')
      } else {
        error(
          `${t('store.contracts.upload_doc_contract_error', 'Impossibile caricare il file')} ${
            err?.response?.data?.errors?.title ?? ''
          }`
        )
      }
      this.setState({ dimmed: false, uploaded: false, errored: true }, () => setTimeout(this.resetSstatus, 2000))
    }
  }

  render() {
    let { id } = this.props
    let { dimmed, uploaded, errored } = this.state
    return (
      <FileDropzone
        title={<i className="fa fa-cloud-arrow-up" style={{ color: 'grey' }} />}
        tableColumn={true}
        dimmed={dimmed}
        uploaded={uploaded}
        errored={errored}
        onDrop={this.onDrop(id)}
        accept={['xlsx', 'pdf', 'jpg', 'png']}
      />
    )
  }
}

export default ColumnDropzone
