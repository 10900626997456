import React from 'react'
import { connect } from 'react-redux'
import { Button, Form, Alert, Modal } from 'react-bootstrap'
import i18next from 'i18next'
import hash from 'object-hash'
import { Trans } from 'react-i18next'

import validateFields from 'lib/validators/user'
import { resetCurrentUser, updateErrors, updateUser, resetPasswordUser } from 'store/users'
import SelectMunicipality from 'components/system_wide/select_municipality'
import ReqResetPasswordModal from 'components/system_wide/req_reset_password_modal'

import { setCurrentUser } from 'store/userinfo'
import { isAdmin as _isAdmin } from 'lib/utils'
import { getRoles } from 'store/users'

const { t } = i18next
const mstp = state => {
  let user = state.userInfo.info
  let { role_id } = state.userInfo
  let { roles = {} } = state.usersData
  let errors = state.usersData.errors
  let my_id = state.userInfo.id
  let isAdmin = _isAdmin(role_id, roles)
  return { my_id, user, errors, isAdmin, roles }
}

const initialState = {
  id: null,
  name: '',
  surname: '',
  email: '',
  deactivated: false,
  role_id: '',
  tax_datum_attributes: {
    email: '',
    business_name: '',
    vat_number: '',
    street_address: '',
    cap: '',
    city: '',
    region: '',
    province: '',
    phone: '',
    mobile_phone: '',
    sdi: '',
    country_id: 1,
  },
  show_modal_reset_password: false,
}

class UserProfile extends React.Component {
  state = {
    user: initialState,
    user_hash: '',
  }

  componentDidMount = async () => {
    if (Object.keys(this.props.roles).length === 0) {
      await this.props.dispatch(getRoles())
    }
    await this.props.dispatch(setCurrentUser(this.props.my_id))
    let user = { ...initialState, ...this.props.user }
    let user_hash = hash(user)
    this.setState({ user, user_hash })
    this.initialHash = hash(user)
  }
  componentDidUpdate = async prevProps => {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      await this.props.dispatch(updateErrors({ errors: [] }))
      await this.props.dispatch(setCurrentUser(this.props.my_id))
    }
    if (prevProps.show !== this.props.show && this.props.show === false) {
      await this.props.dispatch(updateErrors({ errors: [] }))
      await this.props.dispatch(resetCurrentUser())
    }
    if (prevProps.user.id !== this.props.user.id) {
      let user = { ...initialState, ...this.props.user }
      let user_hash = hash(user)
      this.setState({ user, user_hash })
      this.initialHash = hash(user)
    }
  }
  updateHash = () => {
    let { user } = this.state
    this.setState({ user_hash: hash(user) })
  }
  handleChange = ({ target: { name, value } }) => {
    let user = { ...this.state.user }
    user[name] = value
    this.setState({ user }, () => this.updateHash())
  }
  handleEmailChange = ({ target: { name, value } }) => {
    let user = { ...this.state.user }
    user[name] = value
    let tax_datum_attributes = { ...user.tax_datum_attributes }
    tax_datum_attributes[name] = value
    this.setState({ user: { ...user, tax_datum_attributes } }, () => this.updateHash())
  }
  handleTaxAttributesChange = ({ target: { name, value } }) => {
    let user = { ...this.state.user }
    let tax_datum_attributes = { ...user.tax_datum_attributes }
    tax_datum_attributes[name] = value
    this.setState({ user: { ...user, tax_datum_attributes } }, () => this.updateHash())
  }

  handleMunicipalityChange = values => {
    let user = { ...this.state.user }
    let tax_datum_attributes = { ...user.tax_datum_attributes, ...values }
    this.setState({ user: { ...user, tax_datum_attributes } }, () => this.updateHash())
  }

  isFormValid = () => {
    let { isAdmin } = this.props
    let { name, surname, email } = this.state.user
    let { vat_number, business_name } = this.state.user.tax_datum_attributes
    return name !== '' && surname !== '' && email !== '' && (isAdmin || (vat_number !== '' && business_name !== ''))
  }

  handleConfirm = async () => {
    let errors = validateFields({ ...this.state.user, ...this.state.user.tax_datum_attributes }, true)
    if (Object.keys(errors).length) {
      this.props.dispatch(updateErrors({ errors }))
    } else {
      await this.props.dispatch(updateUser(this.state.user))
      await this.props.dispatch(setCurrentUser(this.state.user.id))
      this.props.handleShow()
    }
    this.setState({ validated: true })
  }

  resetPassword = () => this.setState({ show_modal_reset_password: true })
  onCancelResetPassword = () => this.setState({ show_modal_reset_password: false })
  onConfirmResetPassword = async () => {
    await this.props.dispatch(resetPasswordUser(this.state.user.email))
    this.setState({ show_modal_reset_password: false })
  }

  render() {
    let { user, validated } = this.state
    let { name, surname, email, tax_datum_attributes } = user
    let { street_address, phone, cap, city_id, vat_number, business_name, mobile_phone, sdi, province, region } =
      tax_datum_attributes
    let { errors, isAdmin } = this.props

    return (
      <>
        <Modal size="lg" show={this.props.show} onHide={this.props.handleShow}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Trans i18nKey="users.user_form.my_profile">Il mio profilo</Trans>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {errors?.from_server?.length > 0 && (
                <Alert variant="danger">
                  <Alert.Heading>Error:</Alert.Heading>
                  <ul>
                    {errors.from_server.map((err, i) => (
                      <li key={`err-${i}`}>{err.detail}</li>
                    ))}
                  </ul>
                </Alert>
              )}
            </div>
            <div className="row">
              <div className="col">
                <Form.Group className="mt-3 mb-3">
                  <Form.Label>
                    <Trans i18nKey="users.user_form.name">Nome</Trans>
                  </Form.Label>
                  <span> *</span>
                  <Form.Control
                    name="name"
                    placeholder={t('users.user_form.name', 'Nome')}
                    value={name}
                    onChange={this.handleChange}
                    isInvalid={validated && 'name' in errors}
                  />
                  <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group className="mt-3 mb-3">
                  <Form.Label>
                    <Trans i18nKey="users.user_form.surname">Cognome</Trans>
                  </Form.Label>
                  <span> *</span>
                  <Form.Control
                    name="surname"
                    placeholder={t('users.user_form.surname', 'Cognome')}
                    value={surname}
                    onChange={this.handleChange}
                    isInvalid={validated && 'surname' in errors}
                  />
                  <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group className="mt-3 mb-3">
                  <Form.Label>
                    <Trans i18nKey="users.user_form.email">Email</Trans>
                  </Form.Label>
                  <span> *</span>
                  <Form.Control
                    name="email"
                    placeholder={t('users.user_form.email', 'Email')}
                    value={email}
                    onChange={this.handleEmailChange}
                    isInvalid={validated && 'email' in errors}
                  />
                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>

            <div className="mt-3 mb-2 pb-0 border-bottom text-primary">
              <b>Dati fiscali</b>
            </div>
            <div style={{ opacity: isAdmin ? 0.3 : 1 }}>
              <div className="row">
                <div className="col">
                  <Form.Group className="mt-2 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.business_name">Ragione sociale</Trans>
                    </Form.Label>
                    {!isAdmin && <span> *</span>}
                    <Form.Control
                      name="business_name"
                      placeholder={t('users.user_form.business_name', 'Ragione sociale')}
                      value={business_name}
                      onChange={this.handleTaxAttributesChange}
                      disabled={isAdmin}
                    />
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mt-2 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.vat_number">P.I.</Trans>
                    </Form.Label>
                    {!isAdmin && <span> *</span>}
                    <Form.Control
                      name="vat_number"
                      placeholder={t('users.user_form.vat_number', 'P.I.')}
                      value={vat_number}
                      onChange={this.handleTaxAttributesChange}
                      isInvalid={validated && 'vat_number' in errors}
                      disabled={isAdmin}
                    />
                    <Form.Control.Feedback type="invalid">{errors.vat_number}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mt-2 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.sdi">SDI</Trans>
                    </Form.Label>
                    <Form.Control
                      name="sdi"
                      placeholder={t('users.user_form.sdi', 'SDI')}
                      value={sdi}
                      onChange={this.handleTaxAttributesChange}
                      disabled={isAdmin}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.street_address">Indirizzo</Trans>
                    </Form.Label>
                    <Form.Control
                      name="street_address"
                      placeholder={t('users.user_form.street_address', 'Indirizzo')}
                      value={street_address}
                      onChange={this.handleTaxAttributesChange}
                      disabled={isAdmin}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <SelectMunicipality
                  region={region}
                  province={province}
                  city={city_id}
                  cap={cap}
                  onChange={this.handleMunicipalityChange}
                  isReadOnly={isAdmin}
                />
              </div>
              <div className="row">
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.tel">Telefono</Trans>
                    </Form.Label>
                    <Form.Control
                      name="phone"
                      placeholder={t('users.user_form.tel', 'Telefono')}
                      value={phone}
                      onChange={this.handleTaxAttributesChange}
                      disabled={isAdmin}
                    />
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.mobile_phone">Cellulare</Trans>
                    </Form.Label>
                    <Form.Control
                      name="mobile_phone"
                      placeholder={t('users.user_form.mobile_phone', 'Cellulare')}
                      value={mobile_phone}
                      onChange={this.handleTaxAttributesChange}
                      disabled={isAdmin}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.handleShow} className="me-auto">
              Chiudi
            </Button>
            <Button style={{ position: 'absolute', left: 10 }} variant="secondary" onClick={this.resetPassword}>
              Cambia password
            </Button>
            <Button
              variant="primary"
              onClick={this.handleConfirm}
              disabled={!this.isFormValid() || this.initialHash === this.state.user_hash}>
              Aggiorna
            </Button>
          </Modal.Footer>
        </Modal>
        <ReqResetPasswordModal
          onCancelResetPassword={this.onCancelResetPassword}
          onConfirmResetPassword={this.onConfirmResetPassword}
          show={this.state.show_modal_reset_password}
          user_profile={true}
        />
      </>
    )
  }
}

export default connect(mstp)(UserProfile)
