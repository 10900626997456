import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { Table, Button, Dropdown, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import 'scss/assignments.css'

import { resetCurrentAssignment, setCurrentAssignment } from 'store/assignments'
import {
  deleteCondominium,
  confirmCondominia,
  getCondominium,
  toggleShowAddCondominia,
} from 'store/assignments/condominia'
import { getServices } from 'store/assignments/services'
import { isStateBefore } from 'lib/utils'
import DeleteCondominiumModal from './delete_condominium_modal'
import AddAssignmentCondominia from 'components/assignments/condominia/add_condominia_modal'

const mstp = state => {
  let { current_assignment, loading } = state.assignments.data
  let { services } = state.assignments.services
  return { current_assignment, loading, services }
}
class AssignmentCondominia extends React.Component {
  static defaultProps = {
    current_assignment: { dossiers: [] },
  }

  componentDidMount = async () => {
    await this.props.dispatch(getServices())
    await this.props.dispatch(setCurrentAssignment(this.props.assignment_id))
  }

  state = {
    condominium_id: '',
    condominium_name: '',
    show_delete_modal: false,
    hide_columns: true,
    fixed: false,
    scrollX: 0,
  }

  onHandleEdit = condominium_id => async () => {
    await this.props.dispatch(getCondominium({ assignment_id: this.props.current_assignment.id, condominium_id }))
    this.props.dispatch(toggleShowAddCondominia())
  }
  onHandleDelete = (id, name) => () => {
    this.setState({ show_delete_modal: true, condominium_id: id, condominium_name: name })
  }
  onCloseModal = () => this.setState({ show_delete_modal: false })

  onConfirmDelete = () => {
    this.props.dispatch(
      deleteCondominium({ assignment_id: this.props.current_assignment.id, condominium_id: this.state.condominium_id })
    )
    this.setState({ show_delete_modal: false })
  }
  onCloseCondominia = () => {
    this.props.dispatch(resetCurrentAssignment())
  }

  onConfirmCondominia = async ({ target: { value } }) => {
    await this.props.dispatch(confirmCondominia({ assignment_id: this.props.current_assignment.id, action: value }))
  }

  onHideColumns = () => this.setState({ hide_columns: !this.state.hide_columns })

  render() {
    let { condominia, current_state, dossiers } = this.props.current_assignment
    let { services, loading } = this.props
    let { hide_columns } = this.state

    let totals = condominia.reduce((acc, c) => {
      c.services.map(s => {
        let d = dossiers.find(d => d.service_id === s)
        acc[s] = isNaN(d.price) ? d.price : acc[s] ? acc[s] + parseFloat(d.price) : 0 + parseFloat(d.price)
      })
      return acc
    }, {})

    if (loading)
      return (
        <div className="d-flex w-100 flex-column zi-1">
          <div className="w-100">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      )
    let disabled_confirm = false
    let condominia_services = condominia.reduce((acc, c) => {
      if (c.services.length === 0) disabled_confirm = true
      return [...new Set([...acc, ...c.services])]
    }, [])
    let selected_services = services.filter(s => condominia_services.includes(parseInt(s.value)))

    return (
      <>
        <div class="d-flex flex-column w-100 overflow-auto z-1" style={{ height: 'calc(100% - 70px)' }}>
          <div
            className="d-flex flex-row w-100"
            style={{
              backgroundColor: 'var(--bs-body-bg)',
              position: 'sticky',
              top: 0,
              zIndex: 1,
            }}
          >
            <div
              className="d-flex text-left align-middle text-primary p-2 fw-bold h5 z-1"
              style={{
                minWidth: '20rem',
                backgroundColor: 'var(--bs-body-bg)',
                position: 'sticky',
                left: 0,
                borderBottom: '2px solid orange',
              }}
            >
              <AddAssignmentCondominia />
              <div className="text-end pt-1 w-100" onClick={this.onHideColumns} role="button">
                {hide_columns ? (
                  <i className="fas fa-caret-square-right" />
                ) : (
                  <i className="fas fa-caret-square-left" />
                )}
              </div>
            </div>
            <div
              className={`text-center align-middle text-muted p-3 fw-bold h6 ${hide_columns ? 'd-none' : ''}`}
              style={{ minWidth: '25rem', borderBottom: '2px solid orange' }}
            >
              <Trans i18nKey="assignments.services.number">Indirizzo</Trans>
            </div>
            <div
              className={`text-center align-middle text-muted p-3 fw-bold h6 ${hide_columns ? 'd-none' : ''} `}
              style={{ minWidth: '20rem', borderBottom: '2px solid orange' }}
            >
              <Trans i18nKey="assignments.services.cost">Codice fiscale</Trans>
            </div>
            <div
              className={`text-center align-middle text-muted p-3 fw-bold h6 ${hide_columns ? 'd-none' : ''}`}
              style={{ minWidth: '25rem', borderBottom: '2px solid orange' }}
            >
              <Trans i18nKey="assignments.services.commission">Iban</Trans>
            </div>
            {selected_services.map((s, idx) => {
              let target = React.createRef()
              return (
                <div
                  key={s.value}
                  className="text-center align-center align-middle text-muted p-3 fw-bold h6"
                  style={{
                    width: '12rem',
                    minWidth: '12rem',
                    borderBottom: '2px solid orange',
                  }}
                >
                  <OverlayTrigger key={s.value} trigger={['hover', 'click']} overlay={<Tooltip>{s.label}</Tooltip>}>
                    <div className="d-flex justify-content-center">
                      <div style={{ width: '1.3rem' }} className="d-flex align-items-center">
                        <i ref={target} className={`${s.icon_classes} m-auto`} />
                      </div>
                      <div className="ps-2 text-truncate">{s.label}</div>
                    </div>
                  </OverlayTrigger>
                </div>
              )
            })}
            <div
              className="text-end p-3 fw-bold text-muted h6 justify-content-center"
              style={{
                width: '100%',
                minWidth: '10rem',
                borderBottom: '2px solid orange',
                backgroundColor: 'var(--bs-body-bg)',
              }}
            >
              <div style={{ marginRight: '0.5rem' }}>Actions</div>
            </div>
          </div>
          {condominia.map(c => {
            return (
              <div className="d-flex flex-row text-center">
                <div
                  className="p-3 border-bottom"
                  style={{ minWidth: '20rem', backgroundColor: 'var(--bs-body-bg)', position: 'sticky', left: 0 }}
                >
                  {c.name}
                </div>
                <div className={`${hide_columns ? 'd-none' : ''} p-3 border-bottom`} style={{ minWidth: '25rem' }}>
                  {c.cap} - {c.city.name} - {c.street}
                </div>
                <div className={`${hide_columns ? 'd-none' : ''} p-3 border-bottom`} style={{ minWidth: '20rem' }}>
                  {c.fiscal_code}
                </div>
                <div className={`${hide_columns ? 'd-none' : ''} p-3 border-bottom`} style={{ minWidth: '25rem' }}>
                  {c.iban}
                </div>
                {selected_services.map(s => {
                  let service = this.props.services.find(ser => ser.value === s.value)
                  let service_option = service.service_options.find(
                    so => parseInt(so.value) === parseInt(c.services_options[service.value])
                  )
                  return (
                    <div
                      key={`value-${c.id}-${s.value}`}
                      className="p-3 border-bottom"
                      style={{
                        width: '12rem',
                        minWidth: '12rem',
                      }}
                    >
                      {c.services.includes(parseInt(s.value)) ? (
                        service_option?.label ? (
                          service_option.label
                        ) : (
                          <i className="fas fa-check" />
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  )
                })}
                <div className="p-2 border-bottom text-end" style={{ width: '100%', minWidth: '10rem' }}>
                  <Dropdown>
                    <Dropdown.Toggle variant="warning" id="dropdown-basic">
                      <Trans i18nKey="common.actions_button">Actions</Trans>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={this.onHandleEdit(c.id)}>Modifica</Dropdown.Item>
                      <Dropdown.Item onClick={this.onHandleDelete(c.id, c.name)}>Elimina</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            )
          })}
          {selected_services.length > 0 && (
            <div
              className="d-flex flex-row w-100 pt-1"
              style={{
                backgroundColor: 'white',
                position: 'sticky',
                zIndex: 1,
                // bottom: 70,
                // marginTop: 80,
              }}
            >
              <div
                className="p-2 fw-bold"
                style={{
                  minWidth: '20rem',
                  position: 'sticky',
                  left: 0,
                  borderTop: '2px solid orange',
                  borderBottom: '2px solid orange',
                }}
              >
                Totali
              </div>
              <div
                style={{
                  ...(hide_columns ? { minWidth: '0rem' } : { minWidth: '70rem' }),
                  borderTop: '2px solid orange',
                  borderBottom: '2px solid orange',
                }}
              ></div>
              {selected_services.map(s => {
                return (
                  <div
                    key={`value-${s.id}-${s.value}`}
                    className="p-2 text-center fw-bold"
                    style={{
                      backgroundColor: 'white',
                      width: '12rem',
                      minWidth: '12rem',
                      borderTop: '2px solid orange',
                      borderBottom: '2px solid orange',
                    }}
                  >
                    {totals[s.value]}
                  </div>
                )
              })}
              <div
                style={{
                  backgroundColor: 'white',
                  minWidth: '10rem',
                  width: '100%',
                  borderTop: '2px solid orange',
                  borderBottom: '2px solid orange',
                }}
              ></div>
            </div>
          )}
        </div>
        <div
          style={{
            bottom: 0,
            left: 0,
            paddingLeft: 50,
            width: '100%',
            position: 'absolute',
            backgroundColor: 'white',
          }}
        >
          <Table striped bordered className="w-100 mb-0">
            <thead style={{ borderTop: '2px solid var(--bs-primary)' }}>
              <tr>
                <th width="10%" className="align-middle">
                  {current_state && !isStateBefore(current_state, 'confirmation') && (
                    <div className="text-warning text-center align-self-center">IN ATTESA</div>
                  )}
                  {current_state && !isStateBefore(current_state, 'ready') && (
                    <div className="text-success text-center align-self-center">APPROVATO</div>
                  )}
                </th>
                <th width="60%" className="align-middle">
                  {disabled_confirm === true && (
                    <div className="text-muted text-center align-self-center">
                      <span className="text-danger">Attenzione</span>: tutti i condomini devono avere almeno un servizio
                      associato per continuare!
                    </div>
                  )}
                </th>
                <th width="10%" className="align-middle">
                  <Link to="/assignments" className="d-grid">
                    <Button variant="secondary" style={{ height: '3.5rem' }}>
                      <i className="fas fa-arrow-left" /> Indietro
                    </Button>
                  </Link>
                </th>
                <th width="10%" className="align-middle">
                  <div className="d-grid">
                    {current_state && (isStateBefore(current_state, 'confirmation') || current_state === 'to_fix') && (
                      <Button
                        variant="success"
                        onClick={this.onConfirmCondominia}
                        style={{ height: '3.5rem' }}
                        disabled={condominia.length === 0 || disabled_confirm === true}
                      >
                        Conferma
                      </Button>
                    )}
                    {current_state && current_state === 'confirmation' && (
                      <div className="d-flex justify-content-between">
                        <Button
                          variant="warning"
                          value="tofix"
                          onClick={this.onConfirmCondominia}
                          style={{ height: '3.5rem', minWidth: '7rem' }}
                          disabled={condominia.length === 0}
                        >
                          To Fix
                        </Button>
                        <Button
                          variant="success"
                          value="confirm"
                          onClick={this.onConfirmCondominia}
                          style={{ height: '3.5rem', minWidth: '7rem' }}
                          disabled={condominia.length === 0 || disabled_confirm === true}
                        >
                          Conferma
                        </Button>
                      </div>
                    )}
                  </div>
                </th>
              </tr>
            </thead>
          </Table>
        </div>
        {this.state.show_delete_modal === true && (
          <DeleteCondominiumModal
            delete_id={this.state.condominium_id}
            delete_name={this.state.condominium_name}
            onCloseModal={this.onCloseModal}
            onConfirmDelete={this.onConfirmDelete}
          />
        )}
      </>
    )
  }
}

export default connect(mstp)(AssignmentCondominia)
