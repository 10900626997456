import React from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'
import { Trans } from 'react-i18next'
import { Button, Modal, Form } from 'react-bootstrap'
import axios from 'lib/axios'
import config from 'config'
import StyledSelect from 'components/system_wide/styled_select'

const { t } = i18next

const mstp = state => {
  let { current_assignment, loading } = state.assignments.data
  let { services } = state.assignments.services
  return { current_assignment, loading, services }
}

class ModalDossier extends React.Component {
  static defaultProps = {
    targets: [{ id: 'test', value: 'test', label: 'test' }],
  }
  state = {
    selectedTarget: null,
    selectedFile: '',
    selectedFileLabel: '',
    targets: [],
  }

  handleChangeSelect = value => this.setState({ selectedTarget: value })

  componentDidMount = async () => {
    let { assignment_id, dossier_id, requirement_id } = this.props
    let response = await axios({
      url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}/dossiers/${dossier_id}/requirements/${requirement_id}`,
      method: 'GET',
    })
    let resp_data = response.data.data ?? []
    let temp_targets = Array.isArray(resp_data) ? resp_data : [resp_data]
    let targets = temp_targets.map(t => ({
      id: t.id,
      value: t.id,
      label: `${t.attributes.name} ${t.attributes.surname ?? ''}`,
    }))
    this.setState({ targets })
  }
  onConfirm = () => {
    console.log(this.state.selectedFile)
  }

  FileUploadHandler = e => {
    if (e.target.files) {
      let file = e.target.files[0]
      if (file) {
        let selectedFileLabel = file.name
        let selectedFile = file
        this.setState({ selectedFile, selectedFileLabel })
      }
    } else {
      this.setState({ selectedFile: '', selectedFileLabel: '' })
    }
  }

  render() {
    let { show_modal } = this.props
    let { selectedTarget, selectedFileLabel, targets } = this.state

    return (
      <Modal backdrop="static" centered show={show_modal} onHide={this.props.onCloseModal} size="lg">
        <Modal.Header className="pb-0">
          <Modal.Title>
            <h4 className="text-primary p-1">
              <Trans i18nKey="dossiers.requirements.add_modal.title">Carica documentazione</Trans>
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column" style={{ maxWidth: '70rem' }}>
          <div className="col">
            <Form.Label>
              <Trans i18nKey="dossiers.requirements.select_title">Seleziona elemento</Trans>
            </Form.Label>
            <StyledSelect
              isClearable
              placeholder={t('dossiers.requirements.select_placeholder', 'Nessuna selezione')}
              value={selectedTarget}
              onChange={this.handleChangeSelect}
              options={targets}
            />
          </div>
          <div className="col pt-4">
            <Form.Label>
              <Trans i18nKey="dossiers.requirements.select_file_title">Scegli il file da associare</Trans>
            </Form.Label>
            <Form.Control
              type="file"
              id="upload"
              key="upload_form"
              label={selectedFileLabel}
              custom
              onChange={this.FileUploadHandler}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.onCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={this.onConfirm}
            disabled={selectedFileLabel === '' || selectedTarget === null}
          >
            Associa file
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default connect(mstp)(ModalDossier)
