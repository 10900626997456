import React from 'react'
import { Table, Button } from 'react-bootstrap'
import { renderExpireAt } from 'components/system_wide/utils'

const nameMapping = {
  mapping: 'Da mappare',
  confirmed: 'Confermato',
  converted: 'File convertito',
  imported: 'Importazione completata',
}
class ImportList extends React.Component {
  downloadErrors = id => {
    this.props.downloadErrors(id)
  }

  render() {
    let { list = [], isAdmin } = this.props

    return (
      <>
        <Table responsive hover size="lg" style={{ zIndex: '1' }}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Stato</th>
              {isAdmin && <th>Amministratore</th>}
              <th>Ultima modifica</th>
              <th>Errori</th>
            </tr>
          </thead>
          <tbody>
            {list.map(c => {
              return (
                <tr key={c.id} style={{ height: '3rem', verticalAlign: 'middle' }}>
                  <td>{c.id}</td>
                  <td style={{ color: 'var(--bs-primary)', fontWeight: 'bold' }}>{nameMapping[c.current_state]} </td>
                  {isAdmin && (
                    <td>
                      {c.target.name ?? ''} {c.target.surname ?? ''}
                    </td>
                  )}
                  <td>{renderExpireAt(c.updated_at)}</td>
                  <td>
                    {(c.leftovers.length > 0 && (
                      <Button onClick={() => this.downloadErrors(c.id)}>Dati non caricati</Button>
                    )) ||
                      'Nessun errore'}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        {list.length === 0 && (
          <div className="justify-content-center align-items-center d-flex" style={{ height: 150, width: '100%' }}>
            Nessuna importazione in corso!
          </div>
        )}
      </>
    )
  }
}

export default ImportList
