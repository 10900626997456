const reduceRels = (included, rels) => {
  let result = {}
  Object.keys(rels).forEach(key => {
    let info = rels[key].data ?? null
    if (info) {
      if (Array.isArray(info)) {
        info.forEach(el => {
          let found = included.find(sel => parseInt(sel.id) === parseInt(el.id) && sel.type === el.type)
          if (!result[key]) result[key] = []
          if (found) {
            let includes = reduceRels(included, found?.relationships ?? {})
            result[key].push({ id: found.id, ...found.attributes, ...includes })
          }
        })
      } else {
        let found = included.find(sel => parseInt(sel.id) === parseInt(info.id) && sel.type === info.type)
        if (found) {
          let includes = reduceRels(included, found?.relationships ?? {})
          result[key] = { id: found.id, ...found.attributes, ...includes }
        }
      }
    }
  })
  return result
}

const transformJsonAPI = jsonapi => {
  let { data, included, links } = jsonapi
  let result = { data: [] }

  if (data) {
    if (Array.isArray(data)) {
      data.forEach(el => {
        let { id, attributes, type, relationships } = el
        let includes = reduceRels(included, relationships ?? {})
        let element = { id, type, ...attributes, ...includes }
        result.data.push(element)
      })
    } else {
      let { id, attributes, type, relationships } = data
      let includes = reduceRels(included, relationships ?? {})
      let element = { id, type, ...attributes, ...includes }
      result.data.push(element)
    }
  }
  // Return total number on api rest index
  if (links?.count) {
    result.total = links.count
  }
  // Return filters to use into checkbox_filter
  if (links?.filters) {
    result.filters = links.filters
  }
  return result
}

export default transformJsonAPI
