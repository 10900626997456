import config from 'config'

export const isStateBefore = (current_state, limit_state) => {
  let statuses = config.ASSIGNMENTS_STATUSES
  let state1 = statuses.find(s => s.id === current_state)?.order || 99
  let state2 = statuses.find(s => s.id === limit_state)?.order || 99
  return state1 < state2
}

export const isDev = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return false
  } else return false
}

// check se sei admin o tecnico
export const isAdmin = (role_id, roles) => {
  let admin_id = Object.keys(roles).find(s => roles[s] === 'admin')
  let technician_id = Object.keys(roles).find(s => roles[s] === 'technician')
  return role_id === admin_id || role_id === technician_id
}
export const isNoTaxDatum = (role_id, roles) => {
  let recapitista_id = Object.keys(roles).find(s => roles[s] === 'delivery_person')
  let subaccount_id = Object.keys(roles).find(s => roles[s] === 'subaccount')
  return role_id === recapitista_id || role_id === subaccount_id
}

// check se sei amministratore di condominio
export const isCondominiaAdmin = (role_id, roles) => {
  let building_administrator_id = Object.keys(roles).find(s => roles[s] === 'building_administrator')
  return role_id === building_administrator_id
}

export const getRoleIdByName = (role_name, roles) => {
  let role_id = Object.keys(roles).find(s => roles[s] === role_name)
  return role_id
}
