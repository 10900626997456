import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'lib/axios'

import config from 'config'

const initialState = {
  id: null,
  email: '',
  name: '',
  surname: '',
  role_id: null,
  info: {},
}

export const setCurrentUser = createAsyncThunk('userInfo/set_user', async (id, thunkApi) => {
  try {
    let user_resp = await axios({
      url: `${config.SERVER_API_URL}/v1/users/${id}?include=affiliate_settings,tax_datum`,
      method: 'GET',
    })
    let { data: user_data } = user_resp.data
    let role_id = user_data.relationships.role?.data?.id || null
    let affiliate_roles = (user_resp.data.included ?? [])
      .filter(af => af.type === 'affiliate_settings')
      .map(r => {
        return r.attributes
      })
    let tax_datum_id = user_data.relationships.tax_datum.data?.id || null
    let tax_datum_data = {}
    if (tax_datum_id) {
      let tax_resp = await axios({
        url: `${config.SERVER_API_URL}/v1/users/${id}/tax_data/${tax_datum_id}`,
        method: 'GET',
      })
      tax_datum_data = tax_resp.data.data
    }
    let city_id = tax_datum_data.relationships?.city?.data?.id || null
    let attributes = Object.entries(tax_datum_data?.attributes ?? {}).reduce((acc, [key, value]) => {
      return { ...acc, [key]: value ? value : '' }
    }, {})
    let region_id, province_id
    if (city_id) {
      let city_resp = await axios({
        url: `${config.SERVER_API_URL}/v1/cities/${city_id}`,
        method: 'GET',
      })
      region_id = city_resp.data.data.attributes.region_id
      province_id = city_resp.data.data.attributes.province_id
    }
    return {
      id: user_data.id,
      role_id,
      ...user_data.attributes,
      tax_datum_attributes: { ...attributes, city_id, region: region_id, province: province_id, id: tax_datum_id },
      affiliate_roles,
    }
  } catch (err) {
    console.log(err)
    return thunkApi.rejectWithValue()
  }
})

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,

  reducers: {
    updateUserinfo: (state, action) => action.payload,
  },
  extraReducers: {
    [setCurrentUser.fulfilled]: (state, action) => {
      state.info = action.payload
    },
    [setCurrentUser.rejected]: (state, action) => {
      state.info = {}
    },
  },
})

export const { updateUserinfo } = userInfoSlice.actions
export default userInfoSlice.reducer
