import React from 'react'
import { Button, Form, Alert } from 'react-bootstrap'
import i18next from 'i18next'
import hash from 'object-hash'
import { Trans } from 'react-i18next'
import { HeaderModal } from 'components/system_wide/header_modal'
import validateFields from 'lib/validators/user'
import { updateErrors } from 'store/users'
import SelectMunicipality from 'components/system_wide/select_municipality'
import { isAdmin, isNoTaxDatum } from 'lib/utils'

const { t } = i18next

const initialState = {
  id: null,
  name: '',
  surname: '',
  email: '',
  role_id: '',
  tax_datum_attributes: {
    email: '',
    business_name: '',
    vat_number: '',
    street_address: '',
    cap: '',
    city_id: '',
    region: '',
    province: '',
    phone: '',
    mobile_phone: '',
    sdi: '',
    country_id: 1,
  },
  affiliate_roles: [],
  selected_affiliate_roles: [],
}

class UserForm extends React.Component {
  static defaultProps = {
    onCloseForm: () => {},
    onSave: () => {},
    errors: {},
    user: initialState,
  }

  state = {
    user: initialState,
    user_hash: '',
  }

  componentDidMount() {
    if (this.props.isCondominiaAdmin === true) {
      let { roles } = this.props
      let role_id = Object.keys(roles).find(s => roles[s] === 'subaccount')
      this.setState({ user: { ...this.state.user, role_id } })
    }
  }

  componentDidUpdate = prevProps => {
    if (prevProps.user.id !== this.props.user.id) {
      let user = {
        ...this.state.user,
        ...this.props.user,
        selected_affiliate_roles: this.props.user?.affiliate_roles?.map(r => r.affiliate_role_id?.toString()) || [],
      }
      let user_hash = hash(user)
      this.setState({ user, user_hash })
      this.initialHash = hash(user)
    }
  }

  updateHash = () => {
    let { user } = this.state
    this.setState({ user_hash: hash(user) })
  }
  handleEmailChange = ({ target: { name, value } }) => {
    let user = { ...this.state.user }
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    user[name] = value
    let tax_datum_attributes = { ...user.tax_datum_attributes }
    tax_datum_attributes[name] = value
    this.setState({ user: { ...user, tax_datum_attributes } }, () => this.updateHash())
  }

  handleChange = ({ target: { name, value } }) => {
    let user = { ...this.state.user }
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    user[name] = value
    this.setState({ user }, () => this.updateHash())
  }
  handleTaxAttributesChange = ({ target: { name, value } }) => {
    let user = { ...this.state.user }
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    let tax_datum_attributes = { ...user.tax_datum_attributes }
    tax_datum_attributes[name] = value
    this.setState({ user: { ...user, tax_datum_attributes } }, () => this.updateHash())
  }
  handleSelectChange = e => {
    let errors = { ...this.props.errors }
    delete errors['role_id']
    this.props.dispatch(updateErrors({ errors }))
    this.setState({ user: { ...this.state.user, role_id: e.target.value } })
  }
  handleMunicipalityChange = values => {
    let user = { ...this.state.user }
    let errors = { ...this.props.errors }
    let tax_datum_attributes = { ...user.tax_datum_attributes, ...values }
    Object.keys(values).forEach(k => delete errors[k])
    this.props.dispatch(updateErrors({ errors }))
    this.setState({ user: { ...user, tax_datum_attributes } }, () => this.updateHash())
  }
  handleChangeAffiliateRoles = e => {
    let id = e.target.id
    let selected_affiliate_roles = new Set(this.state.user.selected_affiliate_roles)
    selected_affiliate_roles.has(id) ? selected_affiliate_roles.delete(id) : selected_affiliate_roles.add(id)
    this.setState(
      {
        user: {
          ...this.state.user,
          selected_affiliate_roles: [...selected_affiliate_roles],
        },
      },
      () => this.updateHash()
    )
  }

  isFormValid = () => {
    let { name, surname, email, role_id } = this.state.user
    let { roles } = this.props
    let isUserAdmin = isAdmin(role_id, roles)
    let { vat_number, business_name } = this.state.user.tax_datum_attributes
    return (
      name !== '' &&
      surname !== '' &&
      email !== '' &&
      role_id !== '' &&
      (isUserAdmin || this.props.isCondominiaAdmin || (vat_number !== '' && business_name !== ''))
    )
  }

  handleConfirm = () => {
    let { user } = this.state
    let { roles } = this.props
    let isUserAdmin = isAdmin(user.role_id, roles)
    let errors = validateFields(
      {
        ...user,
        vat_number: user.tax_datum_attributes?.vat_number,
        business_name: user.tax_datum_attributes?.business_name,
      },
      isUserAdmin || this.props.isCondominiaAdmin
    )
    if (Object.keys(errors).length) {
      this.props.dispatch(updateErrors({ errors }))
    } else {
      let { deactivated, tax_datum_attributes, ...rest } = this.state.user
      let { region, province, ...tax_datum } = tax_datum_attributes
      this.props.onSave({ ...rest, tax_datum_attributes: { ...tax_datum } })
    }
    this.setState({ validated: true })
  }

  render() {
    let { validated, user } = this.state
    let { id, name, surname, email, role_id, tax_datum_attributes, selected_affiliate_roles } = user
    let {
      street_address = '',
      phone = '',
      cap,
      city_id,
      vat_number = '',
      business_name = '',
      mobile_phone = '',
      sdi = '',
      province,
      region,
    } = tax_datum_attributes
    let { roles, errors, affiliate_roles: affilateRolesOpts, tRoles, isCondominiaAdmin } = this.props
    let title = id
      ? t('users.user_form.edit_title', 'Modifica utente')
      : t('users.user_form.create_title', 'Crea utente')

    let no_datum_accounts = Object.keys(roles).filter(s => ['subaccount'].includes(roles[s]))
    let isUserAdmin = isAdmin(role_id, roles) || isNoTaxDatum(role_id, roles)

    return (
      <>
        <HeaderModal title={title} onClose={this.props.closeForm} />
        <div className="flex-fill position-relative overflow-auto container">
          {errors?.from_server?.length > 0 && (
            <Alert variant="danger">
              <Alert.Heading>Error:</Alert.Heading>
              <ul>
                {errors.from_server.map((err, i) => (
                  <li key={`err-${i}`}>{err.detail}</li>
                ))}
              </ul>
            </Alert>
          )}
          <div className="row">
            <div className="col-3 pt-4">
              <Form.Label>
                <Trans i18nKey="users.user_form.role">Seleziona ruolo dell'utente: </Trans>
                <span> *</span>
              </Form.Label>
            </div>
            <div className="col-5">
              <Form.Group className="mt-3 mb-1">
                <Form.Select
                  value={role_id}
                  onChange={this.handleSelectChange}
                  disabled={isCondominiaAdmin === true}
                  isInvalid={validated && 'role_id' in errors}>
                  <option value="">{t('user.user_form.not_role', 'Nessun ruolo')}</option>
                  {roles &&
                    Object.entries(roles).map(role => (
                      <option key={`rol-opt-${role[0]}`} value={role[0]}>
                        {tRoles[role[1]] ?? role[1]}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.role_id}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className={isCondominiaAdmin ? 'col-6' : 'col'}>
              <Form.Group className="mt-3 mb-3">
                <Form.Label>
                  <Trans i18nKey="users.user_form.name">Nome</Trans>
                </Form.Label>
                <span> *</span>
                <Form.Control
                  name="name"
                  placeholder={t('users.user_form.name', 'Nome')}
                  value={name}
                  onChange={this.handleChange}
                  isInvalid={validated && 'name' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className={isCondominiaAdmin ? 'col-6' : 'col'}>
              <Form.Group className="mt-3 mb-3">
                <Form.Label>
                  <Trans i18nKey="users.user_form.surname">Cognome</Trans>
                </Form.Label>
                <span> *</span>
                <Form.Control
                  name="surname"
                  placeholder={t('users.user_form.surname', 'Cognome')}
                  value={surname}
                  onChange={this.handleChange}
                  isInvalid={validated && 'surname' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className={isCondominiaAdmin ? 'col-6' : 'col'}>
              <Form.Group className="mt-3 mb-3">
                <Form.Label>
                  <Trans i18nKey="users.user_form.email">Email</Trans>
                </Form.Label>
                <span> *</span>
                <Form.Control
                  name="email"
                  placeholder={t('users.user_form.email', 'Email')}
                  value={email}
                  onChange={this.handleEmailChange}
                  isInvalid={validated && 'email' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          {!no_datum_accounts.includes(role_id) && ( // tax_datum solo negli utenti diversi da sub-account
            <>
              <div className="mt-3 mb-2 pb-0 border-bottom text-primary">
                <b>Dati fiscali</b>
              </div>
              <div style={{ opacity: isUserAdmin ? 0.3 : 1 }}>
                <div className="row">
                  <div className="col">
                    <Form.Group className="mt-2 mb-3">
                      <Form.Label>
                        <Trans i18nKey="users.user_form.business_name">Ragione sociale</Trans>
                      </Form.Label>
                      {!isUserAdmin && <span> *</span>}
                      <Form.Control
                        name="business_name"
                        placeholder={t('users.user_form.business_name', 'Ragione sociale')}
                        value={business_name}
                        onChange={this.handleTaxAttributesChange}
                        disabled={isUserAdmin}
                      />
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group className="mt-2 mb-3">
                      <Form.Label>
                        <Trans i18nKey="users.user_form.vat_number">P.I.</Trans>
                      </Form.Label>
                      {!isUserAdmin && <span> *</span>}
                      <Form.Control
                        name="vat_number"
                        placeholder={t('users.user_form.vat_number', 'P.I.')}
                        value={vat_number}
                        onChange={this.handleTaxAttributesChange}
                        isInvalid={validated && 'vat_number' in errors}
                        disabled={isUserAdmin}
                      />
                      <Form.Control.Feedback type="invalid">{errors.vat_number}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group className="mt-2 mb-3">
                      <Form.Label>
                        <Trans i18nKey="users.user_form.sdi">SDI</Trans>
                      </Form.Label>
                      <Form.Control
                        name="sdi"
                        placeholder={t('users.user_form.sdi', 'SDI')}
                        value={sdi}
                        onChange={this.handleTaxAttributesChange}
                        disabled={isUserAdmin}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Form.Group className="mt-2 mb-3">
                      <Form.Label>
                        <Trans i18nKey="users.user_form.street_address">Indirizzo</Trans>
                      </Form.Label>
                      <Form.Control
                        name="street_address"
                        placeholder={t('users.user_form.street_address', 'Indirizzo')}
                        value={street_address}
                        onChange={this.handleTaxAttributesChange}
                        isInvalid={validated && 'street_address' in errors}
                        disabled={isUserAdmin}
                      />
                      <Form.Control.Feedback type="invalid">{errors.street_address}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <SelectMunicipality
                    region={region}
                    province={province}
                    city={city_id}
                    cap={cap}
                    errors={errors}
                    onChange={this.handleMunicipalityChange}
                    isReadOnly={isUserAdmin}
                  />
                </div>
                <div className="row">
                  <div className="col">
                    <Form.Group className="mt-2 mb-3">
                      <Form.Label>
                        <Trans i18nKey="users.user_form.tel">Telefono</Trans>
                      </Form.Label>
                      <Form.Control
                        name="phone"
                        placeholder={t('users.user_form.tel', 'Telefono')}
                        value={phone}
                        onChange={this.handleTaxAttributesChange}
                        disabled={isUserAdmin}
                      />
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group className="mt-2 mb-3">
                      <Form.Label>
                        <Trans i18nKey="users.user_form.mobile_phone">Cellulare</Trans>
                      </Form.Label>
                      <Form.Control
                        name="mobile_phone"
                        placeholder={t('users.user_form.mobile_phone', 'Cellulare')}
                        value={mobile_phone}
                        onChange={this.handleTaxAttributesChange}
                        disabled={isUserAdmin}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-12 d-flex flex-column">
                    <div className="mb-2">
                      <Trans i18nKey="user.user_form.affiliate_roles">Ruoli affiliato</Trans>
                    </div>
                    <div className="d-flex flex-row flex-wrap gap-2">
                      {affilateRolesOpts?.map(r => (
                        <Form.Check
                          key={r.id}
                          inline
                          label={r.name}
                          name="selected_affiliate_roles"
                          type="checkbox"
                          id={r.id}
                          checked={selected_affiliate_roles.includes(r.id)}
                          onChange={this.handleChangeAffiliateRoles}
                          disabled={isUserAdmin}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="border-top pt-2">
          <Button
            className="float-end"
            variant="primary"
            disabled={!this.isFormValid() || this.initialHash === this.state.user_hash}
            onClick={this.handleConfirm}>
            <Trans i18nKey="common.button_confirm">Salva</Trans>
          </Button>
          <Button className="ms-auto me-2" variant="secondary" onClick={this.props.closeForm}>
            <Trans i18nKey="common.button_cancel">Annulla</Trans>
          </Button>
        </div>
      </>
    )
  }
}

export default UserForm
