import React from 'react'
import { connect } from 'react-redux'
import { getRoles } from 'store/users'
import { isAdmin, isCondominiaAdmin } from 'lib/utils'

const mstp = state => {
  let { role_id } = state.userInfo
  let { roles } = state.usersData
  return { role_id, roles }
}

class WithCapabilities extends React.Component {
  componentDidMount = async () => {
    if (Object.keys(this.props.roles).length === 0) {
      await this.props.dispatch(getRoles())
    }
  }

  isAdmin = () => {
    let { role_id, roles } = this.props
    return isAdmin(role_id, roles)
  }

  isCondominiaAdmin = () => {
    let { role_id, roles } = this.props
    return isCondominiaAdmin(role_id, roles)
  }

  render() {
    let { isAdmin, isNotAdmin, isCondominiaAdmin } = this.props

    if (isAdmin && this.isAdmin()) {
      return <>{this.props.children}</>
    } else if (isNotAdmin && !this.isAdmin()) {
      return <>{this.props.children}</>
    } else if (isCondominiaAdmin && this.isCondominiaAdmin()) {
      return <>{this.props.children}</>
    } else {
      return null
    }
  }
}

export default connect(mstp)(WithCapabilities)
