import React from 'react'
import { CSSTransition } from 'react-transition-group'

const Dropdown = ({ in: inProp, duration = 300 }) => {
  const nodeRef = React.useRef(null)

  return (
    <>
      <style jsx>
        {`
          .content {
            position: absolute;
            background: #000000;
            z-index: 900;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0.5;
          }
          .dropdown-enter {
            opacity: 0;
          }
          .dropdown-enter-active {
            opacity: 0.5;
            transition: opacity ${duration}ms ease-in-out;
          }
          .dropdown-exit-active {
            opacity: 0;
            transition: opacity ${duration}ms ease-in-out;
          }
        `}
      </style>
      <CSSTransition nodeRef={nodeRef} in={inProp} timeout={duration} classNames="dropdown" unmountOnExit>
        <div ref={nodeRef} className="content"></div>
      </CSSTransition>
    </>
  )
}

export default Dropdown
