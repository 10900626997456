import React from 'react'
import i18next from 'i18next'

import withJWTSession from 'enhancers/jwt_session'
import Topbar from 'components/system_wide/topbar'
import Fadebar from 'components/system_wide/fadebar'
import Condominia from 'components/condominia'

const { t } = i18next

class CondominiaPage extends React.Component {
  render() {
    let { navigate } = this.props.router
    return (
      <div>
        <Topbar title={t('pages.condominia.title', 'Lista condomini')} />
        <div className="d-flex vh-94 overflow-auto">
          <div className="d-flex" style={{ backgroundColor: 'white' }}>
            <Fadebar />
          </div>
          <Condominia assignment_id={{}} navigate={navigate} />
        </div>
      </div>
    )
  }
}

export default withJWTSession(CondominiaPage)
