import React from 'react'
import i18next from 'i18next'
import config from 'config'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import ResponsiveTable from 'components/system_wide/responsive_table'

const { t } = i18next

class ReadyAssignmentsList extends React.Component {
  statuses = config.ASSIGNMENTS_STATUSES.reduce((acc, v) => ({ ...acc, [v.id]: v.color }), {})

  roleRenderer = ({ rowData: { role_id } }) => {
    let role = this.props.roles[role_id] ?? null
    if (!role) return null
    return role.replace('_', ' ')
  }
  stateRenderer = ({ rowData: { current_state } }) => {
    return (
      <div className="p-1" style={{ backgroundColor: this.statuses[current_state] }}>
        {current_state}
      </div>
    )
  }
  orderRenderer = ({ rowData: { order } }) => {
    return order ? `N. ${order.id}` : '--'
  }
  orderTotalAmountRenderer = ({ rowData: { order } }) => {
    let total_amount = (order?.final_amount || 0) + (order?.fee_amount || 0)
    return <div className="text-end">{order ? `${total_amount.toFixed(2)} €` : '--'}</div>
  }
  orderDataRenderer = ({ rowData: { order } }) => {
    let data_signature_string = '--'
    if (order) {
      let data_signature = new Date(order.created_at)
      data_signature_string = data_signature.toLocaleDateString()
    }
    return data_signature_string
  }
  renderService = ({ rowData: { services } }) => {
    return (
      <div className="d-flex flex-wrap gap-2">
        {services.map(s_id => {
          let service = this.props.services.find(s => s.value === s_id)
          let target = React.createRef()
          return service ? (
            <OverlayTrigger
              key={service.value}
              trigger={['hover', 'click']}
              overlay={<Tooltip>{service.label}</Tooltip>}
            >
              <div style={{ width: '1.3rem' }} className="d-flex align-items-center">
                <i ref={target} className={`${service.icon_classes} m-auto`} />
              </div>
            </OverlayTrigger>
          ) : (
            ''
          )
        })}
      </div>
    )
  }

  goToDashboard = (assignment_id, current_state) => () => {
    this.props.goToState(assignment_id, current_state)()
  }

  actionsRenderer = ({ rowData }) => {
    return (
      <Button onClick={this.goToDashboard(rowData.id, rowData.current_state)}>
        Dettagli
        <i className="fas fa-arrow-right ps-1" />
      </Button>
    )
  }

  columns = [
    {
      title: t('assignments.list.code', 'Code'),
      className: 'd-lg-block flex-fill',
      style: { width: '6%' },
      sortable: true,
      data: 'id',
    },
    {
      title: t('assignments.list.name', 'Nome'),
      className: 'd-lg-block flex-fill',
      style: { width: '12%' },
      sortable: true,
      data: 'name',
    },
    {
      title: t('assignments.list.surname', 'Cognome'),
      className: 'd-lg-block flex-fill',
      style: { width: '12%' },
      sortable: true,
      data: 'surname',
    },
    {
      title: t('assignments.list.role', 'Ruolo'),
      className: 'd-none d-lg-block flex-fill text-capitalize text-truncate',
      style: { width: '10%' },
      sortable: true,
      data: this.roleRenderer,
    },
    {
      title: t('assignments.list.state', 'Stato'),
      className: 'd-lg-block flex-fill text-capitalize text-truncate',
      style: { width: '10%' },
      sortable: true,
      data: this.stateRenderer,
    },
    {
      title: t('assignments.list.services', 'Servizi'),
      className: 'd-lg-block flex-fill text-truncate',
      style: { width: '14%' },
      data: this.renderService,
    },
    {
      title: t('assignments.list.order', 'Ordine'),
      className: 'd-lg-block flex-fill text-truncate',
      style: { width: '8%' },
      data: this.orderRenderer,
    },
    {
      title: t('assignments.list.date_signature', 'Data'),
      className: 'd-lg-block flex-fill text-truncate',
      style: { width: '10%' },
      sortable: true,
      sortKey: 'created_at',
      data: this.orderDataRenderer,
    },
    {
      title: t('assignments.list.total_cost', 'Costo totale'),
      className: 'd-lg-block flex-fill text-truncate',
      style: { width: '8%' },
      data: this.orderTotalAmountRenderer,
    },
    {
      title: '',
      className: 'd-lg-block text-center',
      style: { width: '10%', minWidth: '3rem' },
      data: this.actionsRenderer,
    },
  ]

  render() {
    return (
      <ResponsiveTable
        ref={this.tableRef}
        className={`flex-fill border ${this.props.className}`}
        rowClasses={this.rowClasses}
        menuWidth="70rem"
        data={this.props.assignments}
        getData={this.getData}
        totalRecords={this.props.assignments.length}
        columns={this.columns}
      />
    )
  }
}

export default ReadyAssignmentsList
