import React from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'
import { Trans } from 'react-i18next'
import { Button, Modal, Form, Table, Dropdown, ButtonGroup } from 'react-bootstrap'

import axios from 'lib/axios'
import config from 'config'
import { error, success } from 'components/system_wide/notification'
import { getDocuments, deleteDocument } from 'store/documents'
import { isAdmin as _isAdmin } from 'lib/utils'
import WithCapabilities from 'enhancers/with_capabilities'

const { t } = i18next

const mstp = state => {
  let { files } = state.documents
  let { role_id } = state.userInfo
  let { roles } = state.usersData
  let isAdmin = _isAdmin(role_id, roles)
  return { files, isAdmin }
}

class ModalDocuments extends React.Component {
  state = {
    selectedFile: '',
    selectedFileLabel: '',
  }

  uploadForm = null

  componentDidMount = () => this.props.dispatch(getDocuments())

  onConfirm = async () => {
    try {
      let formData = new FormData()
      formData.append('_jsonapi[document]', this.state.selectedFile)
      formData.append('accepted', true)
      await axios({
        url: `${config.SERVER_API_URL}/v1/attachments`,
        method: 'POST',
        data: formData,
      })
      this.props.dispatch(getDocuments())
      success(t('store.contracts.attachments.success_upload', 'File caricato con successo'))
      this.setState({ selectedFile: '', selectedFileLabel: '' })
      this.uploadForm.value = ''
    } catch (err) {
      error(t('store.contracts.attachments.error_upload', 'Impossibile caricare il file'))
    }
  }

  deleteFile = id => async () => {
    await this.props.dispatch(deleteDocument(id))
    this.props.dispatch(getDocuments())
  }

  FileUploadHandler = e => {
    if (e.target.files) {
      let file = e.target.files[0]
      if (file) {
        let selectedFileLabel = file.name
        let selectedFile = file
        this.setState({ selectedFile, selectedFileLabel })
      }
    } else {
      this.setState({ selectedFile: '', selectedFileLabel: '' })
    }
  }

  downloadFile = (dw_id, dw_name) => () => {
    axios({
      url: `${config.SERVER_API_URL}/v1/attachments/${dw_id}`,
      method: 'GET',
      responseType: 'blob',
    }).then(response => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', dw_name) //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
  }

  render() {
    let { files } = this.props
    let { selectedFileLabel } = this.state

    return (
      <Modal backdrop="static" centered show={true} onHide={this.props.onCloseModal} size="lg">
        <Modal.Header className="pb-0" closeButton>
          <Modal.Title>
            <h4 className="text-primary p-1">
              <Trans i18nKey="dossiers.requirements.add_modal.title">Carica documentazione</Trans>
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxWidth: '70rem' }}>
          <WithCapabilities isAdmin={true}>
            <div className="pt-2 mb-3 pb-4 border-bottom">
              <Form.Label>
                <Trans i18nKey="dossiers.requirements.select_file_title">Scegli il file da associare</Trans>
              </Form.Label>
              <div className="row">
                <div className="col-10">
                  <Form.Control
                    type="file"
                    id="upload"
                    key="upload_form"
                    label={selectedFileLabel}
                    custom
                    ref={form => (this.uploadForm = form)}
                    onChange={this.FileUploadHandler}
                  />
                </div>
                <div className="col-2">
                  <Button variant="primary" onClick={this.onConfirm} disabled={selectedFileLabel === ''}>
                    Carica file
                  </Button>
                </div>
              </div>
            </div>
          </WithCapabilities>
          <div className="row">
            <h5>Documenti</h5>
          </div>
          <div className="overflow-auto" style={{ height: '15rem' }}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>File</th>
                  <th className="text-center" style={{ width: '2rem' }}>
                    <Trans i18nKey="table_column.actions">Azioni</Trans>
                  </th>
                </tr>
              </thead>
              <tbody>
                {files.map(dw => (
                  <tr style={{ height: '1rem' }} key={`dw-${dw.id}`}>
                    <td>{dw.name}</td>
                    <td>
                      <div className="d-flex">
                        <Dropdown as={ButtonGroup} size="sm">
                          <Button onClick={this.downloadFile(dw.id, dw.name)}>
                            <Trans i18nKey="actions_dropdown.title.download">Scarica</Trans>
                          </Button>

                          <Dropdown.Toggle split variant="secondary" id="dropdown-split-basic" />
                          <Dropdown.Menu style={{ left: '-120px' }}>
                            <Dropdown.Item onClick={this.downloadFile(dw.id, dw.name)}>
                              <Trans i18nKey="document.download">Scarica</Trans>
                            </Dropdown.Item>
                            <WithCapabilities isAdmin={true}>
                              <Dropdown.Divider />
                              <Dropdown.Item onClick={this.deleteFile(dw.id)}>
                                <Trans i18nKey="document.delete">Elimina</Trans>
                              </Dropdown.Item>
                            </WithCapabilities>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.onCloseModal}>
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default connect(mstp)(ModalDocuments)
