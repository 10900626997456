import React from 'react'
import { connect } from 'react-redux'
import { Button, Modal, Form } from 'react-bootstrap'
import i18next from 'i18next'
import hash from 'object-hash'
import { Trans } from 'react-i18next'

import {
  toggleShowAddCondominia,
  createCondominium,
  updateErrors,
  resetCurrentCondominium,
  editCondominium,
} from 'store/assignments/condominia'
import { getServices } from 'store/assignments/services'
import validateFields from 'lib/validators/assignments'
import StyledSelect from 'components/system_wide/styled_select'
import SelectMunicipality from 'components/system_wide/select_municipality'
import ServicesTable from 'components/assignments/condominia/services_table'

const { t } = i18next

const initialState = {
  name: '',
  fiscal_code: '',
  iban: '',
  cap: '',
  region: '',
  province: '',
  city_id: '',
  country_id: 1,
  street: '',
  real_estate_units: false,
  services: [],
  edit_mode: false,
}

const mstp = state => {
  let { current_assignment } = state.assignments.data
  let { services } = state.assignments.services
  let { errors, show_add_condominia, edit_condominia } = state.assignments.condominia
  services = services?.filter(s => current_assignment?.services?.map(s => s.toString()).includes(s.value)) ?? []
  return { servicesOpts: services, show_add_condominia, current_assignment, errors, edit_condominia }
}

class AddAssignmentCondominia extends React.Component {
  state = { condominium: { ...initialState }, condominium_hash: '', selected_condominium: '', validated: false }

  componentDidMount() {
    this.props.dispatch(getServices())
  }
  componentDidUpdate = prevProps => {
    if (prevProps.show_add_condominia !== this.props.show_add_condominia) {
      let edit_mode = Object.keys(this.props.edit_condominia).length !== 0
      this.setState(
        {
          condominium: { ...initialState, ...(edit_mode === true ? this.props.edit_condominia : {}) },
          selected_condominium: '',
          edit_mode,
        },
        () => this.updateHash()
      )
    }
  }

  onOpenModal = () => {
    this.props.dispatch(toggleShowAddCondominia())
  }
  onCloseModal = () => {
    this.props.dispatch(toggleShowAddCondominia())
    this.props.dispatch(resetCurrentCondominium())
  }
  updateHash = () => {
    let { condominium } = this.state
    this.setState({ condominium_hash: hash(condominium) })
  }
  isFormValid = () => {
    let { name, fiscal_code, iban, cap, region, province, city_id, street, services } = this.state.condominium
    return (
      name !== '' &&
      fiscal_code !== '' &&
      iban !== '' &&
      cap !== '' &&
      region !== '' &&
      province !== '' &&
      city_id !== '' &&
      street !== '' &&
      services.length > 0
    )
  }

  handleMunicipalityChange = values => {
    let condominium = { ...this.state.condominium, ...values }
    let errors = { ...this.props.errors }
    Object.keys(values).forEach(k => delete errors[k])
    if (this.state.validated) this.props.dispatch(updateErrors({ errors }))
    this.setState({ condominium }, () => this.updateHash())
  }

  handleChange = ({ target: { name, value } }) => {
    let condominium = { ...this.state.condominium }
    let errors = { ...this.props.errors }
    delete errors[name]
    if (this.state.validated) this.props.dispatch(updateErrors({ errors }))
    condominium[name] = value
    this.setState({ condominium }, () => this.updateHash())
  }
  handleIsItChecked = e => {
    this.setState({ condominium: { ...this.state.condominium, [e.target.name]: e.target.checked } })
  }

  handleChangeServices = values => {
    let condominium = { ...this.state.condominium, services: values }
    this.setState({ condominium })
  }

  handleConfirm = async () => {
    let condominium = { ...this.state.condominium }
    let errors = validateFields({ ...condominium })
    if (Object.keys(errors).length) {
      this.props.dispatch(updateErrors({ errors }))
    } else {
      // let value = this.state.selected_condominium?.value
      // if (value) {
      //   await this.props.dispatch(createAssignment({ user_id: value }))
      // } else {
      condominium.services = '{ ' + condominium.services.join(',') + ' }'
      if (this.state.edit_mode === true) {
        await this.props.dispatch(editCondominium({ assignment_id: this.props.current_assignment.id, condominium }))
      } else {
        await this.props.dispatch(createCondominium({ assignment_id: this.props.current_assignment.id, condominium }))
      }
      // }
    }
    this.setState({ validated: true })
  }

  render() {
    let { condominium, selected_condominium, validated, edit_mode } = this.state
    let { name, fiscal_code, iban, cap, region, province, city_id, street, real_estate_units, services } = condominium
    let { show_add_condominia, errors, servicesOpts } = this.props

    return (
      <>
        <div
          className="d-flex pt-1 pb-1 align-items-center"
          onClick={!this.props.disabled && this.onOpenModal}
          role="button"
        >
          <Trans i18nKey="assignments.add_condominia_modal.topbar_button">Condominio</Trans>
          {!this.props.disabled && <i className="fas fa-plus-circle ps-2 pb-1" />}
        </div>
        <Modal
          size="lg"
          backdrop="static"
          centered
          show={show_add_condominia}
          onHide={this.onCloseModal}
          className="menu-dialog"
        >
          <Modal.Header className="pb-0">
            <Modal.Title>
              <h4 className="text-primary p-1">
                {edit_mode === true ? (
                  <Trans i18nKey="assignments.edit_condominia.title">Modifica condominio</Trans>
                ) : (
                  <Trans i18nKey="assignments.add_condominia.title">Aggiungi condominio</Trans>
                )}
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-row">
            <div className="col-8">
              <div className="flex-fill position-relative container">
                <div className="row mb-3">
                  <div className="col">
                    <Form.Label className="text-muted">
                      <Trans i18nKey="assignments.condominia.search_condominium">Seleziona condominio esistente:</Trans>
                    </Form.Label>
                    <StyledSelect
                      isClearable
                      placeholder={t('assignments.condominia.select_condominium', 'Seleziona condominio..')}
                      value={selected_condominium}
                      options={[]}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="col">
                    <Form.Group className="mt-3">
                      <Form.Label>
                        <Trans i18nKey="assignments.condominia.form.name">Nome</Trans>
                      </Form.Label>
                      <span> *</span>
                      <Form.Control
                        name="name"
                        placeholder={t('assignments.condominia.form.name', 'Nome')}
                        value={name}
                        onChange={this.handleChange}
                        isInvalid={validated && 'name' in errors}
                      />
                      <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col">
                    <Form.Group className="mt-2">
                      <Form.Label>
                        <Trans i18nKey="assignments.condominia.form.fiscal_code">Codice fiscale</Trans>
                      </Form.Label>
                      <span> *</span>
                      <Form.Control
                        name="fiscal_code"
                        placeholder={t('assignments.condominia.form.fiscal_code', 'Codice fiscale')}
                        value={fiscal_code}
                        onChange={this.handleChange}
                        isInvalid={validated && 'fiscal_code' in errors}
                      />
                      <Form.Control.Feedback type="invalid">{errors.fiscal_code}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group className="mt-2">
                      <Form.Label>
                        <Trans i18nKey="assignments.condominia.form.iban">IBAN</Trans>
                      </Form.Label>
                      <span> *</span>
                      <Form.Control
                        name="iban"
                        placeholder={t('assignments.condominia.form.iban', 'IBAN')}
                        value={iban}
                        onChange={this.handleChange}
                        isInvalid={validated && 'iban' in errors}
                      />
                      <Form.Control.Feedback type="invalid">{errors.iban}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>

                <div className="row mt-4">
                  <SelectMunicipality
                    region={region}
                    province={province}
                    city={city_id}
                    cap={cap}
                    errors={errors}
                    onChange={this.handleMunicipalityChange}
                    showRequiredMarks={true}
                  />
                </div>
                <div className="row">
                  <div className="col">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <Trans i18nKey="assignments.condominia.form.street_address">Indirizzo</Trans>
                        <span> *</span>
                      </Form.Label>
                      <Form.Control
                        name="street"
                        placeholder={t('assignments.condominia.form.street_address', 'Indirizzo')}
                        value={street}
                        onChange={this.handleChange}
                        isInvalid={validated && 'street' in errors}
                      />
                      <Form.Control.Feedback type="invalid">{errors.street}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Form.Group className="mb-3">
                      <Form.Check
                        type="switch"
                        id="real_estate_units-switch"
                        label="Suddivisione unità immobiliari"
                        name="real_estate_units"
                        checked={real_estate_units}
                        onChange={this.handleIsItChecked}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 ms-3">
              <ServicesTable
                services={servicesOpts}
                selected_services={services}
                onChangeService={this.handleChangeServices}
              />
              <div>
                <b>NB:</b> Il condominio deve avere almeno un servizio associato
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCloseModal} variant="secondary">
              <Trans i18nKey="common.cancel_button">Annulla</Trans>
            </Button>
            {edit_mode === true ? (
              <Button onClick={this.handleConfirm} variant="primary" disabled={!this.isFormValid()}>
                <Trans i18nKey="assignments.add_modal.edit_button">Modifica</Trans>
              </Button>
            ) : (
              <Button onClick={this.handleConfirm} variant="primary" disabled={!this.isFormValid()}>
                <Trans i18nKey="assignments.add_modal.confirm_button">Aggiungi</Trans>
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default connect(mstp)(AddAssignmentCondominia)
