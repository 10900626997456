import React from 'react'
import { Button, Modal, Row, Card, Col, Container, Table } from 'react-bootstrap'
import { Trans } from 'react-i18next'
import CondominiaMap from './condominia_map'
import DownloadContractButton from 'components/system_wide/download_contract_button'

class ShowCondominium extends React.Component {
  zoom = 8
  position = [41.87194, 12.56738]

  render() {
    let { condominium, tServices } = this.props
    if (condominium.id && condominium.lat && condominium.lon) {
      this.position = [Number(condominium.lat), Number(condominium.lon)]
    }
    return (
      <Modal backdrop="static" centered show={true} onHide={this.props.onCloseModal} size="xl">
        <Modal.Header className="pb-0" closeButton>
          <Modal.Title>
            <h4 className="text-primary p-1">
              <Trans i18nKey="condominia.show_condominium.title">Dettagli condominio</Trans>
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col sm={8} className="d-flex">
                <Card className="flex-fill">
                  <Card.Header>Info condominio</Card.Header>
                  <Card.Body>
                    <Card.Title>
                      <span className="text-muted">Nome:</span> {condominium?.name}
                    </Card.Title>
                    <Card.Text>
                      <Table className="table-padded mt-4">
                        <tbody>
                          <tr>
                            <td className="text-muted">Indirizzo:</td>
                            <td>{condominium?.street}</td>
                          </tr>
                          <tr>
                            <td className="text-muted">Città:</td>
                            <td>
                              {condominium?.city?.name} ({condominium?.city?.province?.name})
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted">CAP</td>
                            <td>{condominium?.cap}</td>
                          </tr>
                          <tr>
                            <td className="text-muted">Regione:</td>
                            <td>{condominium?.city?.province?.region?.name}</td>
                          </tr>
                          <tr>
                            <td className="text-muted">Codice fiscale:</td>
                            <td>{condominium?.fiscal_code}</td>
                          </tr>
                          <tr>
                            <td className="text-muted">IBAN:</td>
                            <td>{condominium?.iban ?? '...'}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4} className="d-flex">
                <Card className="flex-fill">
                  <Card.Header>Mappa</Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <CondominiaMap
                        condominia={[condominium]}
                        zoom={this.zoom}
                        position={this.position}
                        handleSelectedCondominia={() => {}}
                        style={{ height: '19rem' }}
                      />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col sm={8}>
                <Card>
                  <Card.Header>Servizi attivi</Card.Header>
                  <Card.Body>
                    <Card.Text className="d-flex flex-row">
                      {condominium?.services?.map((s, i) => (
                        <div key={i}>
                          <i className={`${s.icon_classes} p-3`} />
                          {tServices?.[s.codename] ?? s.description}
                        </div>
                      ))}
                      {(condominium?.contracts?.length ?? 0) === 0 && (
                        <div className="text-muted">Nessun servizio attivo</div>
                      )}
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card className="mt-2">
                  <Card.Header>Documentazione</Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <Table responsive bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>Servizio</th>
                            <th>File</th>
                            <th className="text-center" style={{ width: '2rem' }}>
                              Scarica
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {condominium?.contracts?.map(ctr => {
                            let service = condominium?.services?.find(
                              s =>
                                s.id.toString() === ctr?.service_id?.toString() ||
                                s.id.toString() === ctr?.service?.id?.toString()
                            )
                            return ctr?.attachments?.map(at => {
                              return (
                                <tr key={ctr.id} style={{ height: '3rem', verticalAlign: 'middle' }}>
                                  <td>{tServices?.[service?.codename] ?? service?.description}</td>
                                  <td>{at.name}</td>
                                  <td>
                                    <DownloadContractButton
                                      contractId={ctr.id}
                                      attachmentsId={at.id}
                                      fileName={at.name}
                                    />
                                  </td>
                                </tr>
                              )
                            })
                          })}
                        </tbody>
                      </Table>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4} className="d-flex">
                <Card className="flex-fill">
                  <Card.Header>Amministratore</Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <div style={{ fontSize: '0.8rem' }} className="admin-table">
                        <div>
                          <div className="d-flex border-bottom p-2">
                            <div className="text-muted pe-1">Ragione sociale:</div>
                            <div>{condominium?.user?.tax_datum?.business_name}</div>
                          </div>
                          <div className="d-flex border-bottom p-2">
                            <div className="text-muted pe-1">Nome:</div>
                            <div>{condominium?.user?.name}</div>
                          </div>
                          <div className="d-flex border-bottom p-2">
                            <div className="text-muted pe-1">Cognome:</div>
                            <div>{condominium?.user?.surname}</div>
                          </div>
                          <div className="d-flex border-bottom p-2">
                            <div className="text-muted pe-1">Email:</div>
                            <div className="text-wrap-ow" style={{ maxWidth: '16rem' }}>
                              {condominium?.user?.email}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onCloseModal} variant="secondary">
            <Trans i18nKey="common.close_button">Chiudi</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ShowCondominium
