import { Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

export const success = message => {
  Store.addNotification({
    title: 'Success',
    message,
    type: 'success',
    container: 'top-right',
    dismiss: {
      duration: 2500,
      onScreen: true,
      pauseOnHover: true,
    },
  })
}
export const error = (message, duration = 2500, skipNotify = false) => {
  if (!skipNotify) {
    Store.addNotification({
      title: 'Error',
      message,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration,
        onScreen: true,
        pauseOnHover: true,
      },
    })
  }
}

const Notification = { success, error }
export default Notification
