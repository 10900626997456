import i18next from 'i18next'
import isIBAN from 'validator/lib/isIBAN'

const { t } = i18next

const validateFields = data => {
  let requiredFields = ['name', 'fiscal_code', 'region', 'province', 'city_id', 'cap', 'street']

  let errors = {}

  // check required fields
  for (const field of requiredFields) {
    if (!data[field]) errors[field] = t('assignments.condominia.form.errors.required_field', 'Campo richiesto')
  }

  if (data.iban && !isIBAN(data.iban)) {
    errors.iban = errors.iban
      ? errors.iban
      : t('assignments.condominia.form.errors.invalid_email', 'Formato IBAN non valido')
  }
  return errors
}

export default validateFields
