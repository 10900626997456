import React from 'react'

import ResetPassword from 'components/login/reset_password'

class ResetPasswordPage extends React.Component {
  render() {
    return <ResetPassword />
  }
}

export default ResetPasswordPage
