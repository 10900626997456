import React from 'react'
import { Trans } from 'react-i18next'
import { Dropdown, ButtonGroup, Button } from 'react-bootstrap'

class ActionsDropdownSplitted extends React.PureComponent {
  render() {
    let { rowData, actions, className } = this.props
    return (
      <Dropdown as={ButtonGroup} style={{ maxHeight: 38 }} className={className}>
        <Button onClick={this.props.onClickMaster}>
          <Trans i18nKey="actions_dropdown.title.open">Apri</Trans>
        </Button>

        <Dropdown.Toggle split variant="secondary" id="dropdown-split-basic" />
        <Dropdown.Menu>
          {actions.map((action, i) => {
            if (action.text === '---') {
              return <Dropdown.Divider />
            }
            let { disabled = false } = action
            if (typeof disabled === 'function') {
              disabled = disabled(rowData)
            }
            let { visible = true } = action
            if (typeof visible === 'function') {
              visible = visible(rowData)
            }

            return visible === true ? (
              <ActionItem key={i} disabled={disabled} action={action} rowData={rowData} caps={action.caps} />
            ) : null
          })}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

// const ActionItem = withCapabilities({})(props => {
const ActionItem = props => {
  let { disabled, action, rowData } = props
  return (
    <Dropdown.Item disabled={disabled} onClick={() => action.onClick(rowData)}>
      <i className={`fas ${action.icon} pe-1`} />
      {action.text}
    </Dropdown.Item>
  )
}
export default ActionsDropdownSplitted
