import i18next from 'i18next'
import isEmail from 'validator/lib/isEmail'

const { t } = i18next

const validateFields = (data, isAdmin = false) => {
  let requiredFields = ['name', 'surname', 'email', 'vat_number', 'business_name']

  if (isAdmin) {
    requiredFields = requiredFields.filter(r => r !== 'vat_number' && r !== 'business_name')
  }
  let errors = {}

  // check required fields
  for (const field of requiredFields) {
    if (!data[field]) errors[field] = t('user.user_form.errors.required_field', 'Campo richiesto')
  }

  if (!isEmail(data.email)) {
    errors.email = errors.email ? errors.email : t('user.user_form.errors.invalid_email', 'Formato email non valido')
  }
  if (data.role_id === '') {
    errors.role_id = errors.role_id ? errors.role_id : t('user.user_form.errors.invalid_role_id', 'Ruolo richiesto')
  }
  if (!isAdmin) {
    if (!/^[0-9]{11}$/.test(data.vat_number)) {
      errors.vat_number = errors.vat_number
        ? errors.vat_number
        : t('user.user_form.errors.invalid_vat_number', 'P.I. non valida')
    }
  }
  return errors
}

export default validateFields
