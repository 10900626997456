import * as React from 'react'
import { Button, Card, Form, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import isEmail from 'validator/lib/isEmail'
import i18next from 'i18next'
import axios from 'axios'

import './index.css'

import config from 'config'
import Footer from './footer'
import logo from 'assets/images/logo.svg'

const { t } = i18next

class Login extends React.Component {
  state = { email: '', message: '', message_type: 'success' }

  handleKeyDown = event => {
    if (event.key === 'Enter') {
      this.handleSubmitEmail()
      event.preventDefault()
    }
  }

  handleChange = ({ target }) => {
    let { name, value } = target
    this.setState({ [name]: value, message_type: 'success', message: '' })
  }

  handleSubmitEmail = async () => {
    try {
      let { email } = this.state
      if (isEmail(email)) {
        await axios({
          url: `${config.SERVER_API_URL}/users/password`,
          method: 'POST',
          data: {
            user: { email: this.state.email },
          },
        })
        this.setState({ message: t('common.email_send_success', 'Email inviata!'), message_type: 'success' })
      } else {
        this.setState({ message: t('common.email_format_error', 'Formato email non valido'), message_type: 'danger' })
      }
    } catch (err) {
      console.log(err)
      this.setState({ message: t('common.email_send_success', 'Email inviata!'), message_type: 'success' })
    }
  }

  render() {
    let { email, message, message_type } = this.state

    return (
      <div className="d-flex justify-content-center align-items-center vh-100 flex-column">
        <Card style={{ width: '28rem', backgroundColor: '#f2f2f2' }} className="p-5 pb-1 mt-auto">
          <Card.Img variant="top" src={logo} />
          <Card.Body className="mt-5">
            <Alert variant={message_type} show={message !== ''} transition={false}>
              {message}
            </Alert>
            <Form onKeyDown={this.handleKeyDown}>
              <Form.Label className="pb-3 text-muted">Inserisci la tua email</Form.Label>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  className="mb-2"
                  name="email"
                  type="text"
                  placeholder="Email"
                  onChange={this.handleChange}
                  value={email}
                />
                <Form.Text className="pb-3 fs-7">
                  Inserisci la tua email e ti invieremo le istruzioni per creare la nuova password
                </Form.Text>
              </Form.Group>

              <Button
                variant="primary"
                className="w-100 rounded-3 mt-2 mb-2"
                disabled={email === ''}
                onClick={this.handleSubmitEmail}
              >
                INVIA EMAIL
              </Button>
            </Form>
            <div className="fs-7 text-primary mt-2">
              <Link to="/login" className="text-decoration-none">
                <i className="fas fa-arrow-left" /> Torna al login
              </Link>
            </div>
          </Card.Body>
        </Card>
        <Footer />
      </div>
    )
  }
}

export default Login
