import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { Table, Dropdown, Button, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import 'scss/assignments.css'

import { setCurrentAssignment, changeState } from 'store/assignments'
import {
  deleteService,
  toggleShowSignature,
  signAssignment,
  confirmOTP,
  renewOTP,
  getDossier,
  toggleShowAddServices,
} from 'store/assignments/services'
import AddAssignmentServices from './add_service_modal'
import DeleteServiceModal from './delete_service_modal'
import SignatureModal from './signature_modal'
import { isStateBefore } from 'lib/utils'

const mstp = state => {
  let { current_assignment, loading } = state.assignments.data
  let { show_signature } = state.assignments.services
  return { current_assignment, loading, show_signature }
}
class AssignmentServices extends React.Component {
  static defaultProps = {
    current_assignment: { dossiers: [] },
  }

  state = {
    service_id: '',
    service_name: '',
    show_delete_modal: false,
    price: '',
  }

  componentDidMount = async () => {
    await this.props.dispatch(setCurrentAssignment(this.props.assignment_id))
  }

  onHandleDelete = (id, name) => () => this.setState({ show_delete_modal: true, service_id: id, service_name: name })
  onHandleEdit = id => async () => {
    await this.props.dispatch(getDossier(id))
    this.props.dispatch(toggleShowAddServices())
  }
  onCloseModal = () => this.setState({ show_delete_modal: false })

  onConfirmDelete = () => {
    this.props.dispatch(
      deleteService({ assignment_id: this.props.current_assignment.id, service_id: this.state.service_id })
    )
    this.setState({ show_delete_modal: false })
  }
  onOpenSignatureModal = () => this.props.dispatch(toggleShowSignature())
  onCloseSignature = () => this.props.dispatch(toggleShowSignature())
  onConfirmSign = async (id, signature) => {
    await this.props.dispatch(signAssignment({ assignment_id: id, signature }))
  }
  onRequestOTP = async id => {
    await this.props.dispatch(changeState({ assignment_id: id, send_otp: true }))
  }
  onConfirmOTP = async (id, otp) => {
    let { verificable_id, otp_verification_id } = this.props.current_assignment
    await this.props.dispatch(confirmOTP({ assignment_id: id, otp_verification_id, verificable_id, otp }))
  }
  onRenewOTP = async id => {
    let { verificable_id, otp_verification_id } = this.props.current_assignment
    await this.props.dispatch(renewOTP({ assignment_id: id, otp_verification_id, verificable_id }))
  }

  render() {
    let { dossiers, current_state } = this.props.current_assignment

    if (this.props.loading)
      return (
        <div className="d-flex w-100 flex-column zi-1">
          <div className="w-100">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      )

    if (!current_state) return null

    let { total_cost, total_fee } = dossiers.reduce(
      (acc, d) => {
        acc.total_cost += d.quantity * (isNaN(parseFloat(d.price)) ? 0 : d.price)
        acc.total_fee += d.quantity * d.fee
        return acc
      },
      { total_cost: 0, total_fee: 0 }
    )

    return (
      <>
        <div className="d-flex w-100 flex-column zi-1">
          <div className="w-100">
            <Table striped bordered hover className="w-100">
              <thead style={{ borderBottom: '2px solid orange' }}>
                <tr>
                  <th width="20%" className="text-left align-middle text-primary main">
                    <AddAssignmentServices disabled={current_state !== 'pending'} />
                  </th>
                  <th width="11%" className="text-center align-middle text-muted">
                    <Trans i18nKey="assignments.services.number">Numero</Trans>
                  </th>
                  <th width="11%" className="text-center align-middle text-muted">
                    <Trans i18nKey="assignments.services.cost">Costo</Trans>
                  </th>
                  <th width="11%" className="text-center align-middle text-muted">
                    <Trans i18nKey="assignments.services.commission">Provvigione</Trans>
                  </th>
                  <th width="11%" className="text-center align-middle text-muted">
                    <Trans i18nKey="assignments.services.suddivision">Suddivisione unità immobiliari</Trans>
                  </th>
                  <th width="11%" className="text-center align-middle text-muted">
                    <Trans i18nKey="assignments.services.total_cost">Costo totale</Trans>
                  </th>
                  <th width="11%" className="text-center align-middle text-muted">
                    <Trans i18nKey="assignments.services.total_commission">Provvigione totale</Trans>
                  </th>
                  <th width="14%" className="text-center align-middle text-muted">
                    <Link to={'/assignments'} className="d-grid">
                      <Button variant="secondary" style={{ height: '3.5rem' }}>
                        <i className="fas fa-arrow-left" /> Indietro
                      </Button>
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {dossiers.map(d => {
                  return (
                    <tr key={d.id} className="text-center">
                      <td>{d?.service?.description || ''}</td>
                      <td>{d.quantity}</td>
                      <td>{d.price}</td>
                      <td>{d.fee}</td>
                      <td>{d.real_estate_units ? <i className="fas fa-check text-success" /> : ''}</td>
                      <td>{typeof d.price === 'number' ? d.price * d.quantity : d.price}</td>
                      <td>{d.fee * d.quantity}</td>
                      <td>
                        {current_state === 'pending' && (
                          <Dropdown>
                            <Dropdown.Toggle variant="warning" id="dropdown-basic">
                              <Trans i18nKey="common.actions_button">Actions</Trans>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item onClick={this.onHandleEdit(d.id)}>Modifica</Dropdown.Item>
                              <Dropdown.Item onClick={this.onHandleDelete(d.id, d.service.description)}>
                                Elimina
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
          <div className="align-self-baseline mt-auto flex-row d-flex w-100">
            <Table striped bordered className="w-100 mb-0">
              <thead style={{ borderTop: '2px solid var(--bs-primary)' }}>
                <tr>
                  <th width="64%" className="align-middle">
                    <h4 className="text-primary fw-bold">Totale</h4>
                  </th>
                  <th width="11%" className="align-middle text-center">
                    {total_cost} €
                  </th>
                  <th width="11%" className="align-middle text-center">
                    {total_fee} €
                  </th>
                  <th width="14%" className="align-middle">
                    {isStateBefore(current_state, 'affiliate_definition') && (
                      <div className="d-grid">
                        <Button
                          variant="success"
                          onClick={this.onOpenSignatureModal}
                          style={{ height: '3.5rem' }}
                          disabled={!dossiers || dossiers.length === 0}
                        >
                          Firma
                        </Button>
                      </div>
                    )}
                    {!isStateBefore(current_state, 'affiliate_definition') && (
                      <div className="text-success text-center">APPROVATO</div>
                    )}
                  </th>
                </tr>
              </thead>
            </Table>
          </div>
        </div>
        {this.state.show_delete_modal === true && (
          <DeleteServiceModal
            delete_id={this.state.service_id}
            delete_name={this.state.service_name}
            onCloseModal={this.onCloseModal}
            onConfirmDelete={this.onConfirmDelete}
          />
        )}
        {this.props.show_signature === true && (
          <SignatureModal
            onCloseModal={this.onCloseSignature}
            assignment={this.props.current_assignment}
            onConfirmSign={this.onConfirmSign}
            onRequestOTP={this.onRequestOTP}
            onConfirmOTP={this.onConfirmOTP}
            onRenewOTP={this.onRenewOTP}
          />
        )}
      </>
    )
  }
}

export default connect(mstp)(AssignmentServices)
