import React from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'

const mstp = state => {
  let { current_version, server_version } = state.common
  return { current_version, server_version }
}

class UpdatesNotificator extends React.Component {
  state = {
    show_modal: false,
  }

  refreshPage = () => {
    window.location.reload(false)
  }

  render() {
    let { current_version, server_version } = this.props
    if (current_version === server_version) return null
    return (
      <>
        <div className="d-flex">
          <Button
            variant="outline-danger"
            onClick={() => this.setState({ show_modal: true })}
            style={{ width: '22rem' }}
          >
            Nuova versione software disponibile!
          </Button>
        </div>
        <Modal show={this.state.show_modal} size="lg" centered>
          <Modal.Header>
            <Modal.Title style={{ margin: 'auto' }}>Nuova versione software!</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            Per installare la nuova versione è sufficiente fare un refresh della pagina o cliccare sotto.
            <br />
            Prima di procedere, salvare il lavoro ancora non concluso!
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.setState({ show_modal: false })} style={{ width: '8rem' }}>
              Annulla{' '}
            </Button>
            <Button variant="success" onClick={this.refreshPage} style={{ width: '8rem' }}>
              Aggiorna{' '}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default connect(mstp)(UpdatesNotificator)
