import React from 'react'
import { Spinner } from 'react-bootstrap'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png'
import 'leaflet/dist/leaflet.css'
import './condominia_map.css'

const DefaultIcon = L.icon({
  iconRetinaUrl: iconRetinaUrl,
  iconUrl: icon,
  shadowUrl: iconShadow,
})

class CondominiaMap extends React.PureComponent {
  static defaultProps = {
    zoom: 6,
    position: [41.87194, 12.56738],
    condominia: {},
    resetSelection: false,
  }

  state = {
    legendLayer: null,
    loading: false,
    progress: 0,
    zoom: 6,
    position: [41.87194, 12.56738],
  }

  map = React.createRef()

  constructor(props) {
    super(props)
    if (props.position) {
      this.state.position = props.position
      this.state.zoom = props.zoom
    }
  }

  componentDidMount() {
    L.Marker.prototype.options.icon = DefaultIcon
    let no_viewed_condominia = this.props.condominia.filter(c => !c.lat && !c.lon)
    if (this.state.legendLayer) this.state.legendLayer.remove()
    if (this.map.current && no_viewed_condominia.length > 0)
      this.setState({ legendLayer: this.legend(this.map.current, no_viewed_condominia) })
  }

  legend = (map, no_viewed_condominia) => {
    const legend = L.control({ position: 'bottomleft' })

    legend.onAdd = () => {
      const div = L.DomUtil.create('div', 'warning osmlayer')
      div.innerHTML =
        '<span id="label_red">Attenzione</span>: La mappa non può caricare il seguente numero di condomini: <b>' +
        no_viewed_condominia.length +
        '</b>'
      return div
    }
    legend.addTo(map)
    return legend
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.resetSelection === true && prevProps.resetSelection === false) {
  //     this.map.current.setView(this.props.position, this.props.zoom)
  //   }
  // }

  handleMarker = (id, position) => _ => {
    this.map?.current?.flyTo(position) //, 16, { animate: true, duration: 0.5 })
    this.props.handleSelectedCondominia(id)
  }

  handleProgress = (processed, total) => {
    this.setState({ loading: true })
    if (processed === total) {
      this.setState({ loading: false })
    }
  }

  render() {
    let { condominia, style } = this.props
    let { position, zoom } = this.state

    return (
      <>
        <MapContainer
          ref={this.map}
          center={position}
          zoom={zoom}
          scrollWheelZoom={true}
          style={{ height: 'calc(100vh - 130px)', ...style }}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup chunkedLoading chunkProgress={this.handleProgress}>
            {Object.keys(condominia)
              .filter(c => condominia[c].lat && condominia[c].lon)
              .map((c, i) => {
                return (
                  <Marker
                    key={i}
                    position={[condominia[c].lat, condominia[c].lon]}
                    title={condominia[c].name}
                    eventHandlers={{
                      click: this.handleMarker(condominia[c].id, [condominia[c].lat, condominia[c].lon]),
                    }}>
                    <Popup>
                      <b>{condominia[c].name}</b>
                      <br />
                      {condominia[c].street}
                    </Popup>
                  </Marker>
                )
              })}
          </MarkerClusterGroup>
        </MapContainer>
        {this.state.loading && (
          <div
            className="d-flex w-100 vh-94 justify-content-center"
            style={{ position: 'absolute', backgroundColor: '#cfcfcf90', zIndex: '99999' }}>
            <div className="w-100 m-auto text-center">
              <div className="loader-box">
                <Spinner animation="border" role="status"></Spinner>
                <div>Loading...</div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default CondominiaMap
