import React from 'react'
import { connect } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import DossierBox from './dossier-box'
import ModalDossier from './modal-dossier'
import { getRequirements } from 'store/assignments'

const mstp = state => {
  let { current_assignment, loading } = state.assignments.data
  let { services } = state.assignments.services
  return { current_assignment, loading, services }
}

class DossiersGroup extends React.Component {
  state = {
    no_reqs: false,
    dossiers: [],
    loading: false,
    requirement: {},
    show_modal: false,
  }

  async componentDidMount() {
    let {
      current_assignment: { id: assignment_id, dossiers },
    } = this.props
    this.setState({ loading: true })
    await this.props.dispatch(getRequirements({ assignment_id, dossiers }))
    this.setState({ loading: false })
  }

  componentDidUpdate(prevProps) {
    let { requirements } = this.props.current_assignment
    if (prevProps.current_assignment.requirements !== requirements) {
      let dossiers = []
      for (let d of this.props.current_assignment.dossiers) {
        let meet = requirements[d.id].reduce((acc, el) => (acc = acc && el.meet), true)
        dossiers.push({ ...d, requirements: requirements[d.id], meet })
      }

      dossiers = dossiers.sort((a, b) => Number(a.meet) - Number(b.meet))
      this.setState({ dossiers })
    }
  }

  openModal = (requirement, dossier) => {
    this.setState({ requirement, dossier, show_modal: true })
  }
  closeModal = () => {
    this.setState({ requirement: {}, dossier: {}, show_modal: false })
  }

  render() {
    let {
      current_assignment: { id: assignment_id },
    } = this.props
    let { dossiers, loading, show_modal, requirement, dossier } = this.state

    if (loading)
      return (
        <div className="d-flex w-100 flex-column zi-1">
          <div className="w-100">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      )

    return (
      <>
        {dossiers.map(d => (
          <div className="col-12 col-lg-6 mt-3" key={`dossier-${d.id}`}>
            <DossierBox dossier={d} assignment_id={assignment_id} openModal={this.openModal} />
          </div>
        ))}
        {show_modal && (
          <ModalDossier
            show_modal={show_modal}
            dossier_id={dossier.id}
            assignment_id={assignment_id}
            requirement_id={requirement.id}
            onCloseModal={this.closeModal}
          />
        )}
      </>
    )
  }
}

export default connect(mstp)(DossiersGroup)
