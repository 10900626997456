import React from 'react'
import { connect } from 'react-redux'
import { Image, Navbar, Container } from 'react-bootstrap'

import { isDev } from 'lib/utils'
import { toggleFadebar } from 'store/common'
import AddNewAssignmentModal from 'components/assignments/add_modal'
import UserMenuDropdown from './user_menu_dropdown'
import SettingsMenuDropdown from './settings_menu_dropdown'
import UpdatesNotificator from './updates_notificator'

import logo from 'assets/images/logo.svg'
import 'scss/navbar.css'

class Topbar extends React.Component {
  toggleFadebar = () => this.props.dispatch(toggleFadebar())

  render() {
    return (
      <Navbar className="p-0" bg="light" style={{ height: '6vh' }}>
        <Container fluid className="d-flex justify-content-between ps-0">
          <div className="d-flex gap-3 ps-1">
            <div
              className="d-flex justify-content-center align-items-center fs-5 text-white p-3 btn-hamburger"
              onClick={this.toggleFadebar}>
              <i className="fas fa-bars" />
            </div>
            <div className="fluid flex-fill">
              <Navbar.Brand href="/">
                <Image src={logo} className="img-fluid p-1" style={{ height: '3rem' }} />
              </Navbar.Brand>
            </div>
            {isDev() === true && <AddNewAssignmentModal />}
          </div>
          <div className="text-primary fw-bold text-uppercase">{this.props.title}</div>
          <div className="d-flex">
            <div className="d-flex p-2 pe-2 ps-3 gap-3 icon-menu">
              {isDev() === true && <SettingsMenuDropdown />}
              <UpdatesNotificator />
              <UserMenuDropdown />
            </div>
          </div>
        </Container>
      </Navbar>
    )
  }
}

export default connect()(Topbar)
