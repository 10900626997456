import React from 'react'

import Signature from './signature'
import OTP from './otp'

class SignatureModal extends React.Component {
  render() {
    let {
      assignment: { current_state, verified },
    } = this.props

    if (current_state === 'pending' || (current_state === 'otp' && verified === false)) return <OTP {...this.props} />
    if (current_state === 'acceptance') return <Signature {...this.props} />
    return null
  }
}

export default SignatureModal
