import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import i18next from 'i18next'
import { Button, Form, Dropdown, OverlayTrigger, Tooltip, Spinner, ProgressBar } from 'react-bootstrap'

import { getTransfers, deleteTransfer, acceptTransfer, getStates, filterStates, getValidators } from 'store/transfers'
import ResponsiveTable from 'components/system_wide/responsive_table'
import { showConfirmDialog } from 'components/system_wide/confirm_dialog'
import ActionsDropdownSplitted from 'components/system_wide/actions_dropdown_splitted'
import SearchBox from 'components/system_wide/search_box'
import CheckboxFilter from 'components/system_wide/checkbox_filter'
import ModalTransfer from 'components/transfers/modal-show'
import ModalNewTransfer from 'components/transfers/modal-new'
import ShowCondominium from 'components/condominia/show_condominium'
import { renderExpireAt } from 'components/system_wide/utils'
import { getCondominium, getCondominiumContracts } from 'store/condominia'
import { isAdmin as _isAdmin } from 'lib/utils'

const { t } = i18next

const mstp = state => {
  let { transfers, filtered_states, total, states } = state.transfers
  let { strings: trans } = state.translations
  let { id: my_user_id, role_id } = state.userInfo
  let { roles } = state.usersData
  let { edit_condominia } = state.condominia
  let isAdmin = _isAdmin(role_id, roles)
  return {
    total,
    transfers,
    trans,
    filtered_states,
    states,
    isAdmin,
    my_user_id,
    edit_condominia,
  }
}

class TransfersList extends React.Component {
  static defaultProps = {
    transfers: [],
    getData: () => {},
    columns: [],
    filter: null,
  }

  state = {
    show_modal_new: false,
    show_modal_transfer: false,
    show_modal_condominium: false,
    filter: '',
    ready: false,
    selected: {},
    activeTab: 'attivi',

    type_selected: 'single',
    selected_transfer: [],
    loading: false,
    progress: 0,
  }

  tableRef = null
  bulk_actions = {
    to_review: t('actions_dropdown.to_review', 'Da revisionare'),
  }

  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
  }

  componentDidMount = async () => {
    this.props.dispatch(getValidators())
    this.setState({ ready: true }, () => this.tableRef.current.refreshData())
    if (this.props.states.length === 0) {
      this.props.dispatch(getStates())
    }
  }

  userRole = rowData => {
    if (this.props.isAdmin) {
      return 'admin'
    } else {
      if (rowData.user_id.toString() === this.props.my_user_id.toString()) return 'transferer'
      if (rowData.new_user_id.toString() === this.props.my_user_id.toString()) return 'receiver'
    }
  }

  selectCheckboxRender = ({ rowData }) => {
    let { selected_transfer } = this.state

    return (
      <Form.Check
        type="checkbox"
        className="py-1"
        value={rowData.id}
        checked={selected_transfer.includes(rowData.id.toString())}
        onChange={this.toggleContract}
      />
    )
  }

  handleSelection = () => {
    this.setState(
      {
        type_selected: this.state.type_selected === 'single' ? 'multiple' : 'single',
        selected_transfer: this.state.type_selected === 'single' ? [] : this.state.selected_transfer,
      },
      () => {
        this.tableRef?.current.refreshData()
      }
    )
  }

  toggleAllTransfer = () => {
    let { selected_transfer, activeTab } = this.state
    let { transfers } = this.props
    let active_transfers = activeTab === 'attivi' ? transfers : []
    let all_selected = active_transfers.every(u => selected_transfer.includes(u.id.toString()))
    let current_transfers = []

    if (all_selected) {
      current_transfers = selected_transfer.filter(su => !active_transfers.find(u => u.id.toString() === su))
    } else {
      current_transfers = new Set([...selected_transfer, ...active_transfers.map(e => e.id.toString())])
    }
    this.setState({ selected_transfer: [...current_transfers] })
  }

  toggleContract = ({ target: { value } }) => {
    let { selected_transfer } = this.state
    if (selected_transfer.includes(value)) {
      this.setState({ selected_transfer: selected_transfer.filter(e => e !== value) })
    } else {
      this.setState({ selected_transfer: [...selected_transfer, value] })
    }
  }

  renderCheckbox = () => {
    let { selected_transfer, activeTab, type_selected } = this.state
    let { transfers } = this.props
    if (type_selected === 'single') return ''
    let active_transfers = activeTab === 'attivi' ? transfers : ''
    let all_selected = active_transfers.every(u => selected_transfer.includes(u.id.toString()))
    return (
      <Button onClick={this.toggleAllTransfer} variant="secondary" className="no-padding-button">
        <i className={`far ${all_selected ? 'fa-check-square' : 'fa-square'}`} style={{ fontSize: '1.5rem' }}></i>
      </Button>
    )
  }

  actions = [
    {
      icon: 'fa-thumbs-up',
      text: t('actions_dropdown.accept_transfer', 'Accetta'),
      disabled: rowData => rowData.current_state !== 'open' || !['admin', 'receiver'].includes(this.userRole(rowData)),
      onClick: ({ id }) => {
        this.acceptTransfer({ id })
      },
    },
    {
      icon: 'fa-times-circle',
      text: t('actions_dropdown.reject_transfer', 'Cancella'),
      disabled: rowData => rowData.current_state !== 'open',
      onClick: ({ id }) => {
        this.deleteTransfer({ id })
      },
    },
    {
      icon: 'fa-trash',
      text: t('actions_dropdown.delete_transfer', 'Elimina'),
      disabled: rowData =>
        rowData.current_state !== 'cancelled' || !['admin', 'transferer'].includes(this.userRole(rowData)),
      onClick: ({ id }) => {
        this.deleteTransfer({ id })
      },
    },
  ]

  actionsRenderer = ({ rowData }) => {
    return (
      <ActionsDropdownSplitted
        rowData={rowData}
        actions={this.actions}
        onClickMaster={() => this.openTransferModal(rowData)}
      />
    )
  }

  renderCondominia = ({ rowData: { condominia: elements } }) => {
    let condominia = (elements ?? []).map(el => el.name).join(', ')
    return <div>{condominia}</div>
  }

  renderState = ({ rowData: { current_state } }) => {
    let color = 'text-primary'
    if (current_state === 'accepted') color = 'text-success'
    if (current_state === 'rejected' || current_state === 'cancelled') color = 'text-danger'
    let { transfer: trTrans } = this.props.trans?.state_machines ?? []
    let label = trTrans?.[current_state] ?? current_state
    return <div className={`${color}`}>{label.toUpperCase()}</div>
  }

  renderDate = ({ rowData: { created_at } }) => {
    return <div>{renderExpireAt(created_at)}</div>
  }
  getColumn = () => {
    return [
      {
        title: this.renderCheckbox(),
        className: 'd-lg-block text-center',
        style: { width: '9%', minWidth: '8rem' },
        data: rowData => {
          return this.state.type_selected === 'single'
            ? this.actionsRenderer(rowData)
            : this.selectCheckboxRender(rowData)
        },
      },
      {
        title: t('transfers.id', 'ID'),
        className: 'd-lg-block flex-fill',
        style: { width: '3%', minWidth: '3rem' },
        sortable: true,
        data: 'id',
      },
      {
        title: t('transfers.list.current_state', 'Stato'),
        className: 'd-lg-block flex-fill',
        style: { width: '10%' },
        sortable: true,
        data: this.renderState,
      },
      {
        title: t('transfers.list.old_administrator', 'Precedente amm.'),
        className: 'd-lg-block flex-fill',
        style: { width: '10%' },
        sortable: false,
        data: ({ rowData }) => `${rowData?.user?.name ?? 'Ceduto'} ${rowData?.user?.surname ?? ''}`,
      },
      {
        title: t('transfers.list.new_administrator', 'Nuovo amm.'),
        className: 'd-lg-block flex-fill',
        style: { width: '10%' },
        sortable: false,
        data: ({ rowData }) => `${rowData?.new_user?.name ?? 'Ceduto'} ${rowData?.new_user?.surname ?? ''}`,
      },
      {
        title: t('transfers.list.new_administrator', 'Inserita il'),
        className: 'd-lg-block flex-fill',
        style: { width: '10%' },
        sortable: true,
        data: this.renderDate,
      },
      {
        title: t('transfers.list.condominia', 'Condomini'),
        className: 'd-lg-block flex-fill text-truncate',
        style: { width: '39%' },
        data: this.renderCondominia,
      },
    ]
  }

  getData = async (index = 0, chunkSize, sortData) => {
    await this.props.dispatch(getTransfers({ offset: index, limit: chunkSize, sortData, filter: this.state.filter }))
  }

  onFilterChange = filter => {
    this.setState({ filter }, () => {
      if (filter === '') this.tableRef.current.refreshData()
    })
  }
  onFilterState = options => {
    this.props.dispatch(filterStates(options))
  }

  onCloseTransferModal = () => {
    this.setState({ show_modal_transfer: false, show_modal_new: false, selected: '' })
    this.tableRef.current.refreshData()
  }
  openTransferModal = rowData => this.setState({ show_modal_transfer: true, selected: rowData })
  onCloseShowCondominium = () => {
    this.setState({ show_modal_condominium: false, show_modal_transfer: true })
  }
  onOpenShowCondominium = c_id => async () => {
    await this.props.dispatch(getCondominium({ condominium_id: c_id }))
    await this.props.dispatch(getCondominiumContracts(c_id))
    this.setState({ show_modal_condominium: true, show_modal_transfer: false })
  }

  acceptTransfer = async ({ id }) => {
    showConfirmDialog(
      <Trans i18nKey="transfers.accepet_transfer_modal.modal_title">Accetta trasferimento</Trans>,
      <Trans
        i18nKey="transfers.delete_transfer_modal.modal_msg"
        values={{ transfer_id: id }}
        defaults="Per procedere all'accettazione del trasferimento con id <1>{{ transfer_id }}</1> clicca su Accetta"
        components={{ 1: <b /> }}
      />,
      async () => {
        await this.props.dispatch(acceptTransfer({ transfer_id: id }))
        this.tableRef.current.refreshData()
      },
      () => {},
      'success',
      <Trans i18nKey="common.reject_button">Accetta</Trans>
    )
  }

  deleteTransfer = async ({ id }) => {
    showConfirmDialog(
      <Trans i18nKey="transfers.delete_transfer_modal.modal_title">Elimina trasferimento</Trans>,
      <Trans
        i18nKey="transfers.delete_transfer_modal.modal_msg"
        values={{ transfer_id: id }}
        defaults="Per procedere alla cancellazione del trasferimento con id <1>{{ transfer_id }}</1> clicca su Elimina"
        components={{ 1: <b /> }}
      />,
      async () => {
        await this.props.dispatch(deleteTransfer({ transfer_id: id }))
        this.tableRef.current.refreshData()
      },
      () => {},
      'danger',
      <Trans i18nKey="common.reject_button">Elimina</Trans>
    )
  }

  handleNewTransfer = () => this.setState({ show_modal_new: true })
  handleSelectTypeTransfer = value => () => {
    this.setState({ activeTab: value, selected_transfers: [] })
  }
  handleSearch = () => {
    this.tableRef.current.refreshData()
  }

  render() {
    let { transfers, total, states, filtered_states, trans, edit_condominia } = this.props
    let { type_selected, selected_transfer, activeTab, loading, progress } = this.state
    if (!transfers || this.state.ready === false) return null
    let { transfer: trTrans } = trans?.state_machines || []
    let tServices = trans?.tables?.services || []
    let stateOpts = states.map(s => ({ id: s, name: trTrans?.[s] ?? s }))
    let columns = this.getColumn()
    return (
      <>
        <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3 pe-lg-3 pt-2 zi-1">
          <div className="d-flex flex-column flex-fill">
            <div className="container-fluid px-0">
              <div className="d-flex flex-fill">
                <div className="d-flex flex-fill flex-column flex-wrap">
                  <div className="d-flex flex-wrap gap-2 mb-2">
                    <Button variant="primary" className="float-md-end" onClick={this.handleNewTransfer}>
                      <i className={`fa fa-plus`} /> Crea trasferimento
                    </Button>
                    <CheckboxFilter
                      className="ms-xl-auto"
                      label={t('contracts.filtered_state', 'Stato')}
                      items={stateOpts}
                      filteredItems={filtered_states}
                      onChangeFilter={this.onFilterState}
                    />
                    <SearchBox
                      value={this.state.filter}
                      onChange={this.onFilterChange}
                      onEnter={this.handleSearch}
                      disabled={this.props.show_form}
                    />
                    <Button onClick={this.handleSearch} variant="secondary">
                      <i className="fa fa-search me-2" />
                      <Trans i18nKey="common.cancel_button">Cerca</Trans>
                    </Button>
                  </div>
                  <div className="d-flex align-items-stretch">
                    {this.props.isAdmin && (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-multiselect`}>
                            <Trans i18nKey="contracts.enable_multiselect_label">Abilita/Disabilita Selezione</Trans>
                          </Tooltip>
                        }>
                        <Button
                          onClick={this.handleSelection}
                          className="rounded-0 no-shadow-button d-none"
                          variant="secondary">
                          <i className="fa-solid fa-list-check me-1"></i>
                        </Button>
                      </OverlayTrigger>
                    )}
                    {type_selected === 'multiple' && (
                      <Dropdown className="me-2 action-menu-dropdown">
                        <Dropdown.Toggle
                          variant="light"
                          id="multi-selected-menu"
                          className="rounded-0 action-menu-dropdown">
                          <Trans i18nKey="contracts.bulk_action_label">Azioni massive</Trans>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {Object.keys(this.bulk_actions).map(a => {
                            return (
                              <Dropdown.Item key={a} disabled={selected_transfer.length === 0} onClick={() => {}}>
                                {this.bulk_actions[a]}
                              </Dropdown.Item>
                            )
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                    <button
                      style={{ marginLeft: '0.1rem' }}
                      onClick={this.handleSelectTypeTransfer('attivi')}
                      className={`btn btn-outline-primary btn-tab ${activeTab === 'attivi' ? 'btn-tab-active' : ''}`}>
                      Trasferimenti in corso
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex" style={{ minHeight: '85vh' }}>
              <ResponsiveTable
                ref={this.tableRef}
                className="flex-fill border"
                headerClasses="bg-secondary"
                menuWidth="70rem"
                data={transfers}
                getData={this.getData}
                totalRecords={total}
                columns={columns}
                filter={this.state.filter}
              />
            </div>
          </div>
        </div>
        {loading === true && (
          <div
            className="d-flex w-100 vh-94 justify-content-center"
            style={{ position: 'absolute', backgroundColor: '#cfcfcf90', zIndex: '99999' }}>
            <div className="w-100 m-auto text-center">
              <div className="loader-box">
                <Spinner animation="border" role="status"></Spinner>
                <div>Loading...</div>
                <div className="m-auto" style={{ width: '10rem' }}>
                  <ProgressBar now={progress} label={`${progress}%`} />
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.show_modal_transfer && (
          <ModalTransfer
            show_modal={this.state.show_modal_transfer}
            transfer={this.state.selected}
            trans={this.props.trans}
            onCloseModal={this.onCloseTransferModal}
            onOpenShowCondominium={this.onOpenShowCondominium}
          />
        )}
        {this.state.show_modal_new && (
          <ModalNewTransfer show_modal={this.state.show_modal_new} onCloseModal={this.onCloseTransferModal} />
        )}
        {this.state.show_modal_condominium === true && (
          <ShowCondominium
            onCloseModal={this.onCloseShowCondominium}
            condominium={edit_condominia}
            tServices={tServices}
          />
        )}
      </>
    )
  }
}

export default connect(mstp)(TransfersList)
