import React from 'react'
import i18next from 'i18next'

import withJWTSession from 'enhancers/jwt_session'
import Topbar from 'components/system_wide/topbar'
import Fadebar from 'components/system_wide/fadebar'
import AssignmentsList from 'components/assignments/list'

const { t } = i18next

class Assignments extends React.Component {
  render() {
    let { params, navigate } = this.props.router
    return (
      <div>
        <Topbar title={t('pages.assignments.title', 'Lista incarichi')} />
        <div className="d-flex vh-94">
          <div className="d-flex" style={{ backgroundColor: 'white' }}>
            <Fadebar />
          </div>
          <AssignmentsList navigate={navigate} />
        </div>
      </div>
    )
  }
}

export default withJWTSession(Assignments)
