import React from 'react'
import { Trans } from 'react-i18next'
import { Modal, Button } from 'react-bootstrap'
import CanvasDraw from 'react-canvas-draw'

import 'scss/assignments.css'

class Signature extends React.Component {
  state = { image: '', canvas: null }

  onConfirmSign = () => {
    this.props.onConfirmSign(this.props.assignment.id, this.state.image)
  }
  onSign = canvas => {
    this.setState({ canvas, image: canvas.getDataURL() })
  }
  onEraseAll = () => {
    if (this.state.canvas) {
      this.state.canvas.clear()
      this.setState({ canvas: null })
    }
  }

  render() {
    return (
      <Modal backdrop="static" centered show={true} onHide={this.props.onCloseModal} className="menu-dialog">
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans i18nKey="assignments.signature_modal.modal_title">Firma il resoconto</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="align-items-center justify-content-center text-center">
            <div className="d-flex">
              <div className="m-auto mb-4 ps-4">
                <Trans i18nKey="assignments.signature.modal_msg">
                  Inserisci la firma nel box sottostante e click su Conferma
                </Trans>
              </div>
              <div className="align-items-end">
                <Button onClick={this.onEraseAll} variant="secondary">
                  <i className="fas fa-trash" />
                </Button>
              </div>
            </div>
            <CanvasDraw
              brushRadius={4}
              canvasWidth={1000}
              className="border shadow m-auto"
              onChange={this.onSign}
              hideInterface
              hideGrid
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onCloseModal} variant="secondary">
            <Trans i18nKey="common.cancel_button">Annulla</Trans>
          </Button>
          <Button onClick={this.onConfirmSign} variant="success" disabled={!this.state.canvas}>
            <Trans i18nKey="common.confirm_button">Conferma</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default Signature
