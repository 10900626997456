import React from 'react'
import i18next from 'i18next'
import { Trans } from 'react-i18next'
import { Table, Row, Col, Form } from 'react-bootstrap'
import StyledSelect from 'components/system_wide/styled_select'

const { t } = i18next

class MappingForm extends React.Component {
  static defaultProps = {
    imported_id: '',
    available_columns: [],
    columns_map: [],
    onChange: () => {},
  }

  handleColumnChange = column => selected => {
    this.props.onChange({ column, selected })
  }

  render() {
    let { available_columns, columns_map } = this.props

    let used_columns = Object.keys(columns_map)
      .filter(c => columns_map[c] !== null)
      .reduce((acc, el) => [...acc, columns_map[el]], [])
    let availableColumnsOptions = available_columns
      .filter(c => !used_columns.includes(c))
      .map(c => ({ label: c, value: c }))

    return (
      <Table hover size="sm">
        <thead>
          <tr>
            <th className="text-center" style={{ width: '35%' }}>
              <Trans i18nKey="condominia.bulk_import.mapping_form.column_field">Campo</Trans>
            </th>
            <th className="text-center">
              <Trans i18nKey="condominia.bulk_import.mapping_form.column_associetion">Associazione</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(columns_map).map(c => {
            let selected = columns_map[c] ? { label: columns_map[c], value: columns_map[c] } : null
            return (
              <tr key={c} style={{ height: '3rem', verticalAlign: 'middle' }}>
                <td>{c}</td>
                <td style={{ textAlign: 'center' }}>
                  <Form.Group>
                    <StyledSelect
                      isClearable
                      placeholder={t('condominia.bulk_import.mapping_form.select_column', 'Seleziona colonna')}
                      value={selected}
                      options={availableColumnsOptions}
                      onChange={this.handleColumnChange(c)}
                    />
                  </Form.Group>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }
}

export default MappingForm
