import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'lib/axios'
import i18next from 'i18next'

import config from 'config'
import { success, error } from 'components/system_wide/notification'
import jsonapiToData from 'lib/jsonapi_to_data'
import { isAdmin, getRoleIdByName } from 'lib/utils'

const { t } = i18next

export const initialState = {
  errors: [],
  loading: false,
  show_add_contract: false,
  edit_contract: {},
  contracts: [],
  suppliers: [],
  expired_contracts: [],
  filtered_services: [],
  filtered_condominia: [],
  filtered_users: [],
  filtered_dates: [],
  total: 0,
  expired_total: 0,
  current_page: 1,
  my_contracts: [],
  filters: {},
}

export const getContract = createAsyncThunk(
  'contracts/getContract',
  async ({ condominium_id, contract_id }, thunkApi) => {
    try {
      let url = `${config.SERVER_API_URL}/v1/condominia/${condominium_id}/contracts/${contract_id}?include=condominium,attachments,service,user,user.tax_datum`
      let response = await axios({
        url,
        method: 'get',
      })
      let contractData = jsonapiToData(response.data)
      return { edit_contract: contractData.data[0] }
    } catch (err) {
      console.log(err)
      error(t('store.contracts.get_contract_error', 'Impossibile recuperare il contratto'))
      return thunkApi.rejectWithValue()
    }
  }
)
export const getMyContracts = createAsyncThunk('contracts/my_contracts', async ({ user_id }, thunkApi) => {
  try {
    let url = `${config.SERVER_API_URL}/v1/users/${user_id}/contracts?filter[only_for]=user`
    let response = await axios({
      url,
      method: 'get',
    })
    let { data: contractsData } = response.data
    let { included } = response.data
    let contracts = contractsData.map(c => {
      let { id, attributes, relationships: rels } = c
      let serviceData = included.find(si => rels.service.data.id === si.id && si.type === 'services')
      let { id: servId, attributes: servAttributes } = serviceData
      return {
        id,
        ...attributes,
        service: { id: servId, ...servAttributes },
      }
    })
    return contracts
  } catch (err) {
    console.log(err)
    error(t('store.contracts.get_contracts_error', 'Impossibile recuperare i contratti'))
    return thunkApi.rejectWithValue()
  }
})

export const getContracts = createAsyncThunk('contracts/list', async (params, thunkApi) => {
  try {
    let { roles } = thunkApi.getState().usersData ?? []
    let type_of_contracts = params.expired ?? false
    let { offset, limit, sortData, filter } = params
    let { sort_column, sort_direction } = sortData
    let state = thunkApi.getState().contracts
    let current_page = parseInt(offset / limit) + 1 || 1

    let user = thunkApi.getState().userInfo || { role_id: getRoleIdByName('building_administrator', roles), id: 0 }
    let { role_id, id: user_id } = user

    let url = `${config.SERVER_API_URL}/v1/users/${user_id}/contracts?include=condominium,user,user.tax_datum,service&models_for_filters=true&filter[only_for]=condominium`
    url = url + `&page=${current_page}&per=${limit}&filter[expired]=${type_of_contracts}`

    // Admin o Tecnico
    if (isAdmin(role_id, roles)) {
      url = `${config.SERVER_API_URL}/v1/contracts?include=condominium,user,user.tax_datum,service&models_for_filters=true&filter[only_for]=condominium`
      url = url + `&page=${current_page}&per=${limit}&filter[expired]=${type_of_contracts}`
    }
    if (filter) url = url + `&filter[q]=${encodeURIComponent(filter)}`
    if (state.filtered_services.length > 0) url = url + `&filter[with_service]=${state.filtered_services.join(',')}`
    if (state.filtered_users.length > 0) url = url + `&filter[with_user]=${state.filtered_users.join(',')}`
    if (state.filtered_condominia.length > 0)
      url = url + `&filter[with_condominium]=${state.filtered_condominia.join(',')}`
    if (state.filtered_dates.length > 0) url = url + `&filter[with_expire_at]=${state.filtered_dates.join(',')}`
    if (sort_column !== '') url = url + `&sort=${sort_direction === 'ASC' ? '' : '-'}${sort_column}`
    let response = await axios({
      url,
      method: 'get',
    })

    let contractsData = jsonapiToData(response.data)
    let { data: contracts, total, filters } = contractsData
    if (type_of_contracts === false) return { contracts, total, current_page, filters }
    else return { expired_contracts: contracts, expired_total: total, current_page, filters }
  } catch (err) {
    console.log(err)
    error(t('store.contracts.get_contracts_error', 'Impossibile recuperare i contratti'))
    return thunkApi.rejectWithValue()
  }
})

export const updateContract = createAsyncThunk(
  'contracts/updateContract',
  async ({ contract_id, data, notify = true }, thunkApi) => {
    try {
      let url = `${config.SERVER_API_URL}/v1/contracts/${contract_id}`
      await axios({
        url,
        method: 'PATCH',
        data,
      })
      if (notify) {
        success(t('store.contracts.update_contract_success', 'Dati contratto aggiornati con successo'))
      }
    } catch (err) {
      console.log(err)
      error(t('store.contracts.get_contract_error', 'Impossibile recuperare il contratto'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const deleteContract = createAsyncThunk(
  'contracts/deleteContract',
  async ({ condominium_id, contract_id }, thunkApi) => {
    try {
      let url = `${config.SERVER_API_URL}/v1/condominia/${condominium_id}/contracts/${contract_id}`
      await axios({
        url,
        method: 'DELETE',
      })
      success(t('store.contracts.delete_contract_success', 'Contratto eliminato con successo'))
    } catch (err) {
      console.log(err)
      error(t('store.contracts.get_contract_error', 'Impossibile recuperare il contratto'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const deleteAttachment = createAsyncThunk(
  'contracts/deleteAttachment',
  async ({ attachment_id, contract_id }, thunkApi) => {
    try {
      let url = `${config.SERVER_API_URL}/v1/contracts/${contract_id}/attachments/${attachment_id}`
      await axios({
        url,
        method: 'DELETE',
      })
      success(t('store.contracts.delete_attachments_contract_success', 'Allegato contratto eliminato con successo'))
    } catch (err) {
      console.log(err)
      error(t('store.contracts.delete_attachments_contract_error', 'Impossibile eliminare allegato del contratto'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const getSuppliers = createAsyncThunk('contracts/getSuppliers', async (_, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/v1/suppliers`,
      method: 'GET',
    })
    let suppliersData = jsonapiToData(response.data)
    let suppliers = suppliersData.data
    return suppliers
  } catch (err) {
    console.log(err)
    error(t('store.common.get_cities_error', 'Impossibile recupeare le città'))
    return thunkApi.rejectWithValue()
  }
})

export const addNewSupplier = createAsyncThunk('contracts/addNewSupplier', async (supplier_name, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/v1/suppliers`,
      method: 'POST',
      data: { name: supplier_name },
    })
    success(t('store.contracts.create_new_supplier', 'Fornitore aggiunto con successo'))
    return response.data.data.id
  } catch (err) {
    console.log(err)
    error(t('store.common.get_cities_error', 'Impossibile recupeare le città'))
    return thunkApi.rejectWithValue()
  }
})

export const contractsSlice = createSlice({
  name: 'contracts',
  initialState,

  reducers: {
    filterServices: (state, action) => {
      state.filtered_services = action.payload
    },
    filterCondominia: (state, action) => {
      state.filtered_condominia = action.payload
    },
    filterUsers: (state, action) => {
      state.filtered_users = action.payload
    },
    filterDates: (state, action) => {
      state.filtered_dates = action.payload
    },
  },
  extraReducers: {
    [getContract.fulfilled]: (state, action) => {
      if (!action?.payload) return
      state.edit_contract = action.payload.edit_contract
    },
    [getContract.rejected]: state => {
      state.edit_contract = {}
      state.users = []
    },
    [getContracts.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { total, contracts, expired_total, expired_contracts, current_page } = action.payload
      if (contracts) {
        state.total = total
        state.contracts = current_page === 1 ? contracts : [...state.contracts, ...contracts]
      }
      if (expired_contracts) {
        state.expired_total = expired_total
        state.expired_contracts =
          current_page === 1 ? expired_contracts : [...state.expired_contracts, ...expired_contracts]
      }
      state.filters = action.payload.filters
      state.loading = false
      state.current_page = current_page
    },
    [getContracts.rejected]: state => {
      state.total = initialState.total
      state.contracts = initialState.contracts
      state.expired_total = initialState.expired_total
      state.expired_contracts = initialState.expired_contracts
      state.filters = {}
      state.loading = false
      state.current_page = 1
    },
    [getMyContracts.fulfilled]: (state, action) => {
      state.my_contracts = action.payload
    },
    [getMyContracts.rejected]: (state, action) => {
      state.my_contracts = []
    },
    [getSuppliers.fulfilled]: (state, action) => {
      state.suppliers = action.payload
    },
    [getSuppliers.rejected]: state => {
      state.suppliers = []
    },
  },
})

export const { filterServices, filterCondominia, filterUsers, filterDates } = contractsSlice.actions
export default contractsSlice.reducer
