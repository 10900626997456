import i18next from 'i18next'

const { t } = i18next
let map = { user: 'user_id', new_user: 'new_user_id', document: 'selectedFile' }

const validateFields = (data, validators) => {
  let requiredFields = Object.keys(validators).filter(el => validators[el].type === 'PresenceValidator')
  let sizeValidFields = Object.keys(validators).filter(el => validators[el].type === 'SizeValidator')
  Object.keys(map).forEach(el => {
    let i = requiredFields.indexOf(el)
    requiredFields[i] = map[el]
  })
  Object.keys(map).forEach(el => {
    let i = sizeValidFields.indexOf(el)
    sizeValidFields[i] = map[el]
  })
  requiredFields = [...requiredFields, 'user_id', ...(data['new_user_id'] !== null ? ['selectedFile'] : [])]

  // assegnazione condominio ceduto
  if (data['unassigned'] === true) {
    requiredFields = requiredFields.filter(f => f !== 'user_id')
  }
  // un condominio viene ceduto ad altro gestore
  if (data['unassignment'] === true) {
    requiredFields = requiredFields.filter(f => f !== 'new_user_id')
  }
  let errors = {}

  // check required fields
  for (const field of requiredFields) {
    if (data[field] === '' || data[field] === null)
      errors[field] = t('transfer.transfer_form.errors.required_field', 'Campo obbligatorio')
  }

  for (const field of sizeValidFields) {
    if (data[field]) {
      let map_key = Object.keys(map).find(m => map[m] === field)
      if (validators[map_key].options.less_than < data[field].size) {
        errors[field] =
          validators[map_key].options.message ??
          `Dimensione del file superiore a ${validators[map_key].options.less_than} byte`
      }
      if (!data[field].type.includes('pdf')) {
        errors[field] = t('transfer.transfer_form.errors.wrong_type_field', 'Formato non valido - solo file PDF')
      }
    }
  }

  if (data['selected_condominia'].length === 0) {
    errors['selected_condominia'] = t('transfer.transfer_form.errors.required_field', 'Campo obbligatorio')
  }

  return errors
}

export default validateFields
