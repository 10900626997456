import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'lib/axios'
import i18next from 'i18next'

import config from 'config'
import { success, error } from 'components/system_wide/notification'
import transformJsonAPI from 'lib/jsonapi_to_data'
import { isAdmin, getRoleIdByName } from 'lib/utils'

const { t } = i18next

export const initialState = {
  errors: {},
  loading: false,
  show_add_transfer: false,
  transfers: [],
  total: 0,
  current_page: 1,
  condominia: null,
  states: [],
  filtered_states: [],
  validators: {},
}

export const getValidators = createAsyncThunk('transfers/get_validators', async (_, thunkApi) => {
  try {
    let url = `${config.SERVER_API_URL}/v1/models/transfer`
    let response = await axios({
      url,
      method: 'get',
    })
    return response.data.data
  } catch (err) {
    console.log(err)
    error(t('store.transfers.get_transfers_validators_error', 'Impossibile recuperare i validatori'))
    return thunkApi.rejectWithValue()
  }
})

export const getStates = createAsyncThunk('transfers/get_states', async (_, thunkApi) => {
  try {
    let url = `${config.SERVER_API_URL}/v1/state_machines/transfer`
    let response = await axios({
      url,
      method: 'get',
    })
    return response.data.data
  } catch (err) {
    console.log(err)
    error(t('store.transfers.get_transfers_error', 'Impossibile recuperare i trasferimenti'))
    return thunkApi.rejectWithValue()
  }
})

export const getTransfers = createAsyncThunk('transfers/list', async (params, thunkApi) => {
  try {
    let state = thunkApi.getState().transfers
    let { roles } = thunkApi.getState().usersData ?? []
    let { offset, limit, sortData, filter } = params
    let { sort_column, sort_direction } = sortData
    let current_page = parseInt(offset / limit) + 1 || 1

    let user = thunkApi.getState().userInfo || { role_id: getRoleIdByName('building_administrator', roles), id: 0 }
    let { role_id, id: user_id } = user

    let url = `${config.SERVER_API_URL}/v1/users/${user_id}/transfers?include=condominia,new_user,user`
    // Admin o Tecnico
    if (isAdmin(role_id, roles)) {
      url = `${config.SERVER_API_URL}/v1/transfers?include=condominia,new_user,user`
    }
    url = url + `&page=${current_page}&per=${limit}`
    if (state.filtered_states.length > 0) url = url + `&filter[with_current_state]=${state.filtered_states.join(',')}`
    if (sort_column !== '') url = url + `&sort=${sort_direction === 'ASC' ? '' : '-'}${sort_column}`
    if (filter) url = url + `&filter[q]=${encodeURIComponent(filter)}`

    let response = await axios({
      url,
      method: 'get',
    })
    let result = transformJsonAPI(response.data)
    return { ...result, current_page }
  } catch (err) {
    console.log(err)
    error(t('store.transfers.get_transfers_error', 'Impossibile recuperare i trasferimenti'))
    return thunkApi.rejectWithValue()
  }
})

export const acceptTransfer = createAsyncThunk('transfers/acceptTransfer', async ({ transfer_id }, thunkApi) => {
  try {
    let { roles } = thunkApi.getState().usersData ?? []
    let user = thunkApi.getState().userInfo || { role_id: getRoleIdByName('building_administrator', roles), id: 0 }
    let { role_id, id: user_id } = user

    let url = `${config.SERVER_API_URL}/v1/users/${user_id}/transfers/${transfer_id}`
    if (isAdmin(role_id, roles)) {
      url = `${config.SERVER_API_URL}/v1/transfers/${transfer_id}`
    }

    await axios({
      url,
      method: 'PUT',
    })
    success(t('store.transfers.accet_transfer_success', 'Trasferimento accettato con successo'))
  } catch (err) {
    console.log(err)
    error(t('store.transfers.accept_transfer_error', 'Impossibile accettare il trasferimento'))
    return thunkApi.rejectWithValue()
  }
})

export const deleteTransfer = createAsyncThunk('transfers/deleteTransfer', async ({ transfer_id }, thunkApi) => {
  try {
    let { roles } = thunkApi.getState().usersData ?? []
    let user = thunkApi.getState().userInfo || { role_id: getRoleIdByName('building_administrator', roles), id: 0 }
    let { role_id, id: user_id } = user

    let url = `${config.SERVER_API_URL}/v1/users/${user_id}/transfers/${transfer_id}`
    if (isAdmin(role_id, roles)) {
      url = `${config.SERVER_API_URL}/v1/transfers/${transfer_id}`
    }

    await axios({
      url,
      method: 'DELETE',
    })
    success(t('store.transfers.delete_transfer_success', 'Trasferimento cancellato con successo'))
  } catch (err) {
    console.log(err)
    error(t('store.transfers.delete_transfer_error', 'Impossibile cancellare il trasferimento'))
    return thunkApi.rejectWithValue()
  }
})

export const getUserCondominia = createAsyncThunk('transfers/condominia_list', async (user_id, thunkApi) => {
  try {
    let url = `${config.SERVER_API_URL}/v1/users/${user_id}/condominia?per=10000`
    if (user_id === null) {
      url = `${config.SERVER_API_URL}/v1/condominia?filter[only_unassigned]=true`
    }
    let response = await axios({
      url,
      method: 'get',
    })
    let result = transformJsonAPI(response.data)
    return result
  } catch (err) {
    console.log(err)
    error(t('store.condominia.get_condominia_error', 'Impossibile recuperare i condomini'))
    return thunkApi.rejectWithValue()
  }
})

export const transfersSlice = createSlice({
  name: 'transfers',
  initialState,

  reducers: {
    resetCondominia: state => {
      state.condominia = null
    },
    filterStates: (state, action) => {
      state.filtered_states = action.payload
    },
    updateErrors: (state, action) => {
      // let { errors } = action.payload
      state.errors = action.payload
    },
  },
  extraReducers: {
    [getUserCondominia.fulfilled]: (state, action) => {
      state.condominia = action.payload.data
    },
    [getUserCondominia.rejected]: state => {
      state.condominia = []
    },
    [getStates.fulfilled]: (state, action) => {
      state.states = action.payload
    },
    [getStates.rejected]: state => {
      state.states = []
    },
    [getValidators.fulfilled]: (state, action) => {
      state.validators = action.payload.validators
    },
    [getValidators.rejected]: state => {
      state.validators = {}
    },
    [getTransfers.fulfilled]: (state, action) => {
      let { data, current_page, total } = action.payload
      state.transfers = current_page === 1 ? data : [...state.transfers, ...data]
      state.total = total
      state.current_page = current_page
      state.loading = false
    },
    [getTransfers.rejected]: state => {
      state.total = initialState.total
      state.transfers = initialState.transfers
      state.loading = false
      state.current_page = 1
    },
  },
})

export const { resetCondominia, filterStates, updateErrors } = transfersSlice.actions
export default transfersSlice.reducer
