import React from 'react'
import { Trans } from 'react-i18next'
import i18next from 'i18next'
import { Modal, Button, Card, Form } from 'react-bootstrap'

import 'scss/assignments.css'
import StyledSelect from 'components/system_wide/styled_select'

const { t } = i18next

class Signature extends React.Component {
  state = { otp_request: false, otp: '', renew: false }

  onRequestOTP = () => {
    this.setState({ otp_request: true })
    this.props.onRequestOTP(this.props.assignment.id)
  }
  onConfirmOTP = () => {
    this.props.onConfirmOTP(this.props.assignment.id, this.state.otp)
  }
  onRenewOTP = () => {
    this.setState({ renew: true })
    this.props.onRenewOTP(this.props.assignment.id)
  }
  handleChange = e => {
    this.setState({ otp: e.target.value, renew: false })
  }

  renderRequestOTP = () => {
    let { user } = this.props.assignment
    let send_methods = [
      { id: 1, label: `${t('assignments.services.authenticate_signature.method1', 'Email a')} ${user.email}` },
    ]
    let selected_method = [send_methods[0]]

    return (
      <div className="align-items-center justify-content-center text-center d-flex">
        <Card>
          <Card.Title className="pt-3">
            <Trans i18nKey="assignments.services.authenticate_signature.title">Autenticazione firma</Trans>
          </Card.Title>
          <Card.Body>
            <Trans i18nKey="assignments.services.authenticate_signature.msg">
              Per autenticare la firma e' necessario inserire il codice OTP che sara' inviato.
            </Trans>
            <Form.Label>
              <Trans i18nKey="assignments.services.authenticate_signature.select_modal">Seleziona modalita'</Trans>
            </Form.Label>
            <StyledSelect
              className="pt-2"
              placeholder={t('assignments.services.select_method', 'Seleziona il metodo..')}
              value={selected_method}
              onChange={this.handleServiceChange}
              options={send_methods}
            />
          </Card.Body>
        </Card>
      </div>
    )
  }

  renderInsertOTP = () => {
    return (
      <div className="align-items-center justify-content-center text-center d-flex">
        <Card className="w-100">
          <Card.Title className="pt-3">
            <Trans i18nKey="assignments.services.authenticate_signature.title">Autenticazione firma</Trans>
          </Card.Title>
          <Card.Body className="border m-2 border-primary bg-opacity-10 bg-primary">
            <Form.Label>
              <Trans i18nKey="assignments.services.authenticate_signature.insert_otp">Inserisci OTP</Trans>
            </Form.Label>
            <Form.Control className="text-center" type="text" id="otp" name="otp" onChange={this.handleChange} />
            {this.state.renew === true && (
              <div className="p-0 m-0 pt-3 text-success">
                <Trans i18nKey="assignments.services.authenticate_signature.renew_msg">
                  Il nuovo OTP e' stato rinviato al metodo di contatto scelto
                </Trans>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    )
  }

  render() {
    let { assignment } = this.props

    return (
      <Modal backdrop="static" centered show={true} onHide={this.props.onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans i18nKey="assignments.signature_modal.modal_title">Firma il resoconto</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {assignment.current_state === 'pending' && this.renderRequestOTP()}
          {assignment.current_state === 'otp' && assignment.verified === false && this.renderInsertOTP()}
        </Modal.Body>
        <Modal.Footer className="d-flex flex-row justify-content-start">
          {assignment.current_state === 'otp' && assignment.verified === false && (
            <Button
              onClick={this.onRenewOTP}
              className="me-auto"
              style={{ backgroundColor: 'var(--bs-gray-500)', borderColor: 'gray' }}
            >
              <Trans i18nKey="common.renew_otp_button">Rinnova OTP</Trans>
            </Button>
          )}
          <Button onClick={this.props.onCloseModal} variant="secondary">
            <Trans i18nKey="common.cancel_button">Annulla</Trans>
          </Button>
          {assignment.current_state === 'pending' && (
            <Button onClick={this.onRequestOTP} variant="success">
              <Trans i18nKey="common.request_otp_button">Richiedi OTP</Trans>
            </Button>
          )}
          {assignment.current_state === 'otp' && assignment.verified === false && (
            <Button onClick={this.onConfirmOTP} variant="success" disabled={this.state.otp === ''}>
              <Trans i18nKey="common.send_otp_button">Invia OTP</Trans>
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}

export default Signature
