import React from 'react'
import axios from 'lib/axios'
import config from 'config'
import { Trans } from 'react-i18next'
import i18next from 'i18next'
import { connect } from 'react-redux'
import { Button, Modal, Table, Form } from 'react-bootstrap'
import { DebounceInput } from 'react-debounce-input'
import StyledSelect from '../system_wide/styled_select'
import { getUserCondominia, resetCondominia, updateErrors } from 'store/transfers'
import { getAdministrators } from 'store/condominia'
import { error, success } from 'components/system_wide/notification'
import jsonapiToData from 'lib/jsonapi_to_data'
import validateFields from 'lib/validators/transfer'
import { isAdmin as _isAdmin } from 'lib/utils'
import WithCapabilities from 'enhancers/with_capabilities'

const { t } = i18next

const mstp = state => {
  let { administrators } = state.condominia
  let { condominia, validators, errors } = state.transfers
  let { role_id, id: my_user_id } = state.userInfo
  let { roles } = state.usersData
  let isAdmin = _isAdmin(role_id, roles)
  return { administrators, condominia, role_id, roles, my_user_id, validators, errors, isAdmin }
}

class ModalNewTransfer extends React.Component {
  static defaultProps = {
    administrators: [],
    validators: {},
  }
  initialState = {
    user_id: null,
    new_user_id: null,
    new_user_cf: '',
    new_user_name: '',
    // name: '',
    // surname: '',
    // email: '',
    // business_name: '',
    // vat_number: '',
    // city_id: '',
    // mobile_phone: '',
    document: null,
    selectedFile: '',
    selectedFileLabel: '',
    selected_condominia: [],
    filter_condominia: '',
    unassigned: false,
    unassignment: false,
  }
  state = { ...this.initialState }

  uploadForm = null
  inputRef = null

  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
  }

  onConfirm = async () => {
    try {
      let isFormValid = this.isFormValid()
      if (Object.keys(isFormValid).length > 0) {
        this.props.dispatch(updateErrors(isFormValid))
        return
      }
      let formData = new FormData()
      let { user_id, new_user_id, selected_condominia, unassigned, unassignment } = this.state
      if (this.state.selectedFile) {
        formData.append('_jsonapi[document]', this.state.selectedFile)
      }
      if (unassigned === false) {
        formData.append('_jsonapi[user_id]', user_id)
      }
      if (unassignment === true) {
        formData.append('_jsonapi[unassignment]', true)
      } else {
        formData.append('_jsonapi[new_user_id]', new_user_id)
      }
      selected_condominia.forEach(sel => {
        formData.append('_jsonapi[condominium_transfers_attributes][][condominium_id]', sel)
      })

      let { my_user_id, isAdmin } = this.props
      let url = `${config.SERVER_API_URL}/v1/users/${my_user_id}/transfers`
      if (isAdmin) {
        url = `${config.SERVER_API_URL}/v1/transfers`
      }

      await axios({
        url,
        method: 'POST',
        data: formData,
      })
      this.props.dispatch(resetCondominia())
      this.props.onCloseModal()
      success(t('store.transfers.new_transfers_ok', 'Trasferimento inserito con successo'))
    } catch (err) {
      if (err?.response?.data?.errors?.title) error(err.response.data.errors.title, 4000)
      if (err?.response?.data?.errors?.[0]) error(err.response.data.errors[0].detail, 4000)
      error(t('store.transfers.new_transfers_ko', 'Impossibile inserire il trasferimento'))
    }
  }

  FileUploadHandler = e => {
    if (e.target.files) {
      let file = e.target.files[0]
      if (file) {
        let selectedFileLabel = file.name
        let selectedFile = file
        this.setState({ selectedFile, selectedFileLabel })
      }
    } else {
      this.setState({ selectedFile: '', selectedFileLabel: '' })
    }
    let errors = { ...this.props.errors }
    delete errors['selectedFile']
    this.props.dispatch(updateErrors({ ...errors }))
  }

  async componentDidMount() {
    if (this.props.administrators.length === 0) {
      await this.props.dispatch(getAdministrators())
    }
    if (this.props.condominia?.length !== 0) {
      this.props.dispatch(resetCondominia())
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.show_modal !== prevProps.show_modal && this.props.show_modal === true) {
      if (this.props.isAdmin) {
        this.setState({ user_id: this.props.my_user_id }, () =>
          this.props.dispatch(getUserCondominia(this.props.my_user_id))
        )
      }
    }
    if (this.props.show_modal !== prevProps.show_modal && this.props.show_modal === false) {
      this.setState({ ...this.initialState })
      this.props.dispatch(resetCondominia())
    }
  }
  componentWillUnmount() {
    this.setState({ ...this.initialState })
    this.props.dispatch(resetCondominia())
    this.props.dispatch(updateErrors({}))
  }

  handleSelectChange = type => option => {
    let value = option ? option.value : ''
    if (type === 'user_id' && value !== '') {
      this.props.dispatch(getUserCondominia(value))
    }
    if (type === 'user_id' && value === '') {
      this.props.dispatch(resetCondominia())
    }
    this.setState({ [type]: value })
    let errors = { ...this.props.errors }
    delete errors[type]
    this.props.dispatch(updateErrors({ ...errors }))
  }

  handleChangeUnassignment = () => {
    let unassigned = this.state.unassigned
    if (unassigned === false) {
      this.setState({ user_id: null, unassigned: true, unassignment: false })
      this.props.dispatch(getUserCondominia(null))
    } else {
      this.setState({ user_id: null, unassigned: false, unassignment: false })
      this.props.dispatch(resetCondominia())
    }
  }

  handleChangeNewUser = ({ target: { value } }) => {
    this.setState({ new_user_cf: value })
    let errors = { ...this.props.errors }
    delete errors['new_user_id']
    this.props.dispatch(updateErrors({ ...errors }))
  }
  searchNewUser = async () => {
    try {
      let response = await axios({
        url: `${config.SERVER_API_URL}/v1/users?filter[with_vat_number]=${this.state.new_user_cf}`,
        method: 'GET',
      })
      let result = jsonapiToData(response.data)
      if (result?.data?.length) {
        let user = result.data[0]
        this.setState({ new_user_id: user.id, new_user_name: `${user.name} ${user.surname}` })
      } else {
        this.setState({ new_user_id: null, new_user_name: `Nessun amministratore trovato!` })
      }
    } catch (err) {
      error(t('store.transfers.search_user', "Impossibile trovare l'amministratore"))
    }
  }

  checkCondominium = cd_id => () => {
    let { selected_condominia } = this.state
    if (selected_condominia.includes(cd_id)) {
      selected_condominia = selected_condominia.filter(s => s !== cd_id)
    } else {
      selected_condominia.push(cd_id)
    }
    this.setState({ selected_condominia })
    let errors = { ...this.props.errors }
    delete errors['selected_condominia']
    this.props.dispatch(updateErrors({ ...errors }))
  }

  isFormValid = () => {
    let { user_id, new_user_id, selected_condominia, selectedFile, unassigned, unassignment } = this.state
    let valid = validateFields(
      { user_id, new_user_id, selectedFile, selected_condominia, unassigned, unassignment },
      this.props.validators
    )
    return valid
  }

  onFilterChange = e => {
    this.setState({ filter_condominia: e.target.value })
  }

  render() {
    let { show_modal, administrators, condominia, errors } = this.props
    let {
      selectedFileLabel,
      user_id,
      new_user_id,
      selected_condominia,
      new_user_cf,
      new_user_name,
      unassigned,
      unassignment,
    } = this.state

    let adminOpts = administrators.map(adm => {
      let label = adm.business_name ?? `${adm.surname} ${adm.name}`
      return {
        id: adm.id,
        value: adm.id,
        label: `${label} - ${adm.vat_number}`,
      }
    })

    return (
      <Modal backdrop="static" centered show={show_modal} onHide={this.props.onCloseModal} size="lg">
        <Modal.Header className="pb-0" closeButton>
          <Modal.Title>
            <h4 className="text-primary p-1">
              <Trans i18nKey="transfers.modal_new_title">Nuovo trasferimento</Trans>
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column">
          <div className="row">
            <div className="col-5">
              <div className="text-muted modal-title">Precedente amministratore</div>
              <StyledSelect
                name="user_id"
                className={`${'user_id' in errors ? 'is-invalid' : ''}`}
                valid={!('user_id' in errors)}
                isClearable
                placeholder={''}
                isDisabled={unassigned}
                value={adminOpts.find(u => u.value === user_id)}
                onChange={this.handleSelectChange('user_id')}
                options={adminOpts.filter(u => u.value !== new_user_id)}
              />
              <Form.Control.Feedback type="invalid">{errors.user_id}</Form.Control.Feedback>
              <WithCapabilities isAdmin={true}>
                <Form.Check
                  type="switch"
                  id="unassigned"
                  label="Condominio ceduto"
                  className="mt-2"
                  value={unassigned}
                  onChange={this.handleChangeUnassignment}
                  disabled={unassignment}
                />
              </WithCapabilities>
            </div>
            <div className="col-2">
              <div className="text-white">.</div>
              <div className="text-muted" style={{ paddingLeft: '2.5rem', paddingTop: '0.25rem' }}>
                <i className="fas fa-angle-double-right"></i>
                <i className="fas fa-angle-double-right"></i>
              </div>
            </div>
            <div className="col-5">
              <div className="text-muted modal-title">Nuovo amministratore</div>
              <WithCapabilities isAdmin={true}>
                <StyledSelect
                  name="new_user_id"
                  className={`${'new_user_id' in errors ? 'is-invalid' : ''}`}
                  valid={!('new_user_id' in errors)}
                  isClearable
                  placeholder={''}
                  isDisabled={unassignment}
                  value={adminOpts.find(u => u.value === new_user_id)}
                  onChange={this.handleSelectChange('new_user_id')}
                  options={adminOpts.filter(u => u.value !== user_id)}
                />
                <Form.Control.Feedback type="invalid">{errors.new_user_id}</Form.Control.Feedback>
                <Form.Check
                  type="switch"
                  id="unassignment"
                  label="Condominio da cedere"
                  className="mt-2"
                  value={unassignment}
                  onChange={() => this.setState({ unassignment: !unassignment, unassigned: false })}
                  disabled={unassigned}
                />
              </WithCapabilities>
              <WithCapabilities isNotAdmin={true}>
                <div className="d-flex flex-row">
                  <Form.Control
                    name="name"
                    placeholder={t('transfers.new_user_fiscal_code', 'Codice fiscale..')}
                    value={new_user_cf}
                    onChange={this.handleChangeNewUser}
                  />
                  <Button variant="primary" className="ms-2 float-md-end" onClick={this.searchNewUser}>
                    <i className={`fa fa-search`} />
                  </Button>
                </div>
                <div className={`mt-2 fw-bold border modal-title p-2 ${new_user_id ? 'text-primary' : 'text-muted'}`}>
                  <span>{new_user_name ?? ''}</span>
                </div>
              </WithCapabilities>
            </div>
          </div>
          <div className="row mt-4 pt-2 border-top">
            <div className="text-muted modal-title">
              <Trans i18nKey="transfers.modal_new_transfers.verbal">
                Carica il file del verbale d'assemblea (Accettati solo PDF - massimo 2Mb):
              </Trans>
            </div>
            <div className="row">
              <div className="col-9">
                <Form.Control
                  className={`${'selectedFile' in errors ? 'is-invalid' : ''}`}
                  type="file"
                  id="upload"
                  key="upload_form"
                  label={selectedFileLabel}
                  ref={form => (this.uploadForm = form)}
                  onChange={this.FileUploadHandler}
                />
                <Form.Control.Feedback type="invalid">{errors.selectedFile}</Form.Control.Feedback>
              </div>
            </div>
          </div>
          <div className="row border-top mt-3 pt-4 pb-2">
            <h5>Condomini da trasferire</h5>
          </div>
          <div className="d-flex">
            <DebounceInput
              debounceTimeout={300}
              className="form-control mb-2 w-50"
              onChange={this.onFilterChange}
              placeholder={t('common.filter', 'Cerca..')}
            />
            {'selected_condominia' in errors && (
              <div className="pt-1 ps-3 text-danger">{errors.selected_condominia}</div>
            )}
          </div>
          <div className="overflow-auto" style={{ height: '15rem' }}>
            <Table striped bordered hover size="sm" style={{ borderSpacing: 0, borderCollapse: 'separate' }}>
              <thead style={{ position: 'sticky', top: 0, background: 'white', width: '101%' }}>
                <tr>
                  <th className="border"></th>
                  <th className="border">Nome</th>
                  <th className="border">Indirizzo</th>
                  <th className="border">CF</th>
                </tr>
              </thead>
              <tbody>
                {condominia
                  ?.filter(cd => {
                    if (this.state.filter_condominia !== '') {
                      return cd.name.toLowerCase().includes(this.state.filter_condominia.toLowerCase()) ? cd : null
                    } else {
                      return cd
                    }
                  })
                  .map(cd => {
                    return (
                      <tr style={{ height: '1rem' }} key={`cd-${cd.id}`}>
                        <td>
                          <Form.Check
                            type="checkbox"
                            onChange={this.checkCondominium(cd.id)}
                            checked={selected_condominia.find(s => cd.id === s)}
                          />
                        </td>
                        <td>{cd.name}</td>
                        <td>{cd.street}</td>
                        <td>{cd.fiscal_code}</td>
                      </tr>
                    )
                  })}
                {!condominia && (
                  <tr>
                    <td colSpan="4" className="text-center pt-3">
                      Seleziona l'amministratore possessore dei condomini.
                    </td>
                  </tr>
                )}
                {condominia && condominia.length === 0 && (
                  <tr>
                    <td colSpan="4" className="text-center pt-3">
                      L'amministratore non possiede alcun condominio.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <div className="mt-3 text-muted border-top pt-3">
            <b>NB</b>: tutti i campi sono obbligatori
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onCloseModal} variant="secondary" className="me-auto">
            <Trans i18nKey="common.cancel_button">Annulla</Trans>
          </Button>
          <Button onClick={this.onConfirm} variant="primary">
            <Trans i18nKey="common.start_transfer">Inserisci trasferimento</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default connect(mstp)(ModalNewTransfer)
