import React from 'react'
import i18next from 'i18next'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { success } from 'components/system_wide/notification'

const { t } = i18next

class CopyToClipboard extends React.Component {
  render() {
    return (
      <OverlayTrigger
        key={Math.random()}
        delay={{ show: 250, hide: 400 }}
        trigger={['hover', 'focus']}
        overlay={<Tooltip>Click per copiare negli appunti</Tooltip>}
      >
        <div
          onClick={() => {
            navigator.clipboard.writeText(this.props.value)
            success(t('common.copy_to_clipboard', 'Copiato negli appunti'))
          }}
        >
          {this.props.value}
        </div>
      </OverlayTrigger>
    )
  }
}

export default CopyToClipboard
