import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'lib/axios'
import i18next from 'i18next'
import config from 'config'
import { error, success } from 'components/system_wide/notification'
import jsonapiToData from 'lib/jsonapi_to_data'
import { getRoleIdByName, isAdmin } from 'lib/utils'

const { t } = i18next

export const initialState = {
  errors: [],
  loading: false,
  show_add_condominia: false,
  edit_condominia: { contracts: [] },
  condominia: [],
  geo_condominia: [],
  administrators: [],
  filtered_services: [],
  filtered_users: [],
  filters: { services: [], users: [] },
  total: 0,
  first_total: -1,
  total_ids: [],
  current_page: 1,
  pages: 1,
  limit: 15,
  filter: '',
  progress: -1,
}

export const getAdministrators = createAsyncThunk('condominia/users/list', async (_, thunkApi) => {
  try {
    let { roles } = thunkApi.getState().usersData
    let url = `${config.SERVER_API_URL}/v1/users?include=tax_datum&page=1&per=100000&sort=surname,name`
    url = url + `&filter[with_role]=` + getRoleIdByName('building_administrator', roles)
    let response = await axios({
      url,
      method: 'get',
    })

    let { data: administrators } = response.data
    let { included } = response.data
    administrators = administrators.map(u => {
      let { id, attributes } = u
      let role_id = u.relationships.role?.data?.id || null
      let tax_datum_id = u.relationships.tax_datum.data?.id || null
      let phone, vat_number, business_name
      if (tax_datum_id) {
        let tax_data = included.find(t => t.type === 'tax_data' && t.id === tax_datum_id)
        phone = tax_data.attributes.phone
        vat_number = tax_data.attributes.vat_number
        business_name = tax_data.attributes.business_name
      }
      return { id, ...attributes, role_id: role_id, phone, vat_number, business_name }
    })
    return { administrators }
  } catch (err) {
    console.log(err)
    error(t('store.condominia.get_administrators_error', 'Impossibile recuperare gli amministratori'))
    return thunkApi.rejectWithValue()
  }
})

export const createCondominium = createAsyncThunk(
  'condominia/createCondominium',
  async ({ user_id, condominium, mng_service = false }, thunkApi) => {
    try {
      let res = await axios({
        url: `${config.SERVER_API_URL}/v1/users/${user_id}/condominia`,
        method: 'POST',
        data: { ...condominium },
      })

      thunkApi.dispatch(setLoading(true))
      if (mng_service === false) {
        thunkApi.dispatch(resetCurrentCondominium())
        thunkApi.dispatch(getCondominia())
        success(t('store.condominia.create_condomium_success', 'Condominio inserito con successo'))
      } else {
        let condominium_id = res.data.data.id
        thunkApi.dispatch(getCondominium({ condominium_id }))
        thunkApi.dispatch(getCondominiumContracts(condominium_id))
      }
    } catch (err) {
      console.log(err)
      let {
        data: { errors: from_server },
      } = err.response
      let errors = { from_server: Array.isArray(from_server) ? from_server : [from_server] }
      thunkApi.dispatch(updateErrors({ errors }))
      error(t('store.condominia.create_condominium_error', 'Impossibile aggiungere il condominio'))
      return thunkApi.rejectWithValue()
    }
  }
)
export const editCondominium = createAsyncThunk(
  'condominia/editCondominium',
  async ({ condominium, mountOnMap = false, mng_service = false }, thunkApi) => {
    try {
      await axios({
        url: `${config.SERVER_API_URL}/v1/users/${condominium.user_id}/condominia/${condominium.id}`,
        method: 'PATCH',
        data: { ...condominium },
      })
      if (mng_service === false) {
        thunkApi.dispatch(setLoading(true))
        thunkApi.dispatch(getCondominia())
        if (mountOnMap === false) {
          thunkApi.dispatch(resetCurrentCondominium())
        } else {
          thunkApi.dispatch(toggleShowAddCondominia())
        }
        success(t('store.condominia.update_condomium_success', 'Condominio aggiornato con successo'))
      }
    } catch (err) {
      console.log(err)
      let {
        data: { errors: from_server },
      } = err.response
      let errors = { from_server: Array.isArray(from_server) ? from_server : [from_server] }
      thunkApi.dispatch(updateErrors({ errors }))
      error(t('store.condominia.update_assignment_condominium_error', 'Impossibile aggiornare il condominio'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const createCondominiumContracts = createAsyncThunk(
  'condominia/createCondominiumContracts',
  async ({ condominium_id, contracts, single }, thunkApi) => {
    try {
      let { roles } = thunkApi.getState().usersData ?? []
      let { suppliers } = thunkApi.getState().contracts ?? []
      let user = thunkApi.getState().userInfo || { role_id: getRoleIdByName('building_administrator', roles), id: 0 }
      let { role_id } = user

      thunkApi.dispatch(setLoading(true))
      let services = Object.keys(contracts)
      for (let sid of services) {
        if (contracts[sid].new === true) {
          await axios({
            url: `${config.SERVER_API_URL}/v1/condominia/${condominium_id}/contracts`,
            method: 'POST',
            data: {
              autorenewal: 'true',
              expire_at: contracts[sid].expire_at,
              price: contracts[sid]?.price ?? 0,
              service_option_id: contracts[sid].service_option_id,
              supplier_id: contracts[sid].supplier_id ?? null,
            },
          })
        } else {
          let conhive_supplier = suppliers.find(s => s.name === 'Conhive')
          if (!isAdmin(role_id, roles) && contracts[sid].supplier_id === parseInt(conhive_supplier.id)) {
            console.log('Aggiornamento contratto non permesso')
          } else {
            let contract_id = single === true ? contracts[sid].id : contracts[sid].condominium_contract[condominium_id]
            if (contract_id === null) {
              await axios({
                url: `${config.SERVER_API_URL}/v1/condominia/${condominium_id}/contracts`,
                method: 'POST',
                data: {
                  autorenewal: 'true',
                  expire_at: contracts[sid].expire_at,
                  price: contracts[sid]?.price ?? 0,
                  service_option_id: contracts[sid].service_option_id,
                  supplier_id: contracts[sid].supplier_id ?? null,
                },
              })
            } else {
              await axios({
                url: `${config.SERVER_API_URL}/v1/condominia/${condominium_id}/contracts/${contract_id}`,
                method: 'PATCH',
                data: {
                  autorenewal: 'true',
                  expire_at: contracts[sid].expire_at,
                  price: contracts[sid]?.price ?? 0,
                  service_option_id: contracts[sid].service_option_id,
                  supplier_id: contracts[sid].supplier_id ?? null,
                },
              })
            }
          }
        }
      }

      // thunkApi.dispatch(getCondominium({ condominium_id }))
      thunkApi.dispatch(getCondominiumContracts(condominium_id))
    } catch (err) {
      console.log(err)
      let {
        data: { errors: from_server },
      } = err.response
      let errors = { from_server: Array.isArray(from_server) ? from_server : [from_server] }
      thunkApi.dispatch(updateErrors({ errors }))
      error(t('store.condominia.create_condominium_error', 'Impossibile aggiungere il condominio'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const getCondominium = createAsyncThunk('condominia/getCondominium', async ({ condominium_id }, thunkApi) => {
  try {
    thunkApi.dispatch(setLoading(true))
    let response = await axios({
      url: `${config.SERVER_API_URL}/v1/condominia/${condominium_id}?include=services,user,user.tax_datum,geo_datum,city.province.region`,
      method: 'GET',
    })
    let condominiumData = jsonapiToData(response.data)
    let condominium = { ...condominiumData.data[0] }
    if (condominium.geo_datum) {
      condominium.lat = condominium?.geo_datum?.lat
      condominium.lon = condominium?.geo_datum?.lon
    }
    return condominium
  } catch (err) {
    console.log(err)
    error(t('store.assignments.get_condominia_error', 'Impossibile recuperare il condominio'))
    return thunkApi.rejectWithValue()
  }
})

export const getGeoDataCondominium = createAsyncThunk('condominia/getGeoDataCondominium', async (_, thunkApi) => {
  try {
    thunkApi.dispatch(setLoading(true))
    const state = thunkApi.getState().condominia
    let url = `${config.SERVER_API_URL}/v1/geo_data/condominia?include=associable&models_for_filters=true`

    if (state.filtered_users.length > 0) {
      if (state.filtered_users.includes(-1)) {
        url = url + `&filter[only_unassigned]=true`
      } else {
        url = `${config.SERVER_API_URL}/v1/users/${state.filtered_users[0]}/condominia?include=geo_datum&models_for_filters=true`
      }
    }

    if (state.filtered_users.length > 0) {
    }
    if (state.filter) url = url + `&filter[q]=${encodeURIComponent(state.filter)}`
    if (state.filtered_services.length > 0) url = url + `&filter[with_services]=${state.filtered_services.join(',')}`
    let response = await axios({
      url,
      method: 'GET',
    })
    let geo_data_condominia = jsonapiToData(response.data)
    geo_data_condominia = geo_data_condominia.data.map(g => {
      let { associable, id, geo_datum = {}, ...rest } = g
      if (state.filtered_users > 0) {
        return {
          ...rest,
          ...associable,
          ...geo_datum,
          geoid: geo_datum.id,
          id,
        }
      } else {
        return {
          geoid: id,
          ...rest,
          ...associable,
          ...geo_datum,
        }
      }
    })

    return { geo_data_condominia }
  } catch (err) {
    console.log(err)
    error(t('store.assignments.get_condominia_error', 'Impossibile recuperare il condominio'))
    return thunkApi.rejectWithValue()
  }
})

// funzione per recuperare i contratti passato un condominium_id
export const requestCondominiumContracts = async condominium_id => {
  let response = await axios({
    url: `${config.SERVER_API_URL}/v1/condominia/${condominium_id}/contracts?include=attachments,service`,
    method: 'GET',
  })
  let result = jsonapiToData(response.data)
  return result.data
}

export const getCondominiumContracts = createAsyncThunk(
  'condominia/getCondominiumContracts',
  async (condominium_id, thunkApi) => {
    try {
      let contracts = await requestCondominiumContracts(condominium_id)
      return { contracts }
    } catch (err) {
      console.log(err)
      error(t('store.assignments.get_condominia_contracts_error', 'Impossibile recuperare i servizi del condominio'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const deleteCondominium = createAsyncThunk(
  'condominia/deleteCondominium',
  async ({ user_id, condominium_id }, thunkApi) => {
    try {
      await axios({
        url: `${config.SERVER_API_URL}/v1/users/${user_id}/condominia/${condominium_id}`,
        method: 'DELETE',
      })
      thunkApi.dispatch(setLoading(true))
      thunkApi.dispatch(resetCurrentCondominium())
      thunkApi.dispatch(getCondominia())
      success(t('store.assignments.delete_condominium_success', 'Condominio eliminato con successo'))
    } catch (err) {
      console.log(err)
      error(
        t(
          'store.users.delete_condominium_error',
          'Impossibile eliminare il condominio, sono presenti contratti associati!'
        )
      )
      return thunkApi.rejectWithValue()
    }
  }
)

export const getCondominia = createAsyncThunk('condominia/list', async (current_page, thunkApi) => {
  try {
    let state = thunkApi.getState().condominia
    let { roles } = thunkApi.getState().usersData ?? []
    let user = thunkApi.getState().userInfo || { role_id: getRoleIdByName('building_administrator', roles), id: 0 }
    let { role_id, id: user_id } = user
    let limit = state.limit
    let filter = ''

    let url = `${config.SERVER_API_URL}/v1/users/${user_id}/condominia?include=user,services&models_for_filters=true`
    // Admin o Tecnico
    if (isAdmin(role_id, roles)) {
      url = `${config.SERVER_API_URL}/v1/condominia?include=user,user.tax_datum,services&models_for_filters=true`
    }
    if (state.filtered_users.length > 0) {
      if (state.filtered_users.includes(-1)) {
        filter = filter + `&filter[only_unassigned]=true`
      } else {
        filter = filter + `&filter[with_user]=${state.filtered_users.join(',')}`
      }
    }
    if (state.filter) filter = filter + `&filter[q]=${encodeURIComponent(state.filter)}`
    if (state.filtered_services.length > 0)
      filter = filter + `&filter[with_services]=${state.filtered_services.join(',')}`
    if (state.limit === '-1') {
      current_page = 1
      limit = state.first_total
    }
    let pre_response = await axios({
      url: `${config.SERVER_API_URL}/v1/condominia/only_ids?${filter}`,
      method: 'get',
    })
    let total_ids = pre_response.data.data
    let response = await axios({
      url: `${url}${filter}&sort=id&page=${current_page}&per=${limit}`,
      method: 'get',
    })

    let condominiaData = jsonapiToData(response.data)
    let total = condominiaData?.total ?? 0
    let first_total = state.first_total !== -1 ? state.first_total : total
    let pages = total > limit ? parseInt(total / limit) + (parseInt(total % limit) >= 1 ? 1 : 0) : 1
    let filters = condominiaData?.filters ?? {}
    if (state.filtered_users.length > 0) {
      filters.users = state.filters.users
    }

    return { condominia: condominiaData.data, total, first_total, pages, current_page, filters, total_ids }
  } catch (err) {
    console.log(err)
    error(t('store.condominia.get_condominia_error', 'Impossibile recuperare i condomini'))
    return thunkApi.rejectWithValue()
  }
})

export const condominiaSlice = createSlice({
  name: 'condominia',
  initialState,

  reducers: {
    toggleShowAddCondominia: state => {
      state.show_add_condominia = !state.show_add_condominia
      state.errors = []
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setProgress: (state, action) => {
      state.progress = action.payload
    },
    setPageLimit: (state, action) => {
      state.limit = action.payload
    },
    updateErrors: (state, action) => {
      let { errors } = action.payload
      state.errors = errors
    },
    resetCurrentCondominium: state => {
      state.edit_condominia = initialState.edit_condominia
      state.errors = initialState.errors
      state.show_add_condominia = false
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    },
    setAdministratorCondominia: state => {
      let administrators = state.administrators
      let current_condominia = state.edit_condominia
      let administrator = administrators.find(a => a.id === current_condominia?.user_id?.toString())

      state.edit_condominia = {
        ...current_condominia,
        ...({ administrator: administrator } || { administrator: { name: '' } }),
      }
    },
    filterServices: (state, action) => {
      state.filtered_services = action.payload
    },
    filterUsers: (state, action) => {
      state.filtered_users = action.payload
    },
  },
  extraReducers: {
    [getCondominia.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { total, first_total, condominia, pages, current_page, filters, total_ids } = action.payload
      state.total = total
      state.first_total = first_total
      state.total_ids = total_ids
      state.pages = pages
      state.filters = filters
      state.current_page = current_page
      state.condominia = condominia
      state.loading = false
    },
    [getCondominia.rejected]: state => {
      let { total, condominia, pages, current_page, filters } = initialState
      state.total = total
      state.first_total = -1
      state.total_ids = []
      state.pages = pages
      state.filters = filters
      state.filtered_users = []
      state.filtered_services = []
      state.current_page = current_page
      state.condominia = condominia
      state.loading = false
    },
    [getGeoDataCondominium.pending]: state => {
      state.loading = true
    },
    [getGeoDataCondominium.fulfilled]: (state, action) => {
      let { geo_data_condominia } = action.payload
      state.geo_condominia = geo_data_condominia
      state.loading = false
    },
    [getGeoDataCondominium.rejected]: state => {
      state.geo_condominia = []
      state.loading = false
    },
    [getCondominium.fulfilled]: (state, action) => {
      state.edit_condominia = { ...action.payload }
      state.loading = false
    },
    [getCondominium.rejected]: state => {
      state.edit_condominia = {}
      state.loading = false
    },
    [getCondominiumContracts.fulfilled]: (state, action) => {
      state.edit_condominia = { ...state.edit_condominia, ...action.payload }
      state.loading = false
    },
    [getCondominiumContracts.rejected]: state => {
      state.edit_condominia = { ...state.edit_condominia, contracts: [] }
      state.loading = false
    },
    [getAdministrators.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { administrators } = action.payload
      state.administrators = administrators
      state.loading = false
    },
    [getAdministrators.rejected]: state => {
      state.administrators = initialState.administrators
      state.loading = false
    },
  },
})

export const {
  setLoading,
  setPageLimit,
  updateErrors,
  setAdministratorCondominia,
  toggleShowAddCondominia,
  resetCurrentCondominium,
  setFilter,
  filterServices,
  filterUsers,
  setProgress,
} = condominiaSlice.actions
export default condominiaSlice.reducer
