import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { Button, Modal, Table } from 'react-bootstrap'

import { getMyContracts } from 'store/contracts'
import ModalContract from 'components/contracts/modal-contract'
import { renderExpireAt } from './utils'
const mstp = state => {
  let { my_contracts } = state.contracts
  let { strings: trans } = state.translations
  let user = state.userInfo
  return { my_contracts, trans, user }
}

class ModalContracts extends React.Component {
  state = { show_modal_contract: false, show_modal: true, contract: {} }

  componentDidMount = async () => {
    await this.props.dispatch(getMyContracts({ user_id: this.props.user.id }))
  }

  createContract = ct => {
    let contract = { ...ct }
    contract.user = this.props.user
    contract.condominium = { name: 'Nessun condominio associato', street: '' }
    return contract
  }

  goToContract = ct => () => {
    let contract = this.createContract(ct)
    this.setState({ show_modal_contract: true, show_modal: false, contract })
  }

  onCloseContractModal = () => this.setState({ show_modal_contract: false, show_modal: true })

  render() {
    let { my_contracts, trans } = this.props
    let { show_modal_contract, show_modal } = this.state
    let { services: tServices } = trans?.tables ?? {}

    return (
      <>
        <Modal backdrop="static" centered show={show_modal} onHide={this.props.onCloseModal} size="lg">
          <Modal.Header className="pb-0" closeButton>
            <Modal.Title>
              <h4 className="text-primary p-1">
                <Trans i18nKey="my_contracts.requirements.add_modal.title">Il mio studio</Trans>
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxWidth: '70rem' }}>
            <div className="row">
              <h5>Contratti</h5>
            </div>
            <div className="overflow-auto" style={{ height: '15rem' }}>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Servizio</th>
                    <th>Scadenza</th>
                    <th className="text-center" style={{ width: '2.4rem' }}>
                      Visualizza
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {my_contracts.map(ct => {
                    return (
                      <tr style={{ height: '1rem' }} key={`ct-${ct.id}`}>
                        <td>
                          <i className={`p-2 ${ct.service.icon_classes}`} />
                          {ct.service.description}
                        </td>
                        <td className="p-2">{renderExpireAt(ct.expire_at)}</td>
                        <td className="text-center">
                          <Button className="btn-sm btn-secondary" onClick={this.goToContract(ct)}>
                            Visualizza
                          </Button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onCloseModal}>
              Chiudi
            </Button>
          </Modal.Footer>
        </Modal>
        {show_modal_contract && (
          <ModalContract
            show_modal={this.state.show_modal_contract}
            contract={this.state.contract}
            onCloseModal={this.onCloseContractModal}
            tServices={tServices}
          />
        )}
      </>
    )
  }
}

export default connect(mstp)(ModalContracts)
