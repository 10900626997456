import React from 'react'
import i18next from 'i18next'

import withJWTSession from 'enhancers/jwt_session'
import Topbar from 'components/system_wide/topbar'
import Fadebar from 'components/system_wide/fadebar'
import HexagonGrid from 'components/system_wide/hexagon_grid'

const { t } = i18next

class Home extends React.Component {
  render() {
    return (
      <div>
        <Topbar title={t('pages.home.title', 'Home')} />
        <div className="d-flex vh-94">
          <div className="d-flex" style={{ backgroundColor: 'white' }}>
            <Fadebar />
          </div>
          <HexagonGrid navigate={this.props.router.navigate} />
        </div>
      </div>
    )
  }
}

export default withJWTSession(Home)
