import React from 'react'
import { Modal } from 'react-bootstrap'

class UnsupportedBrowser extends React.PureComponent {
  render() {
    return (
      <Modal show={true} size="lg" centered>
        <Modal.Header>
          <Modal.Title style={{ margin: 'auto' }}>Browser non supportato!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          Stai utilizzando un browser non supportato da Conhive.
          <br /> Ti consigliamo di utilizzare Google Chrome o Mozilla Firefox.
        </Modal.Body>
      </Modal>
    )
  }
}

export default UnsupportedBrowser
