import React from 'react'
import { Table } from 'react-bootstrap'

class ServicesTable extends React.Component {
  static defaultProps = {
    services: [],
    selected_services: [],
  }

  toggleService = id => () => {
    let selected_services = new Set(this.props.selected_services)
    selected_services.has(id) ? selected_services.delete(id) : selected_services.add(id)
    this.props.onChangeService([...selected_services])
  }

  render() {
    let { selected_services, services } = this.props
    return (
      <div>
        Seleziona i servizi:
        <div className="pe-2 mt-2 overflow-auto" style={{ maxHeight: '34rem' }}>
          <Table responsive bordered hover size="sm">
            <tbody>
              {services.map(s => {
                return (
                  <tr
                    key={s.value}
                    style={{ height: '3rem', verticalAlign: 'middle' }}
                    onClick={this.toggleService(s.value)}
                  >
                    <td style={{ textAlign: 'center' }}>
                      {selected_services.includes(s.value) ? (
                        <i className="fas fa-check-square text-primary" />
                      ) : (
                        <i className="far fa-square text-muted" />
                      )}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <i className={`text-muted ${s.icon_classes}`} />
                    </td>
                    <td className="ps-3">{s.label}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </div>
    )
  }
}

export default ServicesTable
