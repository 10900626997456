import React from 'react'

import ForgotPassword from 'components/login/forgot_password'

class ForgotPasswordPage extends React.Component {
  render() {
    return <ForgotPassword />
  }
}

export default ForgotPasswordPage
