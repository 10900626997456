import React from 'react'
import { Trans } from 'react-i18next'
import { Button, Modal, Table } from 'react-bootstrap'
import config from 'config'
import { renderExpireAt } from 'components/system_wide/utils'
import DownloadDocumentButton from 'components/system_wide/download_document_button'

class ModalTransfer extends React.Component {
  static defaultProps = {
    trans: {},
  }

  renderStatus = current_state => {
    let color = 'text-primary'
    if (current_state === 'accepted') color = 'text-success'
    if (current_state === 'rejected' || current_state === 'cancelled') color = 'text-danger'
    let { transfer: trTrans } = this.props.trans?.state_machines ?? []
    let label = trTrans?.[current_state] ?? current_state
    return <div className={`${color}`}>{label.toUpperCase()}</div>
  }

  openDownloadDocument = () => {
    let url = `${config.SERVER_API_URL}/v1/transfers/${this.props.transfer.id}/document`
    window.open(url, '_blank', 'noreferrer')
  }

  render() {
    let { show_modal, transfer } = this.props

    return (
      <Modal backdrop="static" centered show={show_modal} onHide={this.props.onCloseModal} size="lg">
        <Modal.Header className="pb-0" closeButton>
          <Modal.Title>
            <h4 className="text-primary p-1">
              <Trans i18nKey="transfers.modal_title">Trasferimento</Trans>
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column">
          <div className="row">
            <div className="col-4">
              <div className="text-muted modal-title">Id trasferimento</div>
              <div>{transfer?.id}</div>
            </div>
            <div className="col-4">
              <div className="text-muted modal-title">Inserita il</div>
              <div>{renderExpireAt(transfer?.created_at)}</div>
            </div>
            <div className="col-4">
              <div className="text-muted modal-title">Stato</div>
              <div>{this.renderStatus(transfer?.current_state)}</div>
            </div>
          </div>
          <div className="row mt-4 pt-2 border-top">
            <div className="col-6">
              <div className="text-muted modal-title">Precedente amministratore</div>
              <div>
                {transfer?.user?.name} {transfer?.user?.surname}
              </div>
            </div>
            <div className="col-6">
              <div className="text-muted modal-title">Email</div>
              <div>{transfer?.user?.email}</div>
            </div>
          </div>
          <div className="row mt-4 pt-2 border-top">
            <div className="col-6">
              <div className="text-muted modal-title">Nuovo amministratore</div>
              <div>
                {transfer?.new_user?.name} {transfer?.new_user?.surname}
              </div>
            </div>
            <div className="col-6">
              <div className="text-muted modal-title">Email</div>
              <div>{transfer?.new_user?.email}</div>
            </div>
          </div>
          <div className="row mt-4 pt-2 border-top">
            <div className="col-12">
              Verbale d'assemblea: <b>{transfer?.document_name}</b>
              <DownloadDocumentButton
                className="ms-5"
                documentUrl={`${config.SERVER_API_URL}/v1/transfers/${transfer.id}/document`}
                filename={transfer.document_name}
              />
            </div>
          </div>
          <div className="row border-top mt-3 pt-4">
            <h5>Condomini in trasferimento</h5>
          </div>
          <div className="overflow-auto" style={{ height: '15rem' }}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Indirizzo</th>
                  <th>CF</th>
                  <th>Visualizza</th>
                </tr>
              </thead>
              <tbody>
                {transfer?.condominia?.map(cd => (
                  <tr style={{ height: '1rem' }} key={`cd-${cd.id}`}>
                    <td>{cd.name}</td>
                    <td>{cd.street}</td>
                    <td>{cd.fiscal_code}</td>
                    <td>
                      <Button className="ms-2 btn-sm btn-secondary" onClick={this.props.onOpenShowCondominium(cd.id)}>
                        <Trans i18nKey="common.open_condominum">Visualizza</Trans>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onCloseModal} variant="secondary">
            <Trans i18nKey="common.close_button">Chiudi</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ModalTransfer
