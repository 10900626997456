import React from 'react'
import i18next from 'i18next'
import { Button } from 'react-bootstrap'
import axios from 'lib/axios'
import config from 'config'

import 'scss/contracts.css'

const { t } = i18next

export const downloadFile = (contractId, attachmentsId, fileName) => {
  if (!contractId) return null

  axios({
    url: `${config.SERVER_API_URL}/v1/contracts/${contractId}/attachments/${attachmentsId}`,
    method: 'GET',
    responseType: 'blob',
  }).then(response => {
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data)

    // create "a" HTML element with href to file & click
    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', fileName) //or any other extension
    document.body.appendChild(link)
    link.click()

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  })
}

class DownloadContractButton extends React.Component {
  static defaultProps = {
    contractId: null,
    attachmentsId: null,
    fileName: '',
    label: t('common.download_button', 'Scarica'),
    stylesClass: '',
  }

  downloadFile = () => {
    let { contractId, attachmentsId, fileName } = this.props
    if (!contractId) return null

    axios({
      url: `${config.SERVER_API_URL}/v1/contracts/${contractId}/attachments/${attachmentsId}`,
      method: 'GET',
      responseType: 'blob',
    }).then(response => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', fileName) //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
  }
  render() {
    return (
      <Button className={`btn-sm btn-secondary ${this.props.stylesClass}`} onClick={this.downloadFile}>
        {this.props.label}
      </Button>
    )
  }
}
export default DownloadContractButton
