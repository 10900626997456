import React from 'react'
import { connect } from 'react-redux'
import { Button, Modal, Form, Alert, Popover, OverlayTrigger } from 'react-bootstrap'
import i18next from 'i18next'
import { Trans } from 'react-i18next'
import isNumeric from 'validator/lib/isNumeric'

import {
  getServices,
  getServicePrices,
  getPaymentMethods,
  toggleShowAddServices,
  createService,
  editService,
} from 'store/assignments/services'
import StyledSelect from 'components/system_wide/styled_select'

const { t } = i18next

const mstp = state => {
  let { current_assignment } = state.assignments.data
  let { services, payment_methods, show_add_services, errors, current_service, edit_service } =
    state.assignments.services
  return { services, payment_methods, show_add_services, current_assignment, errors, current_service, edit_service }
}

const initialState = {
  quantity: '',
  price: '',
  service_option: '',
  supplier: '',
  real_estate_units: false,
  fee: '',
  service_id: '',
  selected_service: '',
  selected_payment: '',
}
class AddAssignmentServices extends React.Component {
  static defaultProps = { disabled: false }
  state = { ...initialState, edit_mode: false }

  async componentDidMount() {
    await this.props.dispatch(getServices())
    await this.props.dispatch(getPaymentMethods())
  }

  componentDidUpdate = async prevProps => {
    if (prevProps.show_add_services !== this.props.show_add_services) {
      let edit_mode = Object.keys(this.props.edit_service).length !== 0
      let { edit_service, services, payment_methods } = this.props
      let edit_element = { ...edit_service }
      let service_option = ''
      let selected_payment = ''
      if (edit_mode === true) {
        let service = services.find(s => parseInt(s.value) === parseInt(edit_service.selected_service.id))
        edit_element.selected_service = service
        service_option = edit_element.selected_service.service_options.find(
          so => parseInt(so.value) === parseInt(edit_element.service_options[0])
        )
        selected_payment = payment_methods.find(p => parseInt(p.value) === edit_element.selected_payment.id)
      }
      this.setState({
        ...initialState,
        edit_mode,
        ...(edit_mode === true ? edit_element : {}),
        service_option,
        selected_payment,
      })
    }
  }

  onOpenModal = () => {
    this.props.dispatch(toggleShowAddServices())
  }
  onCloseModal = () => {
    this.props.dispatch(toggleShowAddServices())
  }

  isFormValid = () => {
    let { quantity, price, fee, selected_service } = this.state
    return quantity !== '' && price !== '' && fee !== '' && selected_service !== ''
  }

  handleChange = ({ target: { name, value } }) => {
    if (isNumeric(value) || value === '') this.setState({ [name]: value })
  }
  handleSelectTypeChange = option => {
    this.setState({ price: option })
  }
  handleSelectServiceOptionChange = async option => {
    let { selected_service } = this.state
    let price_promotion = { amount: '' }
    if (option) {
      await this.props.dispatch(
        getServicePrices({ service_id: selected_service.value, service_option_id: option.value })
      )
      price_promotion = this.props.current_service.prices.filter(p => p.typology === 'promotion')[0]
    }
    let price = price_promotion?.amount
    this.setState({ service_option: option ? option : '', price })
  }
  handleSelectSuppliersChange = async option => {
    let { selected_service, service_option } = this.state
    let price_promotion = { amount: '' }
    if (option) {
      await this.props.dispatch(
        getServicePrices({
          service_id: selected_service.value,
          supplier_id: option.value,
          service_option_id: service_option?.value,
        })
      )
      price_promotion = this.props.current_service.prices.filter(p => p.typology === 'promotion')[0]
    }
    let price = price_promotion?.amount
    this.setState({ supplier: option ? option : '', price })
  }

  handleIsItChecked = e => {
    this.setState({ [e.target.name]: e.target.checked })
  }

  handleServiceChange = option => {
    this.setState({ selected_service: option, price: '', service_option: '', supplier: '' })
  }
  handlePaymentMethodChange = option => {
    this.setState({ selected_payment: option })
  }

  handleConfirm = async () => {
    let { current_assignment } = this.props
    let { selected_service, selected_payment, edit_mode, ...service } = this.state
    service['service_id'] = selected_service.value
    service['payment_method_id'] = selected_payment.value
    if (edit_mode) {
      this.props.dispatch(editService({ assignment_id: current_assignment.id, service }))
    } else {
      this.props.dispatch(createService({ assignment_id: current_assignment.id, service }))
    }
  }

  render() {
    let {
      quantity,
      price,
      fee,
      real_estate_units,
      selected_service,
      selected_payment,
      service_option,
      supplier,
      edit_mode,
    } = this.state
    let { services, payment_methods, show_add_services, errors, current_service } = this.props
    let popover = (
      <Popover id="popover-basic">
        <Popover.Header as="h3">Costi selezionabili</Popover.Header>
        <Popover.Body>
          <ul>
            {this.props.current_service.prices.map(p => (
              <li key={p.id}>
                {p.typology}: <b>{p.amount}</b>
              </li>
            ))}
          </ul>
        </Popover.Body>
      </Popover>
    )

    return (
      <>
        <div
          className="d-flex pt-1 pb-1 align-items-center"
          onClick={!this.props.disabled && this.onOpenModal}
          role="button"
        >
          <Trans i18nKey="assignments.add_modal.topbar_button">Servizi</Trans>
          {!this.props.disabled && <i className="fas fa-plus-circle ps-2 pb-1" />}
        </div>
        <Modal size="lg" backdrop="static" centered show={show_add_services} onHide={this.onCloseModal}>
          <Modal.Header className="pb-0">
            <Modal.Title>
              <h4 className="text-primary p-1">
                {edit_mode === true ? (
                  <Trans i18nKey="assignments.services.title_edit">Modifica Servizio</Trans>
                ) : (
                  <Trans i18nKey="assignments.services.title">Aggiungi Servizio</Trans>
                )}
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column">
            <div>
              {errors?.from_server?.length > 0 && (
                <Alert variant="danger">
                  <Alert.Heading>Error:</Alert.Heading>
                  <ul>
                    {errors.from_server.map((err, i) => (
                      <li key={`err-${i}`}>{err.detail}</li>
                    ))}
                  </ul>
                </Alert>
              )}
              <div className="row mb-3">
                <div className="col">
                  <Form.Label>
                    <Trans i18nKey="assignments.services.search_service">Cerca Servizio</Trans>
                  </Form.Label>
                  <StyledSelect
                    placeholder={t('assignments.services.select_service', 'Seleziona il servizio..')}
                    value={selected_service}
                    onChange={this.handleServiceChange}
                    options={services}
                  />
                </div>
              </div>
              <div className="row border-top mt-3">
                <div className="col">
                  <Form.Group className="mt-3">
                    <Form.Label>
                      <Trans i18nKey="assignments.services.price">Opzione</Trans>
                    </Form.Label>
                    <StyledSelect
                      isClearable
                      placeholder={
                        selected_service?.service_options?.length > 0
                          ? t('assignments.services.select_service_option', "Seleziona l'opzione..")
                          : t('assignments.services.no_select_service_option', 'Non ci sono opzioni da selezionare')
                      }
                      value={service_option}
                      onChange={this.handleSelectServiceOptionChange}
                      options={selected_service?.service_options}
                      disabled={!selected_service?.service_options?.length > 0}
                    />
                    <Form.Control.Feedback type="invalid">''</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mt-3">
                    <Form.Label>
                      <Trans i18nKey="assignments.services.price">Fornitore</Trans>
                    </Form.Label>
                    <StyledSelect
                      isClearable
                      placeholder={
                        selected_service?.suppliers?.length > 0
                          ? t('assignments.services.select_suppliers', 'Seleziona il fornitore..')
                          : t('assignments.services.no_select_suppliers', 'Non ci sono fornitori da selezionare')
                      }
                      value={supplier}
                      onChange={this.handleSelectSuppliersChange}
                      options={selected_service?.suppliers}
                      disabled={!selected_service?.suppliers?.length > 0}
                    />
                    <Form.Control.Feedback type="invalid">''</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="row">
                  <div className="col">
                    <Form.Group className="mt-3">
                      <Form.Label>
                        <Trans i18nKey="assignments.services.price">Costo</Trans>
                      </Form.Label>
                      {selected_service?.selectable_price !== true && (
                        <div className="d-flex justify-content-between">
                          <Form.Control
                            name="price"
                            placeholder={t('assignments.services.price', 'Costo')}
                            value={price}
                            onChange={this.handleChange}
                            isInvalid=""
                            disabled={!selected_service}
                          />
                          {selected_service && service_option && (
                            <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                              <i className="fas fa-info-circle p-2 text-warning" />
                            </OverlayTrigger>
                          )}
                        </div>
                      )}
                      {selected_service?.selectable_price === true && (
                        <StyledSelect
                          isClearable
                          placeholder={t('assignments.services.select_service_type', 'Seleziona il tipo..')}
                          value={price}
                          onChange={this.handleSelectTypeChange}
                          options={current_service?.prices.map(opt => ({ value: opt.amount, label: opt.amount }))}
                          disabled={!selected_service || (selected_service?.suppliers?.length > 0 && supplier === '')}
                        />
                      )}
                      <Form.Control.Feedback type="invalid">''</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col"></div>
                </div>
              </div>
              <div className="row mt-4 border-top">
                <div className="col">
                  <Form.Group className="mt-3">
                    <Form.Label>
                      <Trans i18nKey="assignments.services.quantity">Quantità</Trans>
                    </Form.Label>
                    <Form.Control
                      name="quantity"
                      placeholder={t('assignments.services.quantity', 'Quantità')}
                      value={quantity}
                      onChange={this.handleChange}
                      isInvalid=""
                    />
                    <Form.Control.Feedback type="invalid">''</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mt-3">
                    <Form.Label>
                      <Trans i18nKey="assignments.services.commission">Provvigione</Trans>
                    </Form.Label>
                    <Form.Control
                      name="fee"
                      placeholder={t('assignments.services.commission', 'Provvigione')}
                      value={fee}
                      onChange={this.handleChange}
                      isInvalid=""
                    />
                    <Form.Control.Feedback type="invalid">''</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="switch"
                      id="real_estate_units"
                      label="Suddivisione unità immobiliari"
                      name="real_estate_units"
                      checked={real_estate_units}
                      onChange={this.handleIsItChecked}
                      disabled={selected_service.selectable_price === true}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <Form.Label>
                    <Trans i18nKey="assignments.services.payment_method">Metodo di pagamento</Trans>
                  </Form.Label>
                  <StyledSelect
                    placeholder={t('assignments.services.select_payment_method', 'Seleziona il metodo di pagamento..')}
                    value={selected_payment}
                    onChange={this.handlePaymentMethodChange}
                    options={payment_methods}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCloseModal} variant="secondary">
              <Trans i18nKey="common.cancel_button">Annulla</Trans>
            </Button>
            {edit_mode === true ? (
              <Button onClick={this.handleConfirm} variant="primary" disabled={!this.isFormValid()}>
                <Trans i18nKey="assignments.add_modal.edit_button">Modifica</Trans>
              </Button>
            ) : (
              <Button onClick={this.handleConfirm} variant="primary" disabled={!this.isFormValid()}>
                <Trans i18nKey="assignments.add_modal.confirm_button">Aggiungi</Trans>
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default connect(mstp)(AddAssignmentServices)
