import React from 'react'
import { Trans } from 'react-i18next'
import i18next from 'i18next'
import { Modal, Button } from 'react-bootstrap'

const { t } = i18next

// NOTE: this is a dirty workaround to export internal component logic to outside world!!
export let showConfirmDialog = () => null

const initialState = {
  show: false,
  title: '',
  message: '',
  confirmButtonVariant: 'primary',
  confirmButtonText: t('button_confirm', 'Conferma'),
  onConfirm: () => null,
  onCancel: () => null,
  hideCancelButton: false,
}

export const ConfirmDialog = () => {
  let [state, setState] = React.useState(initialState)

  let [confirmed, setConfirmed] = React.useState(false)

  showConfirmDialog = (
    title,
    message,
    onConfirm = () => null,
    onCancel = () => null,
    confirmButtonVariant = 'primary',
    confirmButtonText = t('button_confirm', 'Conferma'),
    hideCancelButton = false
  ) =>
    setState({
      show: true,
      title,
      message,
      onConfirm,
      onCancel,
      confirmButtonVariant,
      confirmButtonText,
      hideCancelButton,
    })
  let { show, title, message, onConfirm, onCancel, confirmButtonVariant, confirmButtonText, hideCancelButton } = state

  const onCancelModal = async () => {
    await onCancel()
    setState(initialState)
  }

  const onCloseModal = async () => {
    setState(initialState)
  }
  const onConfirmModal = async () => {
    setConfirmed(true)
    await new Promise(resolve => setTimeout(resolve, 1))
    await onConfirm()
    setConfirmed(false)
    onCloseModal()
  }

  return (
    <Modal backdrop="static" centered show={show} onExited={onCloseModal}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{message}</div>
      </Modal.Body>
      <Modal.Footer>
        {hideCancelButton === false && (
          <Button onClick={onCancelModal} disabled={confirmed} variant="secondary">
            <Trans i18nKey="button_cancel">Cancella</Trans>
          </Button>
        )}
        <Button onClick={onConfirmModal} disabled={confirmed} variant={confirmButtonVariant}>
          {confirmed && (
            <div
              className="spinner-border spinner-border-sm me-2"
              role="status"
              style={{ color: 'var(--contrast-primary)' }}>
              <span className="sr-only">Loading...</span>
            </div>
          )}
          {confirmButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmDialog
