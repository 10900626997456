import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'lib/axios'
import i18next from 'i18next'

import config from 'config'
import { error, success } from 'components/system_wide/notification'
import { setCurrentAssignment } from 'store/assignments'
import { redirectLocation } from 'store/common'
import { updateErrors as updateUserErrors } from 'store/users'

const { t } = i18next

export const initialState = {
  roles: [],
  users: [],
  errors: [],
  loading: false,
  list: [],
}

export const getRoles = createAsyncThunk('assignments/getRoles', async (_, thunkApi) => {
  try {
    let url = `${config.SERVER_API_URL}/v1/affiliate_roles`
    let response = await axios({
      url,
      method: 'GET',
    })
    let roles = response.data.data.map(r => {
      return { id: r.id, ...r.attributes, value: r.id, label: r.attributes.name }
    })
    return { roles }
  } catch (err) {
    console.log(err)
    error(t('store.assignments.get_affiliate_roles_error', 'Impossibile recuperare i ruoli affiliato'))
    return thunkApi.rejectWithValue()
  }
})

export const getUsersForRole = createAsyncThunk('assignments/getUsersForRole', async (role_id, thunkApi) => {
  try {
    let url = `${config.SERVER_API_URL}/v1/affiliate_roles/${role_id}/users`
    let response = await axios({
      url,
      method: 'GET',
    })
    let users = response.data.data.map(u => {
      let { name, surname } = u.attributes
      return { value: u.id, label: `${surname} ${name}` }
    })
    return { users }
  } catch (err) {
    console.log(err)
    error(t('store.affiliates.get_affiliate_role_user_error', 'Impossibile recuperare gli utenti'))
    return thunkApi.rejectWithValue()
  }
})

export const getAffiliatesRoles = createAsyncThunk(
  'assignments/getAffiliatesRoles',
  async (assignment_id, thunkApi) => {
    try {
      let url = `${config.SERVER_API_URL}/v1/assignments/${assignment_id}/affiliates`
      let response = await axios({
        url,
        method: 'GET',
      })
      let affiliates = response.data.data.map(u => {
        return { id: u.id, ...u.attributes }
      })
      return { affiliates }
    } catch (err) {
      console.log(err)
      error(t('store.affiliates.get_affiliate_roles_error', 'Impossibile recuperare gli affiliati'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const addAffiliate = createAsyncThunk(
  'assignments/addAffiliate',
  async ({ assignment_id, user_id, affiliate_role_id }, thunkApi) => {
    try {
      let url = `${config.SERVER_API_URL}/v1/assignments/${assignment_id}/affiliates`
      await axios({
        url,
        method: 'POST',
        data: { user_id, affiliate_role_id },
      })
      thunkApi.dispatch(getAffiliatesRoles(assignment_id))
    } catch (err) {
      console.log(err)
      error(t('store.affiliates.add_affiliate_error', 'Impossibile aggiungere affiliato'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const deleteAffiliate = createAsyncThunk(
  'assignments/deleteAffiliate',
  async ({ assignment_id, affiliate_id }, thunkApi) => {
    try {
      await axios({
        url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}/affiliates/${affiliate_id}`,
        method: 'DELETE',
      })
      thunkApi.dispatch(getAffiliatesRoles(assignment_id))
      success(t('store.affiliates.delete_affiliate_success', 'Affiliato eliminato con successo'))
    } catch (err) {
      console.log(err)
      error(t('store.affiliates.delete_affiliate_error', 'Impossibile eliminare affiliato'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const confirmAffiliates = createAsyncThunk('assignments/confirmAffiliates', async (assignment_id, thunkApi) => {
  try {
    let state = thunkApi.getState().assignments.affiliates
    let required_roles = state.roles
      .filter(sr => ['key_account', 'sales_manager', 'team_manager'].includes(sr.codename))
      .map(s => s.id)
    let satisfied_roles = state.list.reduce(
      (acc, r) => acc || required_roles.includes(r.affiliate_role_id.toString()),
      false
    )

    if (!satisfied_roles) {
      error(t('store.assignments.confirm_affiliates_error', 'ATTENZIONE: Inserire almeno uno dei ruoli richiesti!'))
      return thunkApi.rejectWithValue()
    }

    await axios({
      url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}`,
      method: 'PUT',
    })
    thunkApi.dispatch(setCurrentAssignment(assignment_id))
    thunkApi.dispatch(redirectLocation(`/assignments/${assignment_id}/condominia`))
    success(t('store.assignments.confirm_affiliates_success', 'Affiliati confermati con successo'))
  } catch (err) {
    console.log(err)
    error(t('store.assignments.change_state_assignment_error', 'Impossibile aggiornare lo stato'))
    return thunkApi.rejectWithValue()
  }
})

export const affiliatesSlice = createSlice({
  name: 'assignments/affiliates',
  initialState,

  reducers: {
    updateErrors: (state, action) => {
      state.errors = action.payload.errors
    },
  },
  extraReducers: {
    [getRoles.fulfilled]: (state, action) => {
      state.roles = action.payload.roles
    },
    [getRoles.rejected]: state => {
      state.roles = initialState.roles
    },
    [getAffiliatesRoles.fulfilled]: (state, action) => {
      state.list = action.payload.affiliates
    },
    [getAffiliatesRoles.rejected]: state => {
      state.list = initialState.list
    },
    [getUsersForRole.fulfilled]: (state, action) => {
      state.users = action.payload.users
    },
    [getUsersForRole.rejected]: state => {
      state.users = initialState.users
    },
    [getUsersForRole.rejected]: state => {
      state.list = initialState.list
    },
  },
})

export const { updateErrors } = affiliatesSlice.actions
export default affiliatesSlice.reducer
