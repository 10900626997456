import React from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Form, Button, Modal } from 'react-bootstrap'
import i18next from 'i18next'

const { t } = i18next

class ModalTicket extends React.Component {
  static defaultProps = {
    show: false,
    onCloseModal: () => {},
    onConfirm: () => {},
  }
  state = { title: '', message: '', show_success_message: false }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  render() {
    let { show } = this.props
    let { title, message, show_success_message } = this.state

    return (
      <>
        <Modal backdrop="static" centered show={show} onHide={this.props.onCloseModal} size="lg">
          <Modal.Header className="pb-0" closeButton>
            <Modal.Title>
              <h4 className="text-primary p-1">
                <Trans i18nKey="ticket.modal.title">Richiedi assistenza</Trans>
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxWidth: '70rem' }}>
            <div className="row">
              <div className="col-12">
                <Trans i18nKey="ticket.modal.msg">
                  Puoi richiedere assistenza scrivendo una mail direttamente a{' '}
                  <ButtonMailto
                    button={false}
                    label="support@conhive.it"
                    mailto="support@conhive.it"
                    subject={title}
                    body={message}
                    handleClick={this.props.onConfirm}
                  />{' '}
                  oppure compilando questo form sottostante penseremo noi ad aprire il tuo client di posta. La
                  conversazione con l'assistenza e il relativo ticket verranno gestiti attraverso il tuo account di
                  posta e potrai proseguire la conversazione rispondendo semplicemente alle email relative al ticket.
                </Trans>
              </div>
              <div className="col-12">
                <Form.Group className="mt-3 mb-3">
                  <Form.Label>
                    <Trans i18nKey="ticket.modal_form.title">Titolo</Trans>
                  </Form.Label>
                  <span> *</span>
                  <Form.Control
                    name="title"
                    placeholder={t('ticket.modal_form.title', 'Titolo')}
                    value={title}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-12">
                <Form.Group className="mt-3 mb-3">
                  <Form.Label>Messaggio</Form.Label>
                  <span> *</span>
                  <Form.Control name="message" as="textarea" rows={3} value={message} onChange={this.handleChange} />
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onCloseModal} className="me-auto">
              <Trans i18nKey="common.cancel_button">Annulla</Trans>
            </Button>
            <ButtonMailto
              disabled={title === '' || message === '' || show_success_message === true}
              label="Invia con il client di posta"
              mailto="support@conhive.it"
              subject={title}
              body={message}
              handleClick={this.props.onConfirm}
            />
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default ModalTicket

const ButtonMailto = ({ mailto, subject = '', body = '', label, disabled, handleClick, button = true }) => {
  return (
    <>
      <style jsx="true">
        {`
          .disable-link {
            pointer-events: none;
          }
        `}
      </style>
      <Link
        style={disabled ? { pointerEvents: 'none' } : {}}
        className={button !== false ? `btn btn-primary ${disabled ? 'disabled' : ''}` : ''}
        to="#"
        onClick={e => {
          window.location.href =
            'mailto:' +
            encodeURIComponent(mailto) +
            '?subject=' +
            encodeURIComponent(subject) +
            '&body=' +
            encodeURIComponent(body)
          handleClick()
          e.preventDefault()
        }}>
        {label}
      </Link>
    </>
  )
}
