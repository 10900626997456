import React from 'react'
import { Trans } from 'react-i18next'
import { Modal, Button } from 'react-bootstrap'

class ReqResetPasswordModal extends React.Component {
  render() {
    let msg =
      this.props.user_profile === true ? (
        <Trans
          i18nKey="users.list.reset_password.modal_msg_user_profile"
          defaults="Per procedere al cambio della tua password click su Cambia password. Riceverai una mail con il link per procedere alla modifica della password."
        />
      ) : (
        <Trans
          i18nKey="users.list.reset_password.modal_msg"
          values={{ user_name: this.props.user_to_reset_password }}
          defaults="Per procedere al reset della password per l'utente <1>{{ user_name }}</1> click su Cambia password"
          components={{ 1: <b /> }}
        />
      )
    return (
      <Modal
        className="reset-password-modal"
        backdrop="static"
        centered
        show={this.props.show}
        onHide={this.props.onCancelResetPassword}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans i18nKey="users.list.reset_password.modal_title">Cambia la password</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{msg}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onCancelResetPassword} variant="secondary" className="me-auto">
            <Trans i18nKey="common.cancel_button">Annulla</Trans>
          </Button>
          <Button onClick={this.props.onConfirmResetPassword} variant="success" disabled={false}>
            <Trans i18nKey="common.reset_password_button">Cambia password</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ReqResetPasswordModal
