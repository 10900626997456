import React from 'react'
import i18next from 'i18next'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { Button, OverlayTrigger, Dropdown, Tooltip, Form } from 'react-bootstrap'

import 'scss/assignments.css'
import CondominiaListTable from './condominia_list_table'
import CondominiaListMaps from './condominia_list_maps'
import ShowCondominium from './show_condominium'
import CheckboxFilter from 'components/system_wide/checkbox_filter'
import StyledSelect from 'components/system_wide/styled_select'
import SearchBox from 'components/system_wide/search_box'
import AddCondominia from 'components/condominia/add_condominia_modal'
import { getServices } from 'store/assignments/services'
import {
  getCondominia,
  setLoading,
  setFilter,
  getGeoDataCondominium,
  resetCurrentCondominium,
  getCondominium,
  getCondominiumContracts,
  filterServices,
  filterUsers,
  setPageLimit,
  createCondominiumContracts,
  setProgress,
} from 'store/condominia'
import ManageServiceModal from './manage_service_modal'
import { isAdmin as _isAdmin } from 'lib/utils'
import WithCapabilities from 'enhancers/with_capabilities'

const { t } = i18next

const mstp = state => {
  let {
    condominia,
    total,
    loading,
    current_page,
    pages,
    edit_condominia,
    filtered_services,
    filtered_users,
    filters,
    total_ids,
  } = state.condominia
  let { services } = state.assignments.services
  let { id: user_id, role_id } = state.userInfo
  let { roles } = state.usersData
  let { strings: trans } = state.translations
  let tServices = trans?.tables?.services ?? {}
  let isAdmin = _isAdmin(role_id, roles)
  return {
    services,
    condominia,
    edit_condominia,
    filtered_services,
    filtered_users,
    total,
    total_ids,
    user_id,
    trans,
    tServices,
    loading,
    current_page,
    pages,
    filters,
    isAdmin,
  }
}
class CondominiaList extends React.Component {
  static defaultProps = {
    condominia: [],
    total: 0,
    services: [],
    current_page: 1,
    pages: 1,
  }

  componentDidMount = async () => {
    await this.props.dispatch(resetCurrentCondominium())
    await this.props.dispatch(getServices({ module: 'Condominium' }))
    await this.props.dispatch(setLoading())
    await this.props.dispatch(setFilter(''))
    await this.props.dispatch(filterServices([]))
    await this.props.dispatch(getCondominia(this.props.current_page))
  }

  state = {
    show_maps: false,
    show_modal: false,
    show_select_services_modal: false,
    show_add_services_modal: false,
    fixed: false,
    reset_filter: false,
    scrollX: 0,
    filter: '',
    type_selected: 'single',
    selected_condominia: [],
  }

  handleShowMaps = async () => {
    this.setState({ show_maps: !this.state.show_maps })
    await this.props.dispatch(resetCurrentCondominium())
  }

  refreshList = async () => {
    await this.props.dispatch(setLoading())
    if (this.state.show_maps === true) {
      await this.props.dispatch(getGeoDataCondominium())
    } else {
      await this.props.dispatch(getCondominia(this.props.current_page))
    }
  }

  firstPage = async () => {
    await this.props.dispatch(setLoading())
    await this.props.dispatch(getCondominia(1))
  }
  nextPage = async () => {
    let { current_page, pages } = this.props
    if (current_page < pages) {
      await this.props.dispatch(setLoading())
      await this.props.dispatch(getCondominia(this.props.current_page + 1))
    }
  }

  prevPage = async () => {
    let { current_page } = this.props
    if (current_page > 1) {
      await this.props.dispatch(setLoading())
      await this.props.dispatch(getCondominia(this.props.current_page - 1))
    }
  }
  lastPage = async () => {
    await this.props.dispatch(setLoading())
    await this.props.dispatch(getCondominia(this.props.pages))
  }

  refreshData = async () => {
    if (this.state.show_maps === true) {
      await this.props.dispatch(resetCurrentCondominium())
      await this.props.dispatch(getGeoDataCondominium())
    } else {
      await this.props.dispatch(getCondominia(1))
    }
  }

  onFilterChange = async value => {
    if (this.props.filter !== value && value === '') {
      this.setState({ reset_filter: true })
    } else {
      this.setState({ reset_filter: false })
    }
    await this.props.dispatch(setFilter(value))
    if (value === '') this.refreshData()
  }

  onCloseModal = async () => {
    await this.props.dispatch(resetCurrentCondominium())
    this.setState({ show_modal: false, show_add_services_modal: false })
  }
  showCondominium = async c_id => {
    await this.props.dispatch(getCondominium({ condominium_id: c_id }))
    await this.props.dispatch(getCondominiumContracts(c_id))
    this.setState({ show_modal: true })
  }

  onFilterServices = async services => {
    this.props.dispatch(filterServices(services))
    await this.props.dispatch(setLoading())
  }
  onFilterUsers = async user => {
    if (user) this.props.dispatch(filterUsers([user.id]))
    else this.props.dispatch(filterUsers([]))
  }
  onChangeFilterUnassigned = async () => {
    let { filtered_users } = this.props
    if (!filtered_users.includes(-1)) this.props.dispatch(filterUsers([-1]))
    else this.props.dispatch(filterUsers([]))
  }

  handleSelection = () => {
    let { type_selected, selected_condominia } = this.state
    this.setState({
      type_selected: type_selected === 'single' ? 'multiple' : 'single',
      selected_condominia: type_selected === 'single' ? [] : selected_condominia,
    })
  }
  bulk_actions = {
    add_services: t('actions_dropdown.add_services', 'Associa servizi'),
  }
  handleAction = action => () => {
    this.setState({ [`show_${action}_modal`]: true })
  }

  toggleCondominium = ({ target: { value } }) => {
    let { selected_condominia } = this.state
    if (selected_condominia.includes(value)) {
      this.setState({ selected_condominia: selected_condominia.filter(e => e !== value) })
    } else {
      this.setState({ selected_condominia: [...selected_condominia, value] })
    }
  }

  toggleAllCondominia = () => {
    let { total_ids } = this.props
    let { selected_condominia } = this.state
    let all_selected = total_ids.every(u => selected_condominia.includes(u.toString()))
    let current_condominia = []

    if (all_selected) {
      current_condominia = []
    } else {
      current_condominia = new Set([...selected_condominia, ...total_ids.map(e => e.toString())])
    }
    this.setState({ selected_condominia: [...current_condominia] })
  }

  changePageLimit = async e => {
    await this.props.dispatch(setPageLimit(e.target.value))
    await this.props.dispatch(setLoading())
    await this.props.dispatch(getCondominia(this.props.current_page))
  }

  renderPagination = () => {
    let { pages, current_page } = this.props
    let disabled = pages === 1 ? 'disabled-pointer' : ''
    return (
      <>
        <Button onClick={this.firstPage} className={`me-1 ${disabled}`} size="sm">
          <i className={`fa fa-angle-double-left m-auto`} />
        </Button>
        <Button onClick={this.prevPage} className={`me-2 ${disabled}`} size="sm">
          <i className={`fa fa-chevron-left m-auto`} />
        </Button>
        {current_page}/{pages}
        <Button onClick={this.nextPage} className={`ms-2 ${disabled}`} size="sm">
          <i className={`fa fa-chevron-right m-auto`} />
        </Button>
        <Button onClick={this.lastPage} className={`ms-1 ${disabled}`} size="sm">
          <i className={`fa fa-angle-double-right m-auto`} />
        </Button>
      </>
    )
  }

  assignServices = async contracts => {
    let { selected_condominia } = this.state
    let { condominia } = this.props
    let step = 100 % selected_condominia.length
    let progress = 1
    this.props.dispatch(setProgress(progress))
    for (let id of selected_condominia) {
      let condominium = condominia.find(c => c.id === id)
      let new_contracts = {}
      let services = Object.keys(contracts)
      for (let sid of services) {
        if (!(condominium.services?.map(s => s.id) ?? []).includes(sid)) {
          new_contracts = { ...new_contracts, [sid]: contracts[sid] }
        }
      }
      if (Object.keys(new_contracts).length > 0) {
        await this.props.dispatch(createCondominiumContracts({ condominium_id: id, contracts: new_contracts }))
      }
      progress = progress + step
      this.props.dispatch(setProgress(progress))
    }
    this.props.dispatch(setProgress(100))
    this.onCloseModal()
    this.props.dispatch(setProgress(-1))
    await this.props.dispatch(getCondominia(this.props.current_page))
    this.setState({ type_selected: 'single', selected_condominia: [] })
  }

  handleSearch = () => this.refreshData()

  render() {
    let { total, edit_condominia, tServices, services, filtered_services, filtered_users, filters, isAdmin } =
      this.props
    let { show_maps, show_modal, show_add_services_modal, reset_filter, type_selected, selected_condominia } =
      this.state

    let servicesOptions = services
      .map(v => ({ ...v, id: v.value, name: tServices?.[v.codename] ?? v.label }))
      .sort((a, b) => (a.name < b.name ? -1 : 1))
      .map(v => ({
        id: v.id,
        name: (
          <div className="d-flex">
            <div className="text-center pe-1">
              <i className={`${v.icon_classes}`} style={{ width: '1.6rem' }} />
            </div>
            <div>{tServices?.[v.codename] ?? v.label}</div>
          </div>
        ),
      }))

    let users = filters?.users ?? []
    let usersOpts = users.map(c => ({
      id: c.id,
      value: c.id,
      label: c.business_name,
    }))

    return (
      <div className="d-flex flex-column w-100 overflow-auto m-2 ms-3 me-3 z-1">
        <div className="d-flex mb-1 mt-1">
          <AddCondominia mountOnMap={show_maps} />
          <div className="d-flex flex-wrap gap-2 mb-2 w-100">
            <div className="me-3 ms-auto d-flex align-items-center">
              <WithCapabilities isAdmin={true}>
                <Form.Check
                  type="switch"
                  id="deactivated-switch"
                  label="Mostra ceduti"
                  value={filtered_users.includes(-1)}
                  onChange={this.onChangeFilterUnassigned}
                />
              </WithCapabilities>
            </div>
            <CheckboxFilter
              className="me-2"
              label={t('contracts.filtered_services', 'Servizi')}
              items={servicesOptions}
              filteredItems={filtered_services}
              onChangeFilter={this.onFilterServices}
            />
            <WithCapabilities isAdmin={true}>
              <StyledSelect
                className="me-2 filter-condominium"
                styles={{ minWidth: '13rem', maxWidth: '20rem' }}
                isClearable
                placeholder={'Filtra per amministratore...'}
                value={usersOpts.find(o => parseInt(o.id) === parseInt(filtered_users[0]))}
                onChange={this.onFilterUsers}
                options={usersOpts}
                menuPlacement={'top'}
              />
            </WithCapabilities>
            <SearchBox
              style={{ width: '14rem' }}
              value={this.state.filter}
              onChange={this.onFilterChange}
              disabled={this.props.show_form}
              onEnter={this.handleSearch}
            />
            <Button onClick={this.handleSearch} variant="secondary">
              <i className="fa fa-search me-2" />
              <Trans i18nKey="common.cancel_button">Cerca</Trans>
            </Button>
            <Button onClick={this.handleShowMaps} style={{ minWidth: '13rem' }}>
              {show_maps === false && (
                <>
                  <i className="fa-solid fa-map-location-dot"></i>
                  <Trans i18nKey="condominium_list.goto_maps"> Vai alla mappa</Trans>
                </>
              )}
              {show_maps === true && (
                <>
                  <i className="fa fa-list"></i>
                  <Trans i18nKey="condominium_list.goto_list"> Vai alla lista</Trans>
                </>
              )}
            </Button>
          </div>
        </div>
        {show_maps === false && isAdmin && (
          <div className="d-flex align-items-stretch">
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltip-multiselect`}>
                  <Trans i18nKey="contracts.enable_multiselect_label">Abilita/Disabilita Selezione</Trans>
                </Tooltip>
              }>
              <Button onClick={this.handleSelection} className="rounded-0 no-shadow-button m-015" variant="secondary">
                <i className="fa-solid fa-list-check me-1"></i>
              </Button>
            </OverlayTrigger>
            <Dropdown
              className="m-015"
              style={
                type_selected === 'single'
                  ? { backgroundColor: '#ababab', pointerEvents: 'none' }
                  : { border: '1px solid', borderColor: 'lightgrey' }
              }>
              <Dropdown.Toggle
                variant="light"
                id="multi-selected-menu"
                disabled={type_selected === 'single'}
                className="rounded-0">
                <Trans i18nKey="contracts.bulk_action_label">Azioni massive</Trans>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {Object.keys(this.bulk_actions).map(a => {
                  return (
                    <Dropdown.Item key={a} disabled={selected_condominia.length === 0} onClick={this.handleAction(a)}>
                      {this.bulk_actions[a]}
                    </Dropdown.Item>
                  )
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        {show_maps === false && (
          <CondominiaListTable
            onShowCondominium={this.showCondominium}
            type_selected={type_selected}
            selected_condominia={selected_condominia}
            handleSelection={this.handleSelection}
            toggleCondominium={this.toggleCondominium}
            toggleAllCondominia={this.toggleAllCondominia}
          />
        )}
        {show_maps === true && (
          <CondominiaListMaps onShowCondominium={this.showCondominium} reset_filter={reset_filter} />
        )}
        {show_modal === true && (
          <ShowCondominium onCloseModal={this.onCloseModal} condominium={edit_condominia} tServices={tServices} />
        )}
        {show_add_services_modal === true && (
          <ManageServiceModal
            servicesOpts={services}
            selected_condominia={selected_condominia}
            onToggleMngServices={this.onCloseModal}
            onChangeServices={this.assignServices}
          />
        )}
        {show_maps === false && (
          <div
            className="d-flex align-items-center py-2 bg-light mb-2 border-start border-bottom border-end"
            style={{ fontWeight: 'bold', padding: '0.25rem 0.5rem', borderTop: 'solid 0.15rem var(--bs-primary)' }}>
            <div className="text-contrast-light">
              Totale: <b>{total}</b>
            </div>
            <div className="flex-fill"></div>
            <div className="me-3">
              <Form.Select aria-label="Limite di paginazione" className="pt-1 pb-1" onChange={this.changePageLimit}>
                <option value="15">15</option>
                <option value="100">100</option>
                <option value="-1">Tutti</option>
              </Form.Select>
            </div>
            <div className="text-center align-self-center">
              {total > 0 && this.renderPagination()}
              <Button variant="primary" className="ms-4" onClick={this.refreshList} size="sm">
                <i className={`fa fa-refresh m-auto`} />
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default connect(mstp)(CondominiaList)
