import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Spinner } from 'react-bootstrap'

const mstp = state => {
  let { net_status, server_status } = state.common
  return { net_status, server_status }
}

class OfflineModal extends React.PureComponent {
  state = { loading: false }

  handleRetry = () => {
    this.setState({ loading: true })
    setTimeout(() => this.setState({ loading: false }), 10000)
  }

  render() {
    let { net_status, server_status } = this.props
    return (
      <>
        <Modal show={!net_status} size="lg" centered>
          <Modal.Header>
            <Modal.Title style={{ margin: 'auto' }}>Rete disconnessa</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            Il tuo computer risulta non avere una connessione alla rete attiva! <br /> Verifica che il computer sia in
            rete e riprova.
          </Modal.Body>
        </Modal>
        <Modal show={net_status && !server_status} size="lg" centered>
          <Modal.Header>
            <Modal.Title style={{ margin: 'auto' }}>Sistema offline</Modal.Title>
          </Modal.Header>
          <Modal.Body>Il sistema risulta essere in manutenzione!</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleRetry} style={{ width: '8rem' }}>
              {this.state.loading && (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              )}
              {!this.state.loading && 'Riprova'}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default connect(mstp)(OfflineModal)
