import React from 'react'
import { Trans } from 'react-i18next'
import { Modal, Button } from 'react-bootstrap'

class DeleteCondominiumModal extends React.Component {
  render() {
    return (
      <Modal backdrop="static" centered show={true} onHide={this.props.onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans i18nKey="assignments.delete_condominium_modal.modal_title">Elimina condominio</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <Trans
              i18nKey="assignments.delete_condominium_modal.modal_msg"
              values={{ condominium_name: this.props.delete_name }}
              defaults="Per procedere alla cancellazione di <1>{{ condominium_name }}</1> click su Elimina"
              components={{ 1: <b /> }}
            />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onCloseModal} variant="secondary">
            <Trans i18nKey="common.cancel_button">Annulla</Trans>
          </Button>
          <Button onClick={this.props.onConfirmDelete} variant="danger">
            <Trans i18nKey="common.delete_button">Elimina</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default DeleteCondominiumModal
