import React from 'react'
import { connect } from 'react-redux'
import { Button, Modal, Form, Spinner, Alert } from 'react-bootstrap'
import hash from 'object-hash'
import i18next from 'i18next'
import { Trans } from 'react-i18next'

import 'scss/assignments.css'

import { getAdminUsers, setLoading, createAssignment, toggleShowModal } from 'store/assignments'
import { setCurrentUser, resetCurrentUser, updateErrors, getRoles } from 'store/users'
import validateFields from 'lib/validators/user'
import StyledSelect from 'components/system_wide/styled_select'
import SelectMunicipality from 'components/system_wide/select_municipality'

const { t } = i18next
const mstp = state => {
  let { loading, admin_users, show_modal } = state.assignments.data
  let { current_user: user, roles, errors } = state.usersData
  return { user, errors, roles, admin_users, loading, show_modal }
}

const initialState = {
  id: null,
  name: '',
  surname: '',
  email: '',
  deactivated: false,
  role_id: '',
  tax_datum_attributes: {
    email: '',
    business_name: '',
    vat_number: '',
    street_address: '',
    cap: '',
    city: '',
    region: '',
    province: '',
    phone: '',
    mobile_phone: '',
    sdi: '',
    country_id: 1,
  },
}

class AddModal extends React.Component {
  state = {
    selected_user: null,
    user: initialState,
    user_hash: '',
  }

  componentDidUpdate = prevProps => {
    if (prevProps.user.id !== this.props.user.id) {
      let user = { ...initialState, ...this.props.user }
      let user_hash = hash(user)
      this.setState({ user, user_hash })
      this.initialHash = hash(user)
    }
    if (prevProps.show_modal !== this.props.show_modal) {
      this.setState({ user: initialState, user_hash: '', selected_user: null })
    }
  }
  updateHash = () => {
    let { user } = this.state
    this.setState({ user_hash: hash(user) })
  }
  handleEmailChange = ({ target: { name, value } }) => {
    let user = { ...this.state.user }
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    user[name] = value
    let tax_datum_attributes = { ...user.tax_datum_attributes }
    tax_datum_attributes[name] = value
    this.setState({ user: { ...user, tax_datum_attributes } }, () => this.updateHash())
  }

  handleChange = ({ target: { name, value } }) => {
    let user = { ...this.state.user }
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    user[name] = value
    this.setState({ user }, () => this.updateHash())
  }
  handleTaxAttributesChange = ({ target: { name, value } }) => {
    let user = { ...this.state.user }
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    let tax_datum_attributes = { ...user.tax_datum_attributes }
    tax_datum_attributes[name] = value
    this.setState({ user: { ...user, tax_datum_attributes } }, () => this.updateHash())
  }
  handleSelectChange = e => {
    let errors = { ...this.props.errors }
    delete errors['role_id']
    this.props.dispatch(updateErrors({ errors }))
    this.setState({ user: { ...this.state.user, role_id: e.target.value } })
  }
  handleMunicipalityChange = values => {
    let user = { ...this.state.user }
    let errors = { ...this.props.errors }
    let tax_datum_attributes = { ...user.tax_datum_attributes, ...values }
    Object.keys(values).forEach(k => delete errors[k])
    this.props.dispatch(updateErrors({ errors }))
    this.setState({ user: { ...user, tax_datum_attributes } }, () => this.updateHash())
  }

  isFormValid = () => {
    let { name, surname, email } = this.state.user
    let { vat_number, business_name } = this.state.user.tax_datum_attributes
    return name !== '' && surname !== '' && email !== '' && vat_number !== '' && business_name !== ''
  }

  onOpenModal = async () => {
    await this.props.dispatch(getAdminUsers(4))
    if (Object.keys(this.props.roles).length === 0) await this.props.dispatch(getRoles())
    this.props.dispatch(toggleShowModal())
  }
  onCloseModal = () => {
    this.props.dispatch(toggleShowModal())
  }

  handleConfirm = async () => {
    let errors = validateFields({ ...this.state.user, ...this.state.user.tax_datum_attributes })
    if (Object.keys(errors).length) {
      this.props.dispatch(updateErrors({ errors }))
    } else {
      let value = this.state.selected_user?.value
      if (value) {
        await this.props.dispatch(createAssignment({ user_id: value }))
      } else {
        await this.props.dispatch(createAssignment({ user_id: '', user_data: this.state.user }))
      }
    }
    this.setState({ validated: true })
  }

  handleAdminChange = async option => {
    this.props.dispatch(setLoading(true))
    if (option) {
      await this.props.dispatch(setCurrentUser(option.value))
    } else {
      await this.props.dispatch(resetCurrentUser())
    }
    this.setState({ selected_user: option })
    this.props.dispatch(setLoading(false))
  }

  render() {
    let { validated, user, selected_user } = this.state
    let { name, surname, email, role_id, tax_datum_attributes } = user
    let { street_address, phone, cap, vat_number, business_name, mobile_phone, sdi, region, province, city_id } =
      tax_datum_attributes
    let { admin_users, loading, show_modal, roles, errors } = this.props
    let disabled = selected_user !== null

    return (
      <>
        <div className="d-flex pt-1 pb-1">
          <Button variant="primary" onClick={this.onOpenModal}>
            <Trans i18nKey="assignments.add_modal.topbar_button">Nuovo incarico</Trans>
          </Button>
        </div>
        <Modal backdrop="static" centered show={show_modal} onHide={this.onCloseModal} className="menu-dialog">
          <Modal.Header className="pb-0">
            <Modal.Title>
              <h4 className="text-primary p-1">
                <Trans i18nKey="assignments.add_modal.title">Nuovo Incarico</Trans>
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column" style={{ maxWidth: '70rem' }}>
            <div className="flex-fill position-relative overflow-auto container">
              {errors?.from_server?.length > 0 && (
                <Alert variant="danger">
                  <Alert.Heading>Error:</Alert.Heading>
                  <ul>
                    {errors.from_server.map((err, i) => (
                      <li key={`err-${i}`}>{err.detail}</li>
                    ))}
                  </ul>
                </Alert>
              )}
              <div className="row mb-3">
                <div className="col">
                  <Form.Label>
                    <Trans i18nKey="users.user_form.name">Cerca Amministratore</Trans>
                  </Form.Label>
                  <StyledSelect
                    isClearable
                    placeholder={t('users.user_form.select_administrator', 'Seleziona amministratore..')}
                    value={selected_user}
                    onChange={this.handleAdminChange}
                    options={admin_users}
                  />
                </div>
              </div>
              {/* <div className="row"> */}
              {/*   <div className="col-6"> */}
              {/*     <Form.Group className="mt-3 mb-3"> */}
              {/*       <Form.Label> */}
              {/*         <Trans i18nKey="users.user_form.role_in_the_assignment">Ruolo nell'incarico</Trans> */}
              {/*       </Form.Label> */}
              {/*       <Form.Select */}
              {/*         value={role_id} */}
              {/*         onChange={this.handleSelectChange} */}
              {/*         isInvalid={validated && 'role_id' in errors} */}
              {/*       > */}
              {/*         <option value="">{t('user.user_form.not_role', 'Nessun ruolo')}</option> */}
              {/*         {roles && */}
              {/*           Object.entries(roles).map(role => ( */}
              {/*             <option key={`rol-opt-${role[0]}`} value={role[0]}> */}
              {/*               {role[1]} */}
              {/*             </option> */}
              {/*           ))} */}
              {/*       </Form.Select> */}
              {/*       <Form.Control.Feedback type="invalid">{errors.role_id}</Form.Control.Feedback> */}
              {/*     </Form.Group> */}
              {/*   </div> */}
              {/* </div> */}
              <div className={`${disabled ? 'opacity-50' : ''}`}>
                {loading && (
                  <div className="d-flex align-items-center justify-content-center spinner-container">
                    <Spinner animation="border" variant="primary" />
                  </div>
                )}
                <div className="row pt-3">
                  <div className="col">
                    <Form.Group className="mt-3">
                      <Form.Label>
                        <Trans i18nKey="users.user_form.name">Nome</Trans>
                      </Form.Label>
                      <span> *</span>
                      <Form.Control
                        name="name"
                        placeholder={t('users.user_form.name', 'Nome')}
                        value={name}
                        onChange={this.handleChange}
                        disabled={disabled}
                        isInvalid={validated && 'name' in errors}
                      />
                      <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group className="mt-3">
                      <Form.Label>
                        <Trans i18nKey="users.user_form.surname">Cognome</Trans>
                      </Form.Label>
                      <span> *</span>
                      <Form.Control
                        name="surname"
                        placeholder={t('users.user_form.surname', 'Cognome')}
                        value={surname}
                        onChange={this.handleChange}
                        disabled={disabled}
                        isInvalid={validated && 'surname' in errors}
                      />
                      <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group className="mt-3">
                      <Form.Label>
                        <Trans i18nKey="users.user_form.email">Email</Trans>
                      </Form.Label>
                      <span> *</span>
                      <Form.Control
                        name="email"
                        placeholder={t('users.user_form.email', 'Email')}
                        value={email}
                        onChange={this.handleEmailChange}
                        disabled={disabled}
                        isInvalid={validated && 'email' in errors}
                      />
                      <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <Form.Group>
                      <Form.Label>
                        <Trans i18nKey="users.user_form.business_name">Ragione sociale</Trans>
                      </Form.Label>
                      <span> *</span>
                      <Form.Control
                        name="business_name"
                        placeholder={t('users.user_form.business_name', 'Ragione sociale')}
                        value={business_name}
                        onChange={this.handleTaxAttributesChange}
                        isInvalid={validated && 'business_name' in errors}
                      />
                      <Form.Control.Feedback type="invalid">{errors.business_name}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <Trans i18nKey="users.user_form.vat_number">P.I.</Trans>
                      </Form.Label>
                      <span> *</span>
                      <Form.Control
                        name="vat_number"
                        placeholder={t('users.user_form.vat_number', 'P.I.')}
                        value={vat_number}
                        onChange={this.handleTaxAttributesChange}
                        disabled={disabled}
                        isInvalid={validated && 'vat_number' in errors}
                      />
                      <Form.Control.Feedback type="invalid">{errors.vat_number}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <Trans i18nKey="users.user_form.sdi">SDI</Trans>
                      </Form.Label>
                      <Form.Control
                        name="sdi"
                        placeholder={t('users.user_form.sdi', 'SDI')}
                        value={sdi}
                        onChange={this.handleTaxAttributesChange}
                        disabled={disabled}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <SelectMunicipality
                    region={region}
                    province={province}
                    city={city_id}
                    cap={cap}
                    errors={errors}
                    onChange={this.handleMunicipalityChange}
                    disabled={disabled}
                  />
                </div>
                <div className="row">
                  <div className="col">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <Trans i18nKey="users.user_form.street_address">Indirizzo</Trans>
                      </Form.Label>
                      <Form.Control
                        name="street_address"
                        placeholder={t('users.user_form.street_address', 'Indirizzo')}
                        value={street_address}
                        onChange={this.handleTaxAttributesChange}
                        isInvalid=""
                        disabled={disabled}
                      />
                      <Form.Control.Feedback type="invalid">''</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <Trans i18nKey="users.user_form.phone">Telefono</Trans>
                      </Form.Label>
                      <Form.Control
                        name="phone"
                        placeholder={t('users.user_form.phone', 'Telefono')}
                        value={phone}
                        onChange={this.handleTaxAttributesChange}
                        isInvalid=""
                        disabled={disabled}
                      />
                      <Form.Control.Feedback type="invalid">''</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <Trans i18nKey="users.user_form.mobile_phone">Cellulare</Trans>
                      </Form.Label>
                      <Form.Control
                        name="mobile_phone"
                        placeholder={t('users.user_form.mobile_phone', 'Cellulare')}
                        value={mobile_phone}
                        onChange={this.handleTaxAttributesChange}
                        disabled={disabled}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCloseModal} variant="secondary">
              <Trans i18nKey="common.cancel_button">Annulla</Trans>
            </Button>
            <Button onClick={this.handleConfirm} variant="primary" disabled={!this.isFormValid()}>
              <Trans i18nKey="assignments.add_modal.confirm_button">Avvia pratica</Trans>
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default connect(mstp)(AddModal)
