import React from 'react'
import { Dropdown } from 'react-bootstrap'
import 'scss/navbar.css'

class SettingsMenuDropdown extends React.PureComponent {
  render() {
    return (
      <Dropdown id="settings-dropdown-home">
        <Dropdown.Toggle variant="primary">
          <i className={`fas fa-gear`} />
        </Dropdown.Toggle>
        <Dropdown.Menu id="settings-dropdown-menu">
          <Dropdown.Item href="#/action-3">Privacy</Dropdown.Item>
          <Dropdown.Item href="#/action-4">Preferenze</Dropdown.Item>
          <Dropdown.Item href="#/action-4">Impostazioni</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

export default SettingsMenuDropdown
