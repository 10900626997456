import React from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'
import { Trans } from 'react-i18next'
import { Button, Modal, Form, Table, Dropdown, ButtonGroup } from 'react-bootstrap'
import { showConfirmDialog } from 'components/system_wide/confirm_dialog'
import { downloadFile } from 'components/system_wide/download_contract_button'
import axios from 'lib/axios'
import config from 'config'
import { error, success } from 'components/system_wide/notification'

const { t } = i18next

const mstp = state => {
  return {}
}

class ModalUploadDocument extends React.Component {
  state = {
    selectedFile: '',
    selectedFileLabel: '',
  }

  uploadForm = null

  onConfirm = async () => {
    try {
      let formData = new FormData()
      formData.append('_jsonapi[document]', this.state.selectedFile)
      formData.append('accepted', true)
      await axios({
        url: `${config.SERVER_API_URL}/v1/contracts/${this.props.contract.id}/attachments`,
        method: 'POST',
        data: formData,
      })
      this.props.refreshAttachments(this.props.contract.condominium_id, this.props.contract.id)
      success(t('store.contracts.attachments.success_upload', 'File caricato con successo'))
      this.setState({ selectedFile: '', selectedFileLabel: '' })
      this.uploadForm.value = ''
    } catch (err) {
      error(t('store.contracts.attachments.error_upload', 'Impossibile caricare il file'))
    }
  }

  FileUploadHandler = e => {
    if (e.target.files) {
      let file = e.target.files[0]
      if (file) {
        let selectedFileLabel = file.name
        let selectedFile = file
        this.setState({ selectedFile, selectedFileLabel })
      }
    } else {
      this.setState({ selectedFile: '', selectedFileLabel: '' })
    }
  }

  handleDeleteFile = dw => () => {
    showConfirmDialog(
      <Trans i18nKey="dossiers.requirements.delete_modal.title">Elimina allegato</Trans>,
      <Trans
        i18nKey="dossiers.requirements.delete_modal.msg"
        values={{ file_name: dw.name }}
        defaults="Per procedere alla cancellazione dell'allegato <1>{{ file_name }}</1> click su Elimina"
        components={{ 1: <b /> }}
      />,
      async () => {
        let { condominium_id, id: contract_id } = this.props.contract
        await this.props.deleteAttachment({
          attachment_id: dw.id,
          contract_id,
        })
        await this.props.refreshAttachments(condominium_id, contract_id)
      },
      () => {},
      'primary',
      <Trans i18nKey="common.delete_button">Elimina</Trans>
    )
  }

  render() {
    let { show_modal, contract } = this.props
    let { selectedFileLabel } = this.state
    return (
      <Modal backdrop="static" centered show={show_modal} onHide={this.props.onCloseModal} size="lg">
        <Modal.Header className="pb-0" closeButton>
          <Modal.Title>
            <h4 className="text-primary p-1">
              <Trans i18nKey="dossiers.requirements.add_modal.title">Carica documentazione</Trans>
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxWidth: '70rem' }}>
          <div className="pt-2">
            <Form.Label>
              <Trans i18nKey="dossiers.requirements.select_file_title">Scegli il file da associare</Trans>
            </Form.Label>
            <div className="row">
              <div className="col-10">
                <Form.Control
                  type="file"
                  id="upload"
                  key="upload_form"
                  label={selectedFileLabel}
                  ref={form => (this.uploadForm = form)}
                  onChange={this.FileUploadHandler}
                />
              </div>
              <div className="col-2">
                <Button variant="primary" onClick={this.onConfirm} disabled={selectedFileLabel === ''}>
                  Carica file
                </Button>
              </div>
            </div>
          </div>
          <div className="row border-top mt-4 pt-4">
            <h5>Documenti</h5>
          </div>
          <div className="overflow-auto" style={{ height: '15rem' }}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>File</th>
                  <th className="text-center" style={{ width: '2rem' }}>
                    Azioni
                  </th>
                </tr>
              </thead>
              <tbody>
                {(contract.attachments ?? []).map(dw => (
                  <tr style={{ height: '1rem' }} key={`dw-${dw.id}`}>
                    <td>{dw.name}</td>
                    <td>
                      <div className="d-flex">
                        <Dropdown as={ButtonGroup} size="sm">
                          <Button onClick={() => downloadFile(contract.id, dw.id, dw.name)}>
                            <Trans i18nKey="actions_dropdown.title.download">Scarica</Trans>
                          </Button>
                          <Dropdown.Toggle split variant="secondary" id="dropdown-split-basic" />
                          <Dropdown.Menu style={{ left: '-120px' }}>
                            <Dropdown.Item onClick={() => downloadFile(contract.id, dw.id, dw.name)}>
                              <Trans i18nKey="actions_dropdown.title.download">Scarica</Trans>
                            </Dropdown.Item>

                            <Dropdown.Divider />
                            <Dropdown.Item onClick={this.handleDeleteFile(dw)}>
                              <Trans i18nKey="document.delete">Elimina</Trans>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.onCloseModal}>
            <Trans i18nKey="common.close_button">Chiudi</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default connect(mstp)(ModalUploadDocument)
