import React from 'react'
import { Spinner, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap'

class DossierBox extends React.Component {
  state = {
    no_reqs: false,
  }

  openModal = rq => () => {
    this.props.openModal(rq, this.props.dossier)
  }

  render() {
    let { dossier, loading } = this.props
    let { requirements, service } = dossier
    let no_reqs = dossier.requirements.length === 0 || dossier.meet
    let req_condominia = dossier.requirements.reduce(
      (acc, r) => (acc = acc || r.associated_model === 'Condominium'),
      false
    )
    let target = React.createRef()

    if (loading)
      return (
        <div className="d-flex w-100 flex-column zi-1">
          <div className="w-100">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      )

    return (
      <div className="bg-white border">
        <div
          className="fw-bold p-3"
          style={no_reqs ? { background: 'var(--bs-teal)' } : { background: 'var(--bs-red)' }}
        >
          <h4>
            <i className={` ${dossier.service.icon_classes} pe-3`} />
            {service.description}
          </h4>
        </div>
        <div style={{ height: '30rem', overflow: 'auto' }}>
          {requirements.length > 0 && (
            <div
              className="d-flex flex-row w-100"
              style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}
            >
              <div
                className={`text-center align-middle text-muted p-3 fw-bold h6`}
                style={{
                  minWidth: '24rem',
                  borderBottom: '2px solid orange',
                  position: 'sticky',
                  left: 0,
                  background: 'white',
                }}
              >
                Nome
              </div>
              <div
                className="align-middle text-muted p-3 fw-bold h6"
                style={{
                  width: '100%',
                  minWidth: '13rem',
                  borderBottom: '2px solid orange',
                }}
              >
                <div className="d-flex ps-3">
                  <div style={{ width: '1.3rem' }} className="d-flex align-items-start">
                    Info
                  </div>
                </div>
              </div>
              <div
                className="text-end align-center align-middle text-muted p-3 fw-bold h6"
                style={{
                  width: '100%',
                  minWidth: '4rem',
                  borderBottom: '2px solid orange',
                }}
              >
                {req_condominia && (
                  <OverlayTrigger
                    key={`${dossier.id}-over`}
                    trigger={['hover', 'click']}
                    overlay={<Tooltip>Elenco condomini</Tooltip>}
                  >
                    <div className="btn btn-primary">
                      <i className="fas fa-building" />
                    </div>
                  </OverlayTrigger>
                )}
              </div>
            </div>
          )}
          {requirements.length === 0 && (
            <Alert className="m-3" variant="secondary">
              Nessun requisito richiesto per avanzare con la pratica.
            </Alert>
          )}
          {requirements.map(rq => {
            return (
              <div className="d-flex flex-row text-center" key={`${rq.id}-req`}>
                <div
                  className="p-3 border-bottom"
                  style={{ minWidth: '24rem', backgroundColor: 'var(--bs-body-bg)', position: 'sticky', left: 0 }}
                >
                  {rq.name.replace(/_/g, ' ').replace(/\b(\w)/g, s => s.toUpperCase())}
                </div>
                <div
                  key={`value-${rq.id}-val`}
                  className="p-3 border-bottom"
                  style={{ width: '100%', minWidth: '17.5rem' }}
                >
                  {rq.meet && (
                    <span className="text-success">
                      Requisito soddisfatto! <i className="fa fa-check" />
                    </span>
                  )}
                  {!rq.meet && (
                    <div className="d-flex btn btn-secondary" onClick={this.openModal(rq)}>
                      Carica documentazione
                      <i className="fas fa-file-upload ps-3 pt-1" />
                    </div>
                  )}
                </div>
                <div
                  className="p-3 border-bottom"
                  style={{
                    width: '100%',
                  }}
                ></div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default DossierBox
