import React from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import Login from 'components/login'

const mstp = state => {
  return { loginData: state.loginData, userInfo: state.userInfo }
}

class LoginPage extends React.Component {
  render() {
    if (this.props.userInfo.email) return <Navigate to={'/'} push={true} />
    return <Login loginData={this.props.loginData} dispatch={this.props.dispatch} />
  }
}

export default connect(mstp)(LoginPage)
