import React from 'react'

class Footer extends React.Component {
  render() {
    let date = new Date()
    return (
      <div className="mt-auto mb-5 text-center text-muted">
        <div className="d-flex align-items-center justify-content-center m-auto gap-4" style={{ width: '5rem' }}>
          <a
            href="https://www.facebook.com/profile.php?id=100075727936264"
            target="_blank"
            rel="noopener noreferrer"
            title="Facebook - Conhive">
            <i className="fab fa-facebook" />
          </a>
          <a
            href="https://www.linkedin.com/company/76551815/admin/"
            target="_blank"
            rel="noopener noreferrer"
            title="Linkedin - Conhive">
            <i className="fab fa-linkedin" />
          </a>
        </div>
        <div className="d-flex justify-content-around gap-3 pt-3 fs-7">
          <span>
            Vai al sito Conhive:{' '}
            <a href="https://www.conhive.it" target="_blank" rel="noopener noreferrer">
              www.conhive.it
            </a>
          </span>
        </div>
        <div className="p-2 fs-7">Copyright © {date.getFullYear()} - HIVE GROUP S.P.A.</div>
      </div>
    )
  }
}

export default Footer
