import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import i18next from 'i18next'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import config from 'config'
import { isStateBefore } from 'lib/utils'
import { getRoles, getAffiliateRoles } from 'store/users'
import {
  getAssignments,
  filterStatuses,
  filterRoles,
  filterServices,
  resetCurrentAssignment,
  goToState,
} from 'store/assignments'
import { getServices, getService } from 'store/assignments/services'
import ResponsiveTable from 'components/system_wide/responsive_table'
import ActionsDropdownSplitted from 'components/system_wide/actions_dropdown_splitted'
import SearchBox from 'components/system_wide/search_box'
import CheckboxFilter from 'components/system_wide/checkbox_filter'
import ReadyAssignmentsList from 'components/assignments/list-ready'

const { t } = i18next

const mstp = state => {
  let { assignments, assignments_ready, total, errors, filtered_statuses, filtered_services, filtered_roles } =
    state.assignments.data
  let { services } = state.assignments.services
  let { roles, affiliate_roles } = state.usersData
  return {
    total,
    roles,
    errors,
    assignments,
    assignments_ready,
    services,
    affiliate_roles,
    filtered_services,
    filtered_statuses,
    filtered_roles,
  }
}

class AssignmentsList extends React.Component {
  static defaultProps = {
    assignments: [],
    getData: () => {},
    columns: [],
    filter: null,
  }

  state = {
    show_modal_edit: false,
    filter: '',
    ready: false,
  }

  tableRef = null
  statusesOptions = []

  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
    this.statusesOptions = [
      ...config.ASSIGNMENTS_STATUSES.map(v => {
        v.name = (
          <div className="fw-bold" style={{ backgroundColor: v.color }}>
            {v.name}
          </div>
        )
        return v
      }),
    ]
    this.statuses = config.ASSIGNMENTS_STATUSES.reduce((acc, v) => ({ ...acc, [v.id]: v.color }), {})
  }

  componentDidMount = async () => {
    await this.props.dispatch(resetCurrentAssignment())
    await this.props.dispatch(getRoles())
    await this.props.dispatch(getServices())
    await this.props.dispatch(getAffiliateRoles())
    this.setState({ ready: true }, () => this.tableRef.current.refreshData())
  }

  goToState = (assignment_id, current_state) => () => {
    this.props.dispatch(goToState({ assignment_id, current_state }))
  }

  actions = [
    {
      icon: 'fa-list-ol',
      text: t('actions_dropdown.edit_services', 'Servizi'),
      onClick: async rowData => {
        this.props.navigate(`/assignments/${rowData.id}/services`)
      },
    },
    {
      icon: 'fa-users',
      text: t('actions_dropdown.edit_affiliates', 'Affiliati'),
      disabled: rowData => isStateBefore(rowData.current_state, 'acceptance'),
      onClick: async rowData => {
        this.props.navigate(`/assignments/${rowData.id}/affiliates`)
      },
    },
    {
      icon: 'fa-home',
      text: t('actions_dropdown.edit_condominia', 'Condomini'),
      disabled: rowData => isStateBefore(rowData.current_state, 'configuration'),
      onClick: async rowData => {
        this.props.navigate(`/assignments/${rowData.id}/condominia`)
      },
    },
    {
      icon: 'fa-euro-sign',
      text: t('actions_dropdown.edit_price', 'Prezzi'),
      disabled: rowData => isStateBefore(rowData.current_state, 'price_definition'),
      onClick: async rowData => {
        this.props.navigate(`/assignments/${rowData.id}/prices`)
      },
    },
  ]

  actionsRenderer = ({ rowData }) => {
    return (
      <ActionsDropdownSplitted
        rowData={rowData}
        actions={this.actions}
        onClickMaster={this.goToState(rowData.id, rowData.current_state)}
      />
    )
  }

  roleRenderer = ({ rowData: { role_id } }) => {
    let role = this.props.roles[role_id] ?? null
    if (!role) return null
    return role.replace('_', ' ')
  }
  stateRenderer = ({ rowData: { current_state } }) => {
    return (
      <div className="p-1" style={{ backgroundColor: this.statuses[current_state] }}>
        {current_state}
      </div>
    )
  }
  orderRenderer = ({ rowData: { order } }) => {
    return order ? `N. ${order.id}` : '--'
  }
  orderTotalAmountRenderer = ({ rowData: { order } }) => {
    let total_amount = (order?.final_amount || 0) + (order?.fee_amount || 0)
    return <div className="text-end">{order ? `${total_amount.toFixed(2)} €` : '--'}</div>
  }
  orderDataRenderer = ({ rowData: { order } }) => {
    let data_signature_string = '--'
    if (order) {
      let data_signature = new Date(order.created_at)
      data_signature_string = data_signature.toLocaleDateString()
    }
    return data_signature_string
  }
  renderService = ({ rowData: { services } }) => {
    return (
      <div className="d-flex flex-wrap gap-2">
        {services.map(s_id => {
          let service = this.props.services.find(s => s.value === s_id)
          let target = React.createRef()
          return service ? (
            <OverlayTrigger
              key={service.value}
              trigger={['hover', 'click']}
              overlay={<Tooltip>{service.label}</Tooltip>}
            >
              <div style={{ width: '1.3rem' }} className="d-flex align-items-center">
                <i ref={target} className={`${service.icon_classes} m-auto`} />
              </div>
            </OverlayTrigger>
          ) : (
            ''
          )
        })}
      </div>
    )
  }

  columns = [
    {
      title: t('assignments.list.code', 'Code'),
      className: 'd-lg-block flex-fill',
      style: { width: '6%' },
      sortable: true,
      data: 'id',
    },
    {
      title: t('assignments.list.name', 'Nome'),
      className: 'd-lg-block flex-fill',
      style: { width: '12%' },
      sortable: true,
      data: 'name',
    },
    {
      title: t('assignments.list.surname', 'Cognome'),
      className: 'd-lg-block flex-fill',
      style: { width: '12%' },
      sortable: true,
      data: 'surname',
    },
    {
      title: t('assignments.list.role', 'Ruolo'),
      className: 'd-none d-lg-block flex-fill text-capitalize text-truncate',
      style: { width: '10%' },
      sortable: true,
      data: this.roleRenderer,
    },
    {
      title: t('assignments.list.state', 'Stato'),
      className: 'd-lg-block flex-fill text-capitalize text-truncate',
      style: { width: '10%' },
      sortable: true,
      data: this.stateRenderer,
    },
    {
      title: t('assignments.list.services', 'Servizi'),
      className: 'd-lg-block flex-fill text-truncate',
      style: { width: '14%' },
      data: this.renderService,
    },
    {
      title: t('assignments.list.order', 'Ordine'),
      className: 'd-lg-block flex-fill text-truncate',
      style: { width: '8%' },
      data: this.orderRenderer,
    },
    {
      title: t('assignments.list.date_signature', 'Data'),
      className: 'd-lg-block flex-fill text-truncate',
      style: { width: '10%' },
      sortable: true,
      sortKey: 'created_at',
      data: this.orderDataRenderer,
    },
    {
      title: t('assignments.list.total_cost', 'Costo totale'),
      className: 'd-lg-block flex-fill text-truncate',
      style: { width: '8%' },
      data: this.orderTotalAmountRenderer,
    },
    {
      title: '',
      className: 'd-lg-block text-center',
      style: { width: '10%', minWidth: '3rem' },
      data: this.actionsRenderer,
    },
  ]

  getData = async (index = 0, chunkSize, sortData, filter) => {
    await this.props.dispatch(getAssignments({ offset: index, limit: chunkSize, sortData, filter }))
  }

  onFilterChange = filter => this.setState({ filter })

  rowClasses = ({ deactivated }) => {
    return deactivated === true ? 'bg-warning bg-gradient bg-opacity-50' : ''
  }
  onFilterServices = services => {
    this.props.dispatch(filterServices(services))
    this.tableRef.current.refreshData()
  }
  onFilterStatuses = statuses => {
    this.props.dispatch(filterStatuses(statuses))
    this.tableRef.current.refreshData()
  }
  onFilterRoles = roles => {
    this.props.dispatch(filterRoles(roles))
    this.tableRef.current.refreshData()
  }

  render() {
    let {
      assignments,
      assignments_ready,
      total,
      services,
      filtered_services,
      filtered_statuses,
      filtered_roles,
      affiliate_roles,
    } = this.props
    if (!assignments || this.state.ready === false) return null
    let servicesOptions = services.map(v => ({
      id: v.value,
      name: (
        <div className="d-flex">
          <div className="text-center pe-1">
            <i className={`${v.icon_classes}`} style={{ width: '1.6rem' }} />
          </div>
          <div>{v.label}</div>
        </div>
      ),
    }))

    return (
      <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3 pe-lg-3 pt-2 zi-1">
        <div className="d-flex flex-column flex-fill">
          <div className="container-fluid px-0 mb-2">
            <div className="d-flex flex-wrap gap-2">
              <div className="flex-fill">
                <SearchBox
                  value={this.state.filter}
                  onChange={this.onFilterChange}
                  disabled={this.props.show_form}
                  className="float-md-end"
                />
                <CheckboxFilter
                  className="float-md-end me-2"
                  label={t('assignments.filtered_services', 'Servizi')}
                  items={servicesOptions}
                  filteredItems={filtered_services}
                  onChangeFilter={this.onFilterServices}
                />
                <CheckboxFilter
                  className="float-md-end ms-2 me-2"
                  label={t('assignments.filtered_statuses', 'Stato')}
                  items={this.statusesOptions}
                  filteredItems={filtered_statuses}
                  onChangeFilter={this.onFilterStatuses}
                />
                <CheckboxFilter
                  className="float-md-end"
                  label={t('assignments.filtered_roles', 'Ruoli')}
                  items={affiliate_roles}
                  filteredItems={filtered_roles}
                  onChangeFilter={this.onFilterRoles}
                />
              </div>
            </div>
          </div>
          <ResponsiveTable
            ref={this.tableRef}
            className="flex-fill border"
            rowClasses={this.rowClasses}
            menuWidth="70rem"
            data={assignments}
            getData={this.getData}
            totalRecords={total}
            columns={this.columns}
            filter={this.state.filter}
          />
          <div className="pt-3">
            <b>
              <Trans i18nKey="assignments.list.ready_assignment.title">Incarichi finalizzati</Trans>
            </b>
          </div>
          <div className="d-flex align-items-stretch" style={{ height: '15rem' }}>
            <ReadyAssignmentsList
              className="flex-fill border"
              assignments={assignments_ready}
              roles={this.props.roles}
              services={this.props.services}
              goToState={this.goToState}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mstp)(AssignmentsList)
